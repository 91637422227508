import {
  faArrowCircleRight,
  faArrowLeft,
  faBars,
  faBell,
  faHome,
  faMoon,
  faSun,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Image from "react-bootstrap/Image";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

import { useTranslation } from "react-i18next";
import nullAvatar from "../../assets/img/avatar_null";

import onSignOutClick from "../../onClicks/onSignOutClick";
import { toggleSidebar } from "../../redux/actions/sidebarActions";
import { getCompanyLogo } from "../../utils/domain";
import { Box } from "./components/Box";
import { ChildrenRoutes } from "./components/ChildrenRoutes";
import { Container } from "./components/Container";
import { FlagsDropdown, TranslateFlags } from "./components/Flag";
import { Line } from "./components/Line";
import { LineIcon } from "./components/LineIcon";
import { LineText } from "./components/LineText";
import { ListPlants } from "./components/ListPlants";
import { NotificationsDropdown } from "./components/NotificationsDropdown";
import { SidebarHeader } from "./components/SidebarHeader";
import { SpanText } from "./components/SpanText";
import { ThemesDropdown } from "./components/ThemesDropdown";
import { useSidebar } from "./useSidebar";
import { useTheme } from "../../context/theme";

export const SidebarV2 = () => {
  const { theme } = useTheme();
  const companyLogo = getCompanyLogo();
  const { t } = useTranslation();
  const {
    modulesOnByProjects,
    iotRoutes,
    specialistRoutes,
    toolsRoutes,
    adminRoutes,
    nocRoutes,
  } = useSidebar();

  const currentAside = useSelector((store) => store.sidebar);
  const dispatch = useDispatch();
  const [navHovered, setNavHovered] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const innerWidth = window.innerWidth;

  const filteredRoutes =
    localStorage.getItem("userType") === "3"
      ? iotRoutes.filter(
          (route) => route.name === "dashboards" || route.name === "iGoalEye"
        )
      : iotRoutes;

  return (
    <nav
      style={{
        width: currentAside.isOpen || navHovered ? "256px" : "72px",
        background: "var(--sidebar-background)",
        position: "fixed",
        left: innerWidth > 768 ? 0 : currentAside.isOpen ? 0 : "-72px",
        top: 0,
        bottom: 0,
        zIndex: 1026,
        display: "grid",
        transition: "all 0.2s",
        boxSizing: "border-box",
        gridTemplateRows: "72px minmax(300px, 1fr) 192px",
        gap: "8px",
      }}
    >
      <div
        style={{ width: currentAside.isOpen || navHovered ? "256px" : "72px" }}
      >
        <span
          id="control"
          className="sidebar-toggle d-flex justify-content-center align-items-center"
          style={{
            position: "absolute",
            right: innerWidth > 768 ? -12 : -18,
            top: 6,
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            transition: "all 0.3s",
            borderRadius: "50%",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.5)",
          }}
          onClick={() => {
            dispatch(toggleSidebar());
          }}
        >
          <FontAwesomeIcon
            style={{ color: "var(--primary-color)" }}
            icon={currentAside.isOpen ? faArrowLeft : faBars}
            color="#ccc"
          />
        </span>
        <div style={{ height: "72px", display: "flex", alignItems: "center" }}>
          <a href="/" style={{ textDecorations: "none" }}>
            <img
              style={{
                width: currentAside.isOpen || navHovered ? "240px" : "100%",
                height: currentAside.isOpen || navHovered ? "72px" : "",
                transition: "all 0.2s",
                objectFit: "contain",
              }}
              src={companyLogo}
              alt="logo"
            />
          </a>
        </div>
      </div>
      <div
        style={{
          margin: "8px 0",
          width: currentAside.isOpen || navHovered ? "256px" : "72px",
          transition: "all 0.2s",
          minHeight: "300px",
          overflowY: "auto",
          overflowX: "hidden",
        }}
        onMouseEnter={() => setNavHovered(true)}
        onMouseLeave={() => setNavHovered(false)}
      >
        <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
          <Container open={currentAside.isOpen || navHovered}>
            <Box open={currentAside.isOpen || navHovered}>
              <Line open={currentAside.isOpen || navHovered}>
                <LineIcon>
                  <FontAwesomeIcon
                    style={{ color: "var(--primary-color)" }}
                    icon={faHome}
                    size="lg"
                  />
                </LineIcon>
                <LineText open={currentAside.isOpen || navHovered}>
                  <SpanText text={t("sidebar.home")} />
                </LineText>
              </Line>
            </Box>
          </Container>
        </Link>

        {/* NOTE: Rotas IOT Base */}
        {localStorage.getItem("userType") !== "3" &&
          (currentAside.isOpen || navHovered ? (
            <SidebarHeader
              open={currentAside.isOpen || navHovered}
              title={"sidebar.iot"}
            />
          ) : (
            <hr style={{ marginLeft: "12px" }} />
          ))}

        {filteredRoutes.map((route, index) => (
          <Link
            key={index}
            to={route.pathRoute}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Container
              onClick={route.modal ? () => setOpenModal(route.name) : () => {}}
              open={currentAside.isOpen || navHovered}
            >
              <Box open={currentAside.isOpen || navHovered}>
                <Line open={currentAside.isOpen || navHovered}>
                  <LineIcon>
                    {route?.img ? (
                      <img src={route.img} height={20} width={20} />
                    ) : (
                      <FontAwesomeIcon
                        style={{ color: "var(--primary-color)" }}
                        icon={route.icon}
                      />
                    )}
                  </LineIcon>
                  <LineText open={currentAside.isOpen || navHovered}>
                    <SpanText text={t(`sidebar.${route.name}`)} />
                  </LineText>
                </Line>
              </Box>
            </Container>
          </Link>
        ))}

        {/* NOTE: Rotas de especialistas */}
        {Object.values(modulesOnByProjects.data).some((value) => value === 1) &&
          (currentAside.isOpen || navHovered ? (
            <SidebarHeader
              open={currentAside.isOpen || navHovered}
              title={"sidebar.specialist"}
            />
          ) : (
            <hr style={{ marginLeft: "12px" }} />
          ))}

        {specialistRoutes.map(
          (route, index) =>
            modulesOnByProjects.data[route.name] === 1 && (
              <Link
                key={index}
                to={route.pathRoute}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Container open={currentAside.isOpen || navHovered}>
                  <Box open={currentAside.isOpen || navHovered}>
                    <Line open={currentAside.isOpen || navHovered}>
                      <LineIcon>
                        <FontAwesomeIcon
                          style={{ color: "var(--primary-color)" }}
                          icon={route.icon}
                        />
                      </LineIcon>
                      <LineText open={currentAside.isOpen || navHovered}>
                        <SpanText text={t(`sidebar.${route.name}`)} />
                      </LineText>
                    </Line>
                  </Box>
                </Container>
              </Link>
            )
        )}

        {/* NOTE: Rotas de recursos */}
        {localStorage.getItem("userType") !== "3" &&
          (currentAside.isOpen || navHovered ? (
            <SidebarHeader
              open={currentAside.isOpen || navHovered}
              title={"sidebar.tools"}
            />
          ) : (
            <hr style={{ marginLeft: "12px" }} />
          ))}

        {localStorage.getItem("userType") !== "3" &&
          toolsRoutes.map((route, index) => (
            <React.Fragment key={index}>
              <ChildrenRoutes
                route={route}
                open={currentAside.isOpen || navHovered}
              />
            </React.Fragment>
          ))}

        {/* NOTE: Rotas de uso administrativo */}
        {localStorage.getItem("userType") === "1" &&
          (currentAside.isOpen || navHovered ? (
            <SidebarHeader
              open={currentAside.isOpen || navHovered}
              title={"sidebar.adminOnly"}
            />
          ) : (
            <hr style={{ marginLeft: "12px" }} />
          ))}

        {localStorage.getItem("userType") === "1" &&
          adminRoutes.map((route, index) => (
            <React.Fragment key={index}>
              <ChildrenRoutes
                route={route}
                open={currentAside.isOpen || navHovered}
              />
            </React.Fragment>
          ))}

        {localStorage.getItem("userType") === "1" &&
          localStorage.getItem("companyId") === "1" &&
          nocRoutes.map((route, index) => (
            <React.Fragment key={index}>
              <ChildrenRoutes
                route={route}
                open={currentAside.isOpen || navHovered}
              />
            </React.Fragment>
          ))}
      </div>

      <div
        style={{
          width: currentAside.isOpen ? "256px" : "72px",
          transition: "all 0.2s",
        }}
        onMouseEnter={() => setNavHovered(true)}
        onMouseLeave={() => setNavHovered(false)}
      >
        {/* NOTE: Efetuar Logout */}
        <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
          <Container
            onClick={() => onSignOutClick()}
            open={currentAside.isOpen || navHovered}
          >
            <Box open={currentAside.isOpen || navHovered}>
              <Line open={currentAside.isOpen || navHovered}>
                <LineIcon>
                  <FontAwesomeIcon
                    style={{ color: "var(--primary-color)" }}
                    icon={faArrowCircleRight}
                    size="lg"
                  />
                </LineIcon>
                <LineText open={currentAside.isOpen || navHovered}>
                  <SpanText text={t("navbar.logout")} />
                </LineText>
              </Line>
            </Box>
          </Container>
        </Link>

        {/* NOTE: Selecionar Tema */}
        <UncontrolledDropdown direction="up">
          <DropdownToggle tag="span">
            <Container open={currentAside.isOpen || navHovered}>
              <Box open={currentAside.isOpen || navHovered}>
                <Line open={currentAside.isOpen || navHovered}>
                  <LineIcon>
                    <FontAwesomeIcon
                      style={{ color: "var(--primary-color)" }}
                      icon={theme === "dark" ? faMoon : faSun}
                      size="lg"
                    />
                  </LineIcon>
                  <LineText open={currentAside.isOpen || navHovered}>
                    <SpanText text={t("sidebar.changeTheme")} />
                  </LineText>
                  <ThemesDropdown />
                </Line>
              </Box>
            </Container>
          </DropdownToggle>
        </UncontrolledDropdown>

        {/* NOTE: Alarmes */}
        <UncontrolledDropdown direction="up">
          <DropdownToggle
            tag="span"
            style={{
              background: "transparent",
              border: "none",
              width: "24px",
            }}
          >
            <Container open={currentAside.isOpen || navHovered}>
              <Box open={currentAside.isOpen || navHovered}>
                <Line open={currentAside.isOpen || navHovered}>
                  <LineIcon>
                    <FontAwesomeIcon
                      style={{
                        color: "var(--primary-color)",
                        marginLeft: "3px",
                      }}
                      icon={faBell}
                    />
                  </LineIcon>
                  <LineText open={currentAside.isOpen || navHovered}>
                    <SpanText text={t("sidebar.notifications")} />
                  </LineText>
                  <div
                    style={{
                      minWidth: "7rem",
                      position: "fixed",
                      zIndex: "2000",
                      bottom: "150px",
                    }}
                  >
                    <NotificationsDropdown />
                  </div>
                </Line>
              </Box>
            </Container>
          </DropdownToggle>
        </UncontrolledDropdown>

        {/* NOTE: Selecionar idioma */}
        <UncontrolledDropdown direction="up">
          <DropdownToggle
            tag="span"
            style={{
              background: "transparent",
              border: "none",
              width: "24px",
            }}
          >
            <Container open={currentAside.isOpen || navHovered}>
              <Box open={currentAside.isOpen || navHovered}>
                <Line open={currentAside.isOpen || navHovered}>
                  <LineIcon>
                    <FlagsDropdown />
                  </LineIcon>
                  <LineText open={currentAside.isOpen || navHovered}>
                    <SpanText text={t("sidebar.changeLanguage")} />
                  </LineText>
                  <div
                    style={{
                      minWidth: "7rem",
                      position: "fixed",
                      zIndex: "2000",
                      bottom: "150px",
                    }}
                  >
                    <DropdownMenu>
                      <TranslateFlags />
                    </DropdownMenu>
                  </div>
                </Line>
              </Box>
            </Container>
          </DropdownToggle>
        </UncontrolledDropdown>

        {/* NOTE: Acessar Perfil/Settings */}
        <Link
          to="/settings"
          variant="light"
          className="d-inline-flex align-items-center"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Container open={currentAside.isOpen || navHovered}>
            <Box open={currentAside.isOpen || navHovered}>
              <Line open={currentAside.isOpen || navHovered}>
                <div>
                  <LineIcon width="32px" marginRight="7px" height="32x">
                    <Image
                      style={{
                        width: "30px",
                        height: "30px",
                        marginLeft: "-5px",
                        border: `2px solid ${"var(--primary-color)"}`,
                        padding: "1px",
                      }}
                      className="img-fluid rounded-circle"
                      roundedCircle
                      src={
                        localStorage.getItem("avatar") === "null"
                          ? nullAvatar
                          : localStorage.getItem("avatar")
                      }
                    />
                  </LineIcon>
                </div>
                <LineText open={currentAside.isOpen || navHovered}>
                  <SpanText text={t("sidebar.profile")} />
                </LineText>
              </Line>
            </Box>
          </Container>
        </Link>
      </div>

      {openModal === "projects" && (
        <ListPlants open={openModal === "projects"} setModal={setOpenModal} />
      )}
    </nav>
  );
};
