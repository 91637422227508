import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Card,
  CardHeader,
  CardBody,
  Spinner,
} from "reactstrap";
import { resetValidateFields } from "../../../../../../utils/functions";
import { UnitConsumers } from "./components/UnitConsumers";
import { RefMeter } from "./components/RefMeter";

import { usePlantContext } from "../../PlantProvider";
import {
  createParams,
  listDevices,
  listDistributors,
  updateParams,
} from "../../PlantService";
import { DatePicker } from "antd";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { Bell } from "react-feather";
import ModalAlarm from "./components/ModalAlarm";

export const PlantConfig = ({ open, toggle, projName, projId }) => {
  const { t } = useTranslation();
  const { params, setLoadingData, selectedDate, reload, loadingData } =
    usePlantContext();

  const [market, setMarket] = useState(null);
  const [group, setGroup] = useState(null);
  const [tariffModality, setTariffModality] = useState(null);
  const [peakContractedDemand, setPeakContractedDemand] = useState(null);
  const [offPeakContractedDemand, setOffPeakContractedDemand] = useState(null);
  const [taxRateIcms, setTaxRateIcms] = useState(null);
  const [taxRatePisPasep, setTaxRatePisPasep] = useState(null);
  const [taxRateCofins, setTaxRateCofins] = useState(null);
  const [contractStart, setContractStart] = useState(null);
  const [modalListContracts, setModalListContracts] = useState(false);
  const [isAddingContract, setIsAddingContract] = useState(() => {
    if (params === 404 || params?.contracts?.length === 0) return true;
    return false;
  });
  const onCloseContract = () => setModalListContracts(false);
  const [modalAlarm, setModalAlarm] = useState(false);
  const onCloseAlarm = () => setModalAlarm(false);

  const [energyDistributorId, setEnergyDistributorId] = useState(null);
  const [distributor, setDistributor] = useState(null);
  const [refMeterId, setRefMeterId] = useState(null);
  const [metersId, setMetersId] = useState([]);

  const [distributors, setDistributors] = useState([]);
  const [modalities, setModalities] = useState([]);
  const [groups, setGroups] = useState([]);

  const [devices, setDevices] = useState([]);
  const [ucs, setUcs] = useState(devices);
  const [refMeter, setRefMeter] = useState(devices);

  const [validateFields, setValidateFields] = useState([
    {
      path: null,
      msg: null,
    },
  ]);

  const getValuesParams = async () => {
    if (params !== 404) {
      setMarket(params.market);
      setGroup(params.group);
      setTariffModality(params.tariffModality);
      setEnergyDistributorId(params.energyDistributorId);
      setDistributor(params.distributor);
      setMetersId(params ? JSON.parse(params.meters || []) : []);
      setRefMeterId(params ? JSON.parse(params.refMeter) : []);

      // CONTRATOS

      const actualContract = params.contracts.find(
        (contract) =>
          new Date(contract.date_start) <= new Date() &&
          (!contract.date_end || new Date(contract.date_end) >= new Date())
      );

      setTaxRateCofins(actualContract.percentConfins);
      setTaxRatePisPasep(actualContract.percentPisPasep);
      setTaxRateIcms(actualContract.percentICMS);
      setOffPeakContractedDemand(actualContract.contractedOffPeak);
      setPeakContractedDemand(actualContract.contractedPeak);
      setContractStart(
        moment(new Date(actualContract.date_start)).add(3, "hours")
      );
    }
    getDevices();
  };

  const getDevices = async () => {
    const devices = await listDevices(projId);
    const filteredDevices = devices.map((device) => {
      return {
        dvcId: device.dvcId,
        model: device.model,
        description: device.description,
        varlist: device.driver.varlist,
        energyStructure: device.driver.energyStructure
          ? device.driver.energyStructure
          : device.energyStructure,
      };
    });
    setDevices(filteredDevices);
  };

  const getDistributors = async (projId) => {
    const distributors = await listDistributors(projId);
    setDistributors(distributors);

    const distributor = distributors.find(
      (distributor) => distributor.name === params.distributor
    );
    if (distributor) {
      setModalities(JSON.parse(distributor.modalities));
      setGroups([]);
    }
  };

  async function handleCreate(projId) {
    setValidateFields([]);
    const preferences = {
      default: [
        "consumption",
        "demand",
        "powerFactor",
        "devicesInfo",
        "listMeters",
      ],
    };
    const params = await createParams(setValidateFields, setLoadingData, {
      projId,
      monthRef: !selectedDate ? new Date() : selectedDate,
      refMeter,
      market,
      group,
      tariffModality,
      energyDistributorId,
      peakContractedDemand,
      offPeakContractedDemand,
      taxRateIcms,
      taxRatePisPasep,
      taxRateCofins,
      meters: ucs || [],
      preferences,
      contract: {
        contractedPeak: peakContractedDemand,
        offPeakContractedDemand,
        percentICMS: taxRateIcms,
        percentPisPasep: taxRatePisPasep,
        percentConfins: taxRateCofins,
        date_start: moment(contractStart).format("YYYY-MM-DD"),
      },
    });

    if (params?.status !== 400) {
      toggle();
      reload();
    }
  }

  async function handleUpdate(projId) {
    setValidateFields([]);
    const params = await updateParams(setValidateFields, setLoadingData, {
      projId,
      monthRef: !selectedDate ? new Date() : selectedDate,
      refMeter,
      market,
      group,
      tariffModality,
      energyDistributorId,
      peakContractedDemand,
      offPeakContractedDemand,
      taxRateIcms,
      taxRatePisPasep,
      taxRateCofins,
      meters: ucs || [],
    });

    if (params?.status !== 400) {
      toggle();
      setLoadingData(true);
      reload();
    }
  }

  useEffect(() => {
    getValuesParams();
    getDistributors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projId, open]);

  const contracts = useMemo(() => {
    const contracts = params?.contracts || [];
    const last = contracts[contracts.length - 1];
    const actualContract = contracts.find(
      (contract) =>
        new Date(contract.date_start) <= new Date() &&
        (!contract.date_end || new Date(contract.date_end) >= new Date())
    );
    return {
      last,
      all: contracts,
      actual: actualContract,
    };
  }, [params]);

  const showButtonEditContract = useMemo(() => {
    if (isAddingContract) return false;
    if (contracts.all.length === 0) return true;
    const actual = contracts.actual;
    return (
      actual.percentConfins != taxRateCofins ||
      actual.percentPisPasep != taxRatePisPasep ||
      actual.percentICMS != taxRateIcms ||
      actual.contractedOffPeak != offPeakContractedDemand ||
      actual.contractedPeak != peakContractedDemand
    );
  }, [
    contracts.all.length,
    contracts.actual,
    isAddingContract,
    offPeakContractedDemand,
    peakContractedDemand,
    taxRateCofins,
    taxRateIcms,
    taxRatePisPasep,
  ]);

  const saveNewContract = async () => {
    try {
      const dataToSend = {
        idProj: projId,
        date_start: moment(contractStart).format("YYYY-MM-DD"),
        date_end: null,
        contractedPeak: peakContractedDemand,
        offPeakContractedDemand: offPeakContractedDemand,
        percentICMS: taxRateIcms,
        percentPisPasep: taxRatePisPasep,
        percentConfins: taxRateCofins,
      };
      const { data } = await axios.post(
        "/v2/energy/createContract",
        dataToSend
      );
      toastr.success(
        "Contrato salvo com sucesso",
        "Contrato salvo com sucesso"
      );
      setIsAddingContract(false);
      toggle();
      reload();
    } catch (error) {
      console.log(error);
      toastr.error("Erro ao salvar contrato", "Erro ao salvar contrato");
    }
  };

  const updateActualContract = async () => {
    try {
      const dataToSend = {
        id: contracts.actual.id,
        contract: {
          idProj: projId,
          date_start: moment(contracts.actual.date_start).format("YYYY-MM-DD"),
          date_end: moment(contractStart)
            .subtract(1, "days")
            .format("YYYY-MM-DD"),
          contractedPeak: peakContractedDemand,
          offPeakContractedDemand: offPeakContractedDemand,
          percentICMS: taxRateIcms,
          percentPisPasep: taxRatePisPasep,
          percentConfins: taxRateCofins,
        },
      };
      const { data } = await axios.put("/v2/energy/updateContract", dataToSend);
      toastr.success(
        "Contrato salvo com sucesso",
        "Contrato salvo com sucesso"
      );
      setIsAddingContract(false);
      toggle();
      reload();
    } catch (error) {
      console.log(error);
      toastr.error("Erro ao salvar contrato", "Erro ao salvar contrato");
    }
  };

  return (
    <>
      {params && (
        <Modal isOpen={open} toggle={toggle} size="lg">
          <ModalHeader>
            <Label>Configurações - {projName}</Label>
          </ModalHeader>

          <ModalBody
            style={{
              maxHeight: "calc(100dvh - 200px)",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <Card>
              <CardHeader
                style={{ background: "#153D77", fontSize: "18px" }}
                className="text-white font-weight-bold"
              >
                {t("params.tariff")}
              </CardHeader>
              <CardBody>
                <Row className="mt-2">
                  <Col sm={6}>
                    <Label htmlFor="market">{t("params.market")}</Label>
                    <Input
                      id="market"
                      type="select"
                      name="market"
                      value={market}
                      onChange={(e) => {
                        setMarket(e.target.value);
                      }}
                    >
                      <option hidden>{market ?? t("params.select")}</option>
                      <option value="Cativo">Cativo</option>
                    </Input>
                  </Col>
                  <Col sm={6}>
                    <Label htmlFor="modality">{t("params.distributor")}</Label>
                    <Input
                      id="modality"
                      type="select"
                      name="powerCompany"
                      value={energyDistributorId}
                      onChange={(e) => {
                        const distributorId = parseInt(e.target.value);
                        setEnergyDistributorId(distributorId);
                        const distributor = distributors.filter(
                          (distributor) => distributor.id === distributorId
                        );
                        setModalities(JSON.parse(distributor[0].modalities));
                        setTariffModality("");
                        setGroup("");
                      }}
                    >
                      <option hidden>
                        {distributor ?? t("params.select")}
                      </option>

                      {distributors.map((distributor) => (
                        <option key={distributor.id} value={distributor.id}>
                          {distributor.name}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={6}>
                    <Label htmlFor="tariff">{t("params.tariffModality")}</Label>
                    <Input
                      id="tariff"
                      type="select"
                      name="tariffModality"
                      value={tariffModality}
                      disabled={modalities.length === 0}
                      onChange={(e) => {
                        setTariffModality(e.target.value);
                        const modalitySelected = distributors.find(
                          (e) => e.id === energyDistributorId
                        );
                        const parsedTariff =
                          JSON.parse(modalitySelected.tariffs) || [];

                        const newArray = [];
                        parsedTariff.forEach((tariff) => {
                          if (
                            tariff.modalidade === e.target.value &&
                            !newArray.includes(tariff.subgrupo)
                          ) {
                            newArray.push(tariff.subgrupo);
                          }
                        });

                        setGroup("");
                        setGroups(newArray);
                      }}
                    >
                      <option hidden>
                        {tariffModality ?? t("params.select")}
                      </option>
                      {modalities?.map((modality) => (
                        <option key={modality} value={modality}>
                          {modality}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col sm={6}>
                    <Label htmlFor="group">{t("params.subgroup")}</Label>
                    <Input
                      id="group"
                      type="select"
                      name="group"
                      value={group}
                      disabled={groups.length === 0}
                      onChange={(e) => {
                        setGroup(e.target.value);
                      }}
                    >
                      <option hidden>{group ?? t("params.select")}</option>
                      {groups?.map((group) => (
                        <option key={group} value={group}>
                          {group}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardHeader
                style={{ background: "#153D77", fontSize: "18px" }}
                className="text-white font-weight-bold"
              >
                {t("params.refMeter")}
              </CardHeader>
              <CardBody>
                <RefMeter
                  devices={devices}
                  refMeterId={refMeterId}
                  setRefMeter={setRefMeter}
                  refMeter={refMeter}
                />
              </CardBody>
            </Card>

            <Card>
              <CardHeader
                style={{ background: "#153D77", fontSize: "18px" }}
                className="text-white font-weight-bold"
              >
                {t("params.unitConsumers")}
              </CardHeader>
              <CardBody>
                <UnitConsumers
                  devices={devices}
                  metersId={metersId}
                  setUcs={setUcs}
                  ucs={ucs}
                  refMeter={refMeter}
                />
              </CardBody>
            </Card>

            <Card>
              <CardHeader
                style={{ background: "#153D77", fontSize: "18px" }}
                className="text-white font-weight-bold d-flex justify-content-between"
              >
                <div>
                  <span>
                    {isAddingContract
                      ? `${t("params.newContract")}`
                      : ` ${t("params.contract")} ${
                          contracts.all.length > 0 ? t("params.actual") : ""
                        }`}
                  </span>
                  <button
                    className="bg-info-dark bg-transparent border-0 border-none ml-2 rounded-circle"
                    title="Alarmes do contrato"
                    onClick={() => setModalAlarm(true)}
                  >
                    <Bell size={16} />
                  </button>
                </div>
                {contracts.all.length > 0 && (
                  <div>
                    <Button
                      size="sm"
                      onClick={() => setModalListContracts(true)}
                    >
                      administrar contratos
                    </Button>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => {
                        setIsAddingContract(true);
                        setPeakContractedDemand("");
                        setOffPeakContractedDemand("");
                        setTaxRateIcms("");
                        setTaxRatePisPasep("");
                        setContractStart(moment(new Date()).add(3, "hours"));
                      }}
                      className="ml-2"
                    >
                      novo contrato
                    </Button>
                  </div>
                )}
              </CardHeader>
              <CardBody>
                <Row className="mt-2 d-flex justify-content-center">
                  <Col className="d-flex flex-column" sm={6}>
                    <Label htmlFor="peakDemand">
                      {t("params.contractPeak")}
                    </Label>
                    <Input
                      type="number"
                      mask="99.999,99"
                      id="peakDemand"
                      name="peakContractedDemand"
                      value={peakContractedDemand}
                      min={0}
                      onChange={(e) => {
                        let value = e.target.value;
                        if (Number(value) < 0) value = 0;
                        setPeakContractedDemand(value);
                      }}
                      onFocus={(e) => {
                        resetValidateFields(
                          "peakContractedDemand",
                          validateFields,
                          setValidateFields
                        );
                      }}
                    />
                    {validateFields.map((item, index) => {
                      return item.path === "peakContractedDemand" ? (
                        <small key={index} className="text-danger d-block">
                          {item.msg}
                        </small>
                      ) : null;
                    })}
                  </Col>
                  <Col className="d-flex flex-column" sm={6}>
                    <Label htmlFor="offPeakDemand">
                      {t("params.contractOffPeak")}
                    </Label>
                    <Input
                      type="number"
                      mask="99.999,99"
                      id="offPeakDemand"
                      min={0}
                      name="offPeakContractedDemand"
                      value={offPeakContractedDemand}
                      onChange={(e) => {
                        let value = e.target.value;
                        if (Number(value) < 0) value = 0;
                        setOffPeakContractedDemand(value);
                      }}
                      onFocus={(e) => {
                        resetValidateFields(
                          "offPeakContractedDemand",
                          validateFields,
                          setValidateFields
                        );
                      }}
                    />
                    {validateFields.map((item, index) => {
                      return item.path === "offPeakContractedDemand" ? (
                        <small key={index} className="text-danger d-block">
                          {item.msg}
                        </small>
                      ) : null;
                    })}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col className="d-flex flex-column" sm={4}>
                    <Label htmlFor="taxRateIcms">
                      {t("params.taxRateIcms")}
                    </Label>
                    <Input
                      type="number"
                      id="taxRateIcms"
                      name="taxRateIcms"
                      min={0}
                      value={taxRateIcms}
                      onChange={(e) => {
                        let value = e.target.value;
                        if (Number(value) < 0) value = 0;
                        setTaxRateIcms(value);
                      }}
                    />
                  </Col>
                  <Col className="d-flex flex-column" sm={4}>
                    <Label htmlFor="taxRatePisPasep">
                      {t("params.taxRatePisPasep")}
                    </Label>
                    <Input
                      type="number"
                      id="taxRatePisPasep"
                      name="taxRatePisPasep"
                      min={0}
                      value={taxRatePisPasep}
                      onChange={(e) => {
                        let value = e.target.value;
                        if (Number(value) < 0) value = 0;
                        setTaxRatePisPasep(value);
                      }}
                    />
                  </Col>
                  <Col className="d-flex flex-column" sm={4}>
                    <Label htmlFor="taxRateCofins">
                      {t("params.taxRateCofins")}
                    </Label>
                    <Input
                      type="number"
                      id="taxRateCofins"
                      name="taxRateCofins"
                      min={0}
                      value={taxRateCofins}
                      onChange={(e) => {
                        let value = e.target.value;
                        if (Number(value) < 0) value = 0;
                        setTaxRateCofins(value);
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col className="d-flex flex-column" sm={6}>
                    <Label>{t("params.startContract")}</Label>
                    {isAddingContract ? (
                      <DatePicker
                        value={contractStart}
                        format="DD/MM/YYYY"
                        onChange={(date) => date && setContractStart(date)}
                        className="h-100"
                        clearIcon={null}
                        disabledDate={(current) => {
                          if (contracts.all.length > 0) {
                            return (
                              current &&
                              current < moment(contracts.last.date_start)
                            );
                          } else {
                            return current && current > moment();
                          }
                        }}
                      />
                    ) : (
                      <Input
                        value={moment(contractStart).format("DD/MM/YYYY")}
                        disabled
                        className="h-100"
                      />
                    )}
                  </Col>
                  <Col className="d-flex flex-column" sm={6}>
                    <Label>{t("params.endContract")}</Label>
                    <Input
                      value={
                        isAddingContract || !contracts?.actual?.date_end
                          ? t("params.actual")
                          : moment(contracts?.actual.date_end)
                              .add(3, "hours")
                              .format("DD/MM/YYYY")
                      }
                      disabled
                      className="h-100"
                    />
                  </Col>
                </Row>
                {isAddingContract && params !== 404 && (
                  <>
                    {params !== 404 && (
                      <Row>
                        <div className="alert alert-info px-4 py-1 mt-2 ml-3">
                          {t("params.messageNewContract")}
                        </div>
                      </Row>
                    )}
                    <Row className="mt-2">
                      <Col className="d-flex justify-content-end">
                        <Button
                          onClick={() => {
                            setIsAddingContract(false);
                            setContractStart(
                              moment(new Date(contracts.actual.date_start)).add(
                                3,
                                "hours"
                              )
                            );

                            setPeakContractedDemand(
                              contracts.actual.contractedPeak
                            );
                            setOffPeakContractedDemand(
                              contracts.actual.contractedOffPeak
                            );
                            setTaxRateIcms(contracts.actual.percentICMS);
                            setTaxRatePisPasep(
                              contracts.actual.percentPisPasep
                            );
                            setTaxRateCofins(contracts.actual.percentConfins);
                          }}
                        >
                          {t("params.cancel")}
                        </Button>
                        <Button
                          color="primary"
                          onClick={saveNewContract}
                          className="ml-2"
                        >
                          {t("params.save")}
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
                {showButtonEditContract && (
                  <Row className="mt-2 ">
                    <Col className="d-flex justify-content-end">
                      <Button color="primary" onClick={updateActualContract}>
                        {t("params.editContract")}
                      </Button>
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </ModalBody>

          <ModalFooter>
            {params !== 404 ? (
              <Button
                color="primary"
                disabled={!refMeter || !group || isAddingContract}
                onClick={() => !loadingData && handleUpdate(projId)}
              >
                {loadingData ? <Spinner size="sm" /> : t("params.save")}
              </Button>
            ) : (
              <Button
                color="primary"
                disabled={
                  !refMeter ||
                  !group ||
                  !contractStart ||
                  peakContractedDemand === "" ||
                  offPeakContractedDemand === "" ||
                  taxRateIcms === "" ||
                  taxRatePisPasep === "" ||
                  taxRateCofins === "" ||
                  (ucs.length === 0 && refMeter.length === 0)
                }
                onClick={() => !loadingData && handleCreate(projId)}
              >
                {loadingData ? <Spinner size="sm" /> : t("params.create")}
              </Button>
            )}
            <Button color="secondary" onClick={toggle}>
              {t("params.cancel")}
            </Button>
          </ModalFooter>
          {modalListContracts && (
            <ModalListContracts onClose={onCloseContract} projId={projId} />
          )}
          {modalAlarm && <ModalAlarm onClose={onCloseAlarm} />}
        </Modal>
      )}
    </>
  );
};
const ModalListContracts = ({ onClose, projId }) => {
  const { t } = useTranslation();
  const { data, status, refetch } = useQuery({
    queryKey: ["listContracts", projId],
    queryFn: () =>
      axios
        .get(`/v2/energy/listContracts?projId=${projId}`)
        .then((res) => res.data),
  });

  const isLoading = status === "loading";
  const cantDelete = (contract) => {
    const today = moment().endOf("day");
    const contractEndDate = contract.date_end
      ? moment(contract.date_end).add(3, "hours")
      : null;
    const contractStartDate = moment(contract.date_start).add(3, "hours");

    // Se não tiver data fim e a data inicio for maior que hoje (contrato futuro)
    if (!contractEndDate && contractStartDate.isAfter(today)) {
      return false;
    }
    // Se for um contrato antigo (data de término menor ou igual a hoje)
    if (contractEndDate && contractEndDate.isSameOrBefore(today)) {
      return true;
    }

    // Se chegou aqui, é um contrato ativo e não pode ser deletado
    return true;
  };

  const handleDelete = async (contractId) => {
    try {
      const { data } = await axios.delete(
        `/v2/energy/deleteContract?id=${contractId}`
      );
      toastr.success(
        "Contrato deletado com sucesso",
        "Contrato deletado com sucesso"
      );
      refetch();
    } catch (error) {
      console.log(error);
      toastr.error("Erro ao deletar contrato", "Erro ao deletar contrato");
    }
  };

  if (isLoading)
    return (
      <Modal isOpen onClosed={onClose} size="lg">
        <ModalHeader>{t("params.contracts")}</ModalHeader>
        <ModalBody className="d-flex justify-content-center">
          <Spinner />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onClose}>
            {t("params.close")}
          </Button>
        </ModalFooter>
      </Modal>
    );

  return (
    <Modal isOpen toggle={onClose} size="lg">
      <ModalHeader>{t("params.contracts")}</ModalHeader>
      <ModalBody>
        {data?.length > 0 ? (
          <table className="table table-striped">
            <thead>
              <tr>
                <th></th>
                <th>{t("params.startContract")}</th>
                <th>{t("params.endContract")}</th>
                <th>{t("params.contractPeak")}</th>
                <th>{t("params.contractOffPeak")}</th>
                <th>{t("params.taxRateIcms")}</th>
                <th>{t("params.taxRatePisPasep")}</th>
                <th>{t("params.taxRateCofins")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody
              style={{
                maxHeight: "calc(100vh - 300px)",
                overflowY: "auto",
              }}
            >
              {data.map((contract, index) => (
                <tr key={index}>
                  <td>{contract.id}</td>
                  <td>
                    {moment(new Date(contract.date_start))
                      .add(3, "hours")
                      .format("DD/MM/YYYY")}
                  </td>
                  <td>
                    {contract.date_end
                      ? moment(new Date(contract.date_end))
                          .add(3, "hours")
                          .format("DD/MM/YYYY")
                      : "-"}
                  </td>
                  <td>{contract.contractedPeak.toLocaleString()}</td>
                  <td>{contract.contractedOffPeak.toLocaleString()}</td>
                  <td>{Number(contract.percentICMS)}%</td>
                  <td>{Number(contract.percentPisPasep)}%</td>
                  <td>{Number(contract.percentConfins)}%</td>
                  <td>
                    <Button
                      color="danger"
                      size="sm"
                      disabled={cantDelete(contract)}
                      onClick={() => handleDelete(contract.id)}
                    >
                      {t("params.delete")}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>{t("params.noContracts")}</p>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          {t("params.close")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
