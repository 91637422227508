const ptBrGlobalTranslations = {
  reports: {
    month1: "Janeiro",
    month2: "Fevereiro",
    month3: "Março",
    month4: "Abril",
    month5: "Maio",
    month6: "Junho",
    month7: "Julho",
    month8: "Agosto",
    month9: "Setembro",
    month10: "Outubro",
    month11: "Novembro",
    month12: "Dezembro",
    monthJan: "JAN/",
    monthFev: "FEV/",
    monthMar: "MAR/",
    monthAbr: "ABR/",
    monthMai: "MAI/",
    monthJun: "JUN/",
    monthJul: "JUL/",
    monthAgo: "AGO/",
    monthSet: "SET/",
    monthOut: "OUT/",
    monthNov: "NOV/",
    monthDez: "DEZ/",
    deleteEmail: "Deletar E-mail",
  },
  page404: {
    notFound: "Página não encontrada",
    removed: "A página que você procura pode ter sido removida",
    return: "Página Inicial",
  },
  searchBar: {
    logout: "Sair",
    home: "Página Inicial",
    dashboards: "Dashboards",
    dataExplorer: "Data Explorer",
    allCards: "Todos Cards",
    newCard: "Novo Card",
    MeusCards: "Meus Cards",
    projects: "Minhas Plantas",
    alarms: "Alarmes",
    registers: "Configurações",
    settings: "Preferências",
    devices: "Dispositivos",
    alarmView: "Histórico de Alarmes",
    alarmConfig: "Parâmetros de Alarmes",
    usersData: "Registros de Empresas e Usuários",
    projectsData: "Registros de Plantas e Dispositivos",
    alarmsData: "Registros de Parâmetros de Alarmes",
  },
  gateways: {
    title: "Visão Geral de Gateways",
    clickText:
      "Clique sobre o nome de um gateway para ver detalhes acerca do seu status",
    expandText1: "Clique em",
    expandText2:
      "para visualizar os dispositivos periféricos vinculados a cada gateway",
    description: "Referência",
    model: "Modelo",
    serialNumber: "Número de Série",
    projectName: "Projeto",
    companyName: "Empresa",
    lastComm: "Última Com.",
    commInterval: "Int. de Publicação",
    actions: "Ações",
    modalTitle: "Status de",
    upTime: "Tempo desde inicialização",
    connectedDvcs: "Nº de dispositivos vinculados",
    usedStorage: "Memória utilizada",
    usedMem: "Uso de memória atual",
    connMode: "Modo de conexão",
    MACAddress: "MAC Address",
    gwIP: "IP de rede",
    dhcp: "Conexão DHCP",
    gateway: "IP do Gateway",
    subnetMask: "Máscara",
    DNS: "IP do DNS",
    rssi: "Intensidade do sinal",
    gwVersion: "Versão de firmware",
    gwHardware: "Versão de hardware",
    linkSpeed: "Velocidade da conexão",
    ago: "atrás",
    unknown: "Desconhecido",
    yes: "Sim",
    no: "Não",
    noGateway: "Nenhum Gateway encontrado",
    noPeripherical: "Nenhum dispositivo vinculado ao Gateway",
    noStatus: "Não há dados sobre o status do Gateway",
    refresh: "Atualizar",
    close: "Fechar",
  },
  dataTable: {
    title: "Visão Geral de Empresas e Usuários",
    head: "Registros de Empresas e Usuários",
    text1: "Clique em",
    text2: "para visualizar os usuários vinculados a cada empresa.",
    newCompany: "Adicionar empresa",
    insertCompany: "Insira os dados da empresa",
    insertUser: "Insira os dados do usuário administrador",
    id: "Nº",
    name: "Nome",
    enable: "Habilitar",
    contactName: "Responsável",
    search: "Busca",
    company: "Empresa",
    type: "Tipo",
    actions: "Ações",
    newUser: "Novo Usuário",
    password: "Senha",
      checkDefaultPassword: "Senha padrão",
    fullName: "Nome Completo",
    userType: "Tipo de Usuário",
    default: "Padrão",
    admin: "Administrador",
    endUser: "Usuário Final",
    module: "Módulo",
    water: "Água",
    username: "Nome de Usuário",
    cancel: "Cancelar",
    saveContinue: "Salvar e continuar",
    saveUsr: "Salvar usuário",
    linkCompany: "Vincular empresa",
    saveCompany: "Salvar empresa",
    editHead: "Editar dados de ",
    editSave: "Salvar alterações",
    editComp: "Editar empresa",
    editUsers: "Editar usuário",
    editContactNot: 'O usuário de contato deve ser do tipo "admin".',
    editUserNot: 'Não é possível definir a si próprio como "padrão".',
    delete: "Remover",
    deleteHead: "Remover ",
    deleteBody:
      'Essa ação é irreversível. Caso deseje continuar, por favor, digite "DELETE" no campo abaixo',
    deleteUsr: "Remover Usuário",
    deleteUsrMany: "Remover Usuários",
    deleteComp: "Remover Empresa",
    deleteCompMany: "Remover Empresas",
    deleteContact1: "é o usuário responsável pelo contato da empresa",
    deleteContact2:
      "Por favor, selecione um usuário para ser o novo responsável",
    deleteNotAll: "Não é possível excluir todos os usuários de uma empresa.",
    deleteNotComp: "Não é possível excluir a própria empresa.",
    deleteNotUser: "Não é possível excluir a si próprio.",
    deleteHeadComp: "Remover empresas selecionadas",
    deleteBodyComp: "As empresas selecionadas serão removidas.",
    deletedManyComp: "As empresas foram removidas.",
    deleteHeadUsrs: "Remover usuários selecionados",
    deleteBodyUsrs: "Os usuários selecionados serão removidos.",
    deletedManyUsrs: "Os usuários foram removidos.",
    success: "Sucesso",
    att: "Atenção",
    error: "Erro",
    captcha: "Captcha incorreto",
    check1comp: "empresa selecionada",
    check2comp: "empresas selecionadas",
    check1user: "usuário selecionado",
    check2user: "usuários selecionados",
    disableComp: "As empresas selecionadas foram desabilitadas.",
    enableComp: "As empresas selecionadas foram habilitadas.",
    disableUsrs: "Os usuários selecionados foram desabilitados.",
    enableUsrs: "Os usuários selecionados foram habilitados.",
    noUsers: "Não foram encontrados usuários",
    noComps: "Não foram encontradas empresas",
  },
  none: "Não há dados",
  locales: "pt-br",
  dateRangeSelector: {
    today: "Hoje",
    last24Hours: "Últimas 24h",
    last7Days: "Últimos 7d",
    last30Days: "Últimos 30d",
    last12Months: "Últimos 12m",
    "24Hours": "24h",
    "7D": "7d",
    fullMonth: "Mensal",
    fullYear: "Anual",
    custom: "Data Personalizada",
    selectStartDate: "Selecione a data inicial",
    selectEndDate: "Selecione a data final",
    apply: "Aplicar"
  },
  fieldRequired: "Campo obrigatório",
};

export default ptBrGlobalTranslations;
