import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  FormText,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Tooltip,
  Spinner,
  UncontrolledTooltip,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretUp,
  faSearch,
  faSort,
  faPlus,
  faQuestionCircle
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";
import { Cpu } from "react-feather";
import { ModalAddInBatches } from "./components/ModalAddInBatches";

/****** MODAL DE REGISTRO DE PROJETO ******/
const ModalNewProject = ({ open, toggle, setLoadProjects }) => {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(true);
  const [comps, setComps] = useState([]);
  const [formComp, setFormComp] = useState(localStorage.getItem("companyId"));
  const [formName, setFormName] = useState("");
  const [formDesc, setFormDesc] = useState("");
  const [formAddress, setFormAddress] = useState(null);
  const [formNeighborhood, setFormNeighborhood] = useState(null);
  const [formCity, setFormCity] = useState(null);
  const [formState, setFormState] = useState(null);
  const [formZipCode, setFormZipCode] = useState(null);

  const [validateFields, setValidateFields] = useState([{
    path: null, 
    msg: null
  }]);

  function resetValidateFields (path) {
    const resetValidateFields = validateFields.filter((item) => {  
      return item.path !== path
    })
    setValidateFields(resetValidateFields);
  }

  useEffect(() => {
    axios
      .get("/companySearch")
      .then((res) => {
        setComps(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // t() da problema com o useEffect
        toastr.error("Erro!", "Erro ao buscar empresas.");
      });
  }, []);

  return (
    <Modal isOpen={open} toggle={toggle}>
      <Form
        onSubmit={(e) => {
          e.preventDefault();

          const btn = e.nativeEvent.submitter.name;

          /* Salvar dados do formulário no formato JSON */
          const jsonData = JSON.stringify({
            companyId: formComp,
            name: formName,
            description: formDesc,
            address: formAddress,
            neighborhood: formNeighborhood,
            city: formCity,
            state: formState,
            zipCode: formZipCode
          });

          /*** Envio de dados ***/
          axios
            .post("/v2/project/createProject", jsonData)
            .then((response) => {

              toastr.success(response.data.message);

              // setLoadingSave(true); // Atualiza tabela

              /* se dados foram enviados pelo botão "salvar dados"... */
              if (btn === "btnClose") {
                toggle(); // ...fechar o modal
              } else {
                // se foi pelo "salvar e continuar"...
                /* ...limpar campos do formulario */
                setFormName("");
                setFormDesc("");
              }
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.status === 400) {
                  setValidateFields(error.response.data.errors);
                }
              }
            })
            .finally(() => {
              setLoadProjects(true);
            });
        }}
      >
        <ModalHeader toggle={toggle}>{t("projTable.addProject")}</ModalHeader>

        <ModalBody className="text-left mx-2 p-4">

        <Card>
          <CardHeader  
            style={{fontSize: '20px'}} 
            className="font-weight-bold"
          >
            {t("projTable.detailsProject")}
          </CardHeader>

          <CardBody>
            {/*** CAMPO DE EMPRESA ***/}
            {isLoading || !comps.length ? (
              <div className="text-center">
                <Spinner color="primary" />
              </div>
            ) : (
              <FormGroup>
                <Label>{t("projTable.company")}</Label>
                <Input
                  type="select"
                  name="companyId"
                  id="companyId"
                  defaultValue={formComp}
                  onChange={(e) => setFormComp(e.target.value)}
                  onFocus={(e) => {
                    resetValidateFields("companyId");
                  }}
                >
                  {comps.map((item, i) => {
                    return (
                      <option value={item.id} key={i}>
                        {item.name}
                      </option>
                    );
                  })}
                </Input>
                {validateFields.map((item, index) => {
                  return item.path === "companyId" ? (
                    <small key={index} className="text-danger d-block">
                      {item.msg}
                    </small>  
                  ) : null;
                })}
              </FormGroup>
            )}

            {/*** NOME ***/}
            <FormGroup>
              <Label>{t("projTable.name")}</Label>
              <Input
                type="text"
                name="name"
                id="name"
                value={formName}
                onChange={(e) => setFormName(e.target.value)}
                onFocus={(e) => {
                  resetValidateFields("name");
                }}
              />
              {validateFields.map((item, index) => {
                return item.path === "name" ? (
                  <small key={index} className="text-danger d-block">
                    {item.msg}
                  </small>  
                ) : null;
              })}
            </FormGroup>

            {/*** DESCRIÇÃO ***/}
            <FormGroup>
              <Label>{t("projTable.description")}</Label>
              <Input
                type="textarea"
                name="description"
                id="description"
                value={formDesc}
                onChange={(e) => setFormDesc(e.target.value)}
                onFocus={(e) => {
                  resetValidateFields("description");
                }}
              />
              {validateFields.map((item, index) => {
                return item.path === "description" ? (
                  <small key={index} className="text-danger d-block">
                    {item.msg}
                  </small>  
                ) : null;
              })}
            </FormGroup>

          </CardBody>
        </Card>

        <Card>

          <CardHeader  
            style={{ fontSize: '20px'}} 
            className="font-weight-bold"
          >
            {t("projTable.addressProject")}
          </CardHeader>

          <CardBody>
          {/*** ENDEREÇO ***/}
          <FormGroup>
            <Label>{t("projTable.address")}</Label>
              <Input
                type="text"
                name="address"
                id="address"
                value={formAddress}
                maxLength={150}
                onChange={(e) => setFormAddress(e.target.value)}
                onFocus={(e) => {
                  resetValidateFields("address");
                }}
              />
              {validateFields.map((item, index) => {
                return item.path === "address" ? (
                  <small key={index} className="text-danger d-block">
                    {item.msg}
                  </small>  
                ) : null;
              })}
          </FormGroup>

          <Row>
            <Col md={5}>
              {/*** BAIRRO ***/}
              <FormGroup>
                <Label>{t("projTable.neighborhood")}</Label>
                  <Input
                    type="text"
                    name="neighborhood"
                    id="neighborhood"
                    value={formNeighborhood}
                    maxLength={100}
                    onChange={(e) => setFormNeighborhood(e.target.value)}
                    onFocus={(e) => {
                      resetValidateFields("neighborhood");
                    }}
                  />
                {validateFields.map((item, index) => {
                  return item.path === "neighborhood" ? (
                    <small key={index} className="text-danger d-block">
                      {item.msg}
                    </small>  
                  ) : null;
                })}
              </FormGroup>
            </Col>

            <Col md={5}>
              {/*** CIDADE ***/}
              <FormGroup>
                <Label>{t("projTable.city")}</Label>
                  <Input
                    type="text"
                    name="city"
                    id="city"
                    value={formCity}
                    maxLength={100}
                    onChange={(e) => setFormCity(e.target.value)}
                    onFocus={(e) => {
                      resetValidateFields("city");
                    }}
                  />
                {validateFields.map((item, index) => {
                  return item.path === "city" ? (
                    <small key={index} className="text-danger d-block">
                      {item.msg}
                    </small>  
                  ) : null;
                })}
              </FormGroup>
            </Col>

            <Col md={2}>
              {/*** ESTADO ***/}
              <FormGroup>
                <Label>{t("projTable.state")}</Label>
                  <Input
                    type="text"
                    name="state"
                    id="state"
                    value={formState}
                    maxLength={2}
                    onChange={(e) => setFormState(e.target.value.toUpperCase())}
                    onFocus={(e) => {
                      resetValidateFields("state");
                    }}
                  />
                {validateFields.map((item, index) => {
                  return item.path === "state" ? (
                    <small key={index} className="text-danger d-block">
                      {item.msg}
                    </small>  
                  ) : null;
                })}
              </FormGroup>
            </Col>

          </Row>

          <Row>
            <Col md={6}>
              {/*** CEP ***/}
              <FormGroup>
                <Label>{t("projTable.zipCode")}</Label>
                  <Input
                    type="text"
                    name="zipCode"
                    id="zipCode"
                    value={formZipCode}
                    maxLength={15}
                    onChange={(e) => setFormZipCode(e.target.value)}
                    onFocus={(e) => {
                      resetValidateFields("zipCode");
                    }}
                  />
                {validateFields.map((item, index) => {
                  return item.path === "zipCode" ? (
                    <small key={index} className="text-danger d-block">
                      {item.msg}
                    </small>  
                  ) : null;
                })}
              </FormGroup>
            </Col>
          </Row>
          </CardBody>
        </Card>
        </ModalBody>

        <ModalFooter>
          {/*** BOTÃO DE CANCELAR *
           * (apenas fecha o modal sem enviar nada para a API) ***/}
          <Button
            name="btnCancel"
            color="primary"
            className="mr-1 mb-1"
            onClick={toggle}
            outline
          >
            {t("projTable.cancel")}
          </Button>
          {/*** BOTÃO DE ENVIAR DADOS PARA A API SEM FECHAR O MODAL ***/}
          <Button name="btnOpen" type="submit" color="primary">
            {t("projTable.saveContinue")}
          </Button>
          {/*** BOTÃO DE ENVIAR DADOS E FECHAR O MODAL ***/}
          <Button name="btnClose" type="submit" color="primary">
            {t("projTable.saveProj")}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

/****** MODAL DE ADICIONAR E REMOVER USUÁRIOS A PROJETO ******/
const ModalAddUser = ({ open, toggle, proj }) => {
  const { t } = useTranslation();

  const [loadUsersComp, setLoadUsersComp] = useState(true);
  const [companyUsers, setCompanyUsers] = useState([]);
  const [loadUsersProj, setLoadUsersProj] = useState(false);
  const [projectUsersIds, setProjectUsersIds] = useState([]);
  const [selectedUsersIds, setSelectedUsersIds] = useState([]);

  // Busca usuários
  useEffect(() => {
    // Busca usuários da empresa (e empresas-mãe)
    if (loadUsersComp) {
      // axios.post('parentUserSearch/', JSON.stringify({
      //   projCompanyId: proj.companyId,
      //   userCompanyId: localStorage.getItem('companyId')
      // }))
      axios
        .get("/parentUserSearch", {
          params: {
            projCompanyId: proj.companyId,
            // userCompanyId: localStorage.getItem('companyId')
          },
        })
        .then((res) => {
          setCompanyUsers(res.data);
          setLoadUsersComp(false);
          setLoadUsersProj(true);
        })
        .catch((err) => {
          console.log(err);
          toastr.error("Erro!", "Erro ao buscar usuários da empresa."); // t() da problema com o useEffect
        });
    }

    // Busca usuários já vinculados ao projeto
    if (loadUsersProj) {
      // axios.post('userProjectSearch/', JSON.stringify({
      //   projId: proj.id
      // }))
      axios
        .get("/userProjectSearch", {
          params: {
            projId: proj.id,
          },
        })
        .then((res) => {
          let ids = [];
          res.data.map((usr) => ids.push(usr.id));
          setProjectUsersIds(ids);
          setSelectedUsersIds(ids);
          setLoadUsersProj(false);
        })
        .catch((err) => {
          console.log(err);
          toastr.error("Erro!", "Erro ao buscar usuários do projeto."); // t() da problema com o useEffect
        });
    }
  }, [loadUsersComp, loadUsersProj, proj]);

  /*** configurações das colunas da tabela de usuários ***/
  const colsUsr = [
    {
      // ID (oculto)
      dataField: "userId",
      text: `${t("projTable.id")}`,
      sort: true,
      hidden: true,
    },
    {
      // nome de usuário
      dataField: "name",
      text: t("projTable.name"),
      sort: true,
    },
    {
      // empresa
      dataField: "company",
      text: t("projTable.company"),
      sort: true,
    },
  ];

  /* Caixas de seleção de linha */
  const selectRow = {
    mode: "checkbox",
    bgColor: "var(--main-background)",
    selected: selectedUsersIds,
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setSelectedUsersIds([...selectedUsersIds, row.id]);
      } else {
        setSelectedUsersIds(selectedUsersIds.filter((x) => x !== row.id));
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        let ids = [];
        rows.map((row) => ids.push(row.id));
        setSelectedUsersIds(ids);
      } else {
        setSelectedUsersIds([]);
      }
    },
  };

  /* Ícones de ordenação */
  const sortIcon = {
    sortCaret: (order) =>
      !order ? (
        <FontAwesomeIcon className={"fa-xs ml-1"} icon={faSort} />
      ) : order === "asc" ? (
        <FontAwesomeIcon className={"fa-xs ml-1"} icon={faCaretUp} />
      ) : (
        <FontAwesomeIcon className={"fa-xs ml-1"} icon={faCaretDown} />
      ),
  };

  const defaultSorted = [
    {
      dataField: "name", // if dataField is not match to any column you defined, it will be ignored.
      order: "asc", // desc or asc
    },
  ];

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {t("projTable.changeUsers")} {proj.name}
      </ModalHeader>

      <ModalBody className="px-6" style={{
        maxHeight: "80dvh",
        overflowY: "auto",
        width: "100%"
      }}>
        <p>
          {t("projTable.changeText")} {proj.name}
        </p>

        <BootstrapTable
          bootstrap4
          keyField="id"
          data={companyUsers}
          columns={colsUsr}
          condensed
          bordered={false}
          striped={true}
          selectRow={selectRow}
          sort={sortIcon}
          defaultSorted={defaultSorted}
        />
      </ModalBody>

      <ModalFooter>
        {/*** BOTÃO DE CANCELAR ***/}
        <Button
          name="btnCancel"
          color="primary"
          className="mr-1 mb-1"
          onClick={toggle}
          outline
        >
          {t("projTable.cancel")}
        </Button>

        {/*** BOTÃO DE ENVIAR DADOS E FECHAR O MODAL ***/}
        <Button
          name="btnClose"
          type="submit"
          color="primary"
          onClick={(e) => {
            e.preventDefault(); // evitar atualizar página

            /* Adiciona usuários selecionados ao projeto *
             * caso já não estejam no projeto antes */
            selectedUsersIds.forEach((usrId, i) => {
              if (!projectUsersIds.includes(usrId)) {
                axios
                  .post(
                    "addUserToProject/",
                    JSON.stringify({
                      userId: usrId,
                      projId: proj.id,
                    })
                  )
                  .then(() => {
                    // console.log(`${usrId} adicionado`);
                  })
                  .catch((err) => {
                    console.log(err);
                    console.log(err.response);

                    if (err.response) {
                      /* Notificação de erro de envio */
                      toastr.warning(
                        err.response.data.messageHead,
                        err.response.data.messageBody
                      );
                    }
                  });
              }
            });

            /* Remove usuários não-selecionados do projeto */
            projectUsersIds.forEach((usrId, i) => {
              if (!selectedUsersIds.includes(usrId)) {
                // axios.post('removeUserFromProject/', JSON.stringify({
                //   userId: usrId,
                //   projId: proj.id
                // }))
                axios
                  .delete("removeUserFromProject/", {
                    data: {
                      userId: usrId,
                      projId: proj.id,
                    },
                  })
                  .then(() => {
                    // console.log(`${usrId} removido`);
                  })
                  .catch((err) => {
                    console.log(err);
                    console.log(err.response);

                    if (err.response) {
                      /* Notificação de erro de envio */
                      toastr.warning(
                        err.response.data.messageHead,
                        err.response.data.messageBody
                      );
                    }
                  });
              }
            });

            toastr.success(
              `${t("projTable.success")}!`,
              `${t("projTable.projectUpdated")}.`
            ); // Notificação
            toggle(); // fecha o modal
          }}
        >
          {t("projTable.saveProj")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

/****** MODAL DE NOVO PERIFÉRICO ******/
const ModalNewPP = ({
  toggle,
  proj,
  selected,
  setSelected,
  setLoadDevices,
  deviceList,
}) => {
  const { t } = useTranslation();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [toggleMultipleDevices, setToggleMultipleDevices] = useState(false);
  const [numberOfDevices, setNumberOfDevices] = useState();

  const [manufacturers, setManufacturers] = useState([]); // lista de fabricantes
  const [models, setModels] = useState([]); // lista de modelos

  const [formManuf, setFormManuf] = useState(""); // fabricante selecionado
  const [formModel, setFormModel] = useState(""); // modelo selecionado
  const [formModelID, setFormModelID] = useState(""); // id do modelo selecionado
  const [formDesc, setFormDesc] = useState(""); // descrição
  const [formGW, setFormGW] = useState(""); // gateway do dispositivo
  const [formInterval, setFormInterval] = useState(""); // tempo de atualização
  const [formLatitude, setFormLatitude] = useState(""); // latitude
  const [formLongitude, setFormLongitude] = useState(""); // longitude
  const [intUnit, setIntUnit] = useState(1); // unidade de tempo de indicação (s, min, h)

  const [tooltipLatitudeOpen, setTooltipLatitudeOpen] = useState(false);
  const [tooltipLongitudeOpen, setTooltipLongitudeOpen] = useState(false);
  const toggleTooltipLatitude = () => setTooltipLatitudeOpen(!tooltipLatitudeOpen);
  const toggleTooltipLongitude = () => setTooltipLongitudeOpen(!tooltipLongitudeOpen);

  const [invalidManuf, setInvalidManuf] = useState(false); // estado de fabricante invalido
  const [invalidModel, setInvalidModel] = useState(false); // estado de modelo invalido
  const [invalidDesc, setInvalidDesc] = useState(false); // estado de descrição invalido
  const [invalidGW, setInvalidGW] = useState(false); // estado de GW invalido
  const [invalidInterval, setInvalidInterval] = useState(false); // estado de tempo invalido

  const [loading, setLoading] = useState(false); // estado de carregamento envio de dados para api

  // busca lista de fabricantes
  useEffect(() => {
    axios
      .get("deviceManufacturerSearch/")
      .then((res) => {
        setManufacturers(res.data);
      })
      .catch((err) => {
        console.log(err);
        // t() da problema com o useEffect
        toastr.error("Erro!", "Erro ao buscar fabricantes.");
      });
  }, []);

  // busca lista de modelos do fabricante selecionado
  useEffect(() => {
    if (formManuf) {
      axios
        .get("/deviceModelSearch", {
          params: {
            name: formManuf,
          },
        })
        .then((res) => {
          // console.log(res, "RES");
          setModels(res.data);
        })
        .catch((err) => {
          console.log(err);
          // t() da problema com o useEffect
          toastr.warning("Erro!", "Erro ao buscar modelos.");
        });
    }
  }, [formManuf]);

  let gwList = deviceList.filter((item) => item.category === "gw");

  function onSubmitForm(btn) {
    if (
      !formManuf ||
      !formModel ||
      !formDesc ||
      (gwList.length && !formGW) ||
      !formInterval
    ) {
      setInvalidManuf(!formManuf);
      setInvalidModel(!formModel);
      setInvalidDesc(!formDesc);
      setInvalidGW(gwList.length && !formGW);
      setInvalidInterval(!formInterval);

      toastr.warning(
        `${t("projTable.att")}!`,
        `${t("projTable.invalidText")}.`
      );
    } else if (numberOfDevices > 24) {
      toastr.warning("Atenção", "Limite de 10 dispositivos");
    } else if (formInterval * intUnit < 60) {
      toastr.warning("Erro!", "O número é invalido");
    } else {
      /* Salvar dados do formulário no formato JSON */
      const jsonData = {
        projId: proj.id,
        category: selected,
        gatewayId: formGW,
        manufacturer: formManuf,
        model: formModel,
        description: formDesc,
        commInterval: formInterval * intUnit,
        latitude: formLatitude,
        longitude: formLongitude,
        numberOfDevices: numberOfDevices ? numberOfDevices : null,
        customDriversId: formModelID,
      };

      // console.log('novo PP:', jsonData);

      setLoading(true);
      /*** Envio de dados ***/
      axios
        .post("addDevice/", JSON.stringify(jsonData))
        .then((res) => {
          setLoading(false);
          toastr.success(res.data.messageHead, res.data.messageBody);
          setLoadDevices(true); // Atualiza tabela

          /* se dados foram enviados pelo botão "salvar dados"... */
          if (btn === "btnClose") {
            toggle(); // ...fechar o modal
          } else {
            // se foi pelo "salvar e continuar"...
            /* ...limpar campos do formulario */
            // setFormDesc('');
            // setFormHash('');
            // setFormIR('');
            // setACList([]);

            setSelected(0);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          console.log(err.response);

          if (err.response) {
            /* Notificação de erro de envio */
            toastr.warning(
              err.response.data.messageHead,
              err.response.data.messageBody
            );
          }
        });
    }
  }

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();

        const btn = e.nativeEvent.submitter.name;
        onSubmitForm(btn);
      }}
    >
      <ModalBody className="text-left mx-6">
        <h5 className="text-center">
          {t("projTable.new")} {t("projTable." + selected)}
        </h5>

        <hr />

        {/*** CAMPO DE FABRICANTE ***/}
        <FormGroup>
          <Label>{t("projTable.manufacturer")}</Label>
          <Input
            type="select"
            name="manufacturer"
            id="manufacturer"
            value={formManuf}
            onChange={(e) => {
              setFormManuf(e.target.value);
              setFormModel("");
              setInvalidManuf(false);
              setInvalidModel(false);
            }}
            invalid={invalidManuf}
            disabled={loading}
          >
            <option value="" disabled>
              {t("projTable.select")}
            </option>
            {manufacturers.map((item, i) => (
              <option value={item} key={i}>
                {item}
              </option>
            ))}
            <option value="myDrivers">Meus Drivers</option>
          </Input>
        </FormGroup>

        {/* CAMPO DE MODELO */}
        <FormGroup>
          <Label>{t("projTable.model")}</Label>
          <Input
            type="select"
            name="model"
            id="model"
            value={formModel}
            onChange={(e) => {
              setFormModel(e.target.value);
              setFormModelID(
                models.map((item) => item.id)[e.target.selectedIndex - 1] || ""
              );
              setInvalidModel(false);
            }}
            // disabled={(!models.length)}
            disabled={!models.length || loading}
            invalid={invalidModel}
          >
            <option value="" disabled>
              {t("projTable.select")}
            </option>
            {models.map((item, i) => (
              <option value={item.model} key={i}>
                {item.model}
              </option>
            ))}
          </Input>
        </FormGroup>

        {/* CAMPO DE DESCRIÇÃO */}
        <FormGroup>
          <Label>{t("projTable.reference")}</Label>
          <Input
            type="text"
            name="description"
            id="description"
            value={formDesc}
            onChange={(e) => {
              setFormDesc(e.target.value);
              setInvalidDesc(false);
            }}
            invalid={invalidDesc}
            disabled={loading}
          />
        </FormGroup>

        {/* CAMPO DE GATEWAY DO DISPOSITIVO */}
        {gwList.length ? (
          <FormGroup>
            <Label>{t("projTable.gatewayId")}</Label>
            <Input
              type="select"
              name="deviceGW"
              id="deviceGW"
              value={formGW}
              onChange={(e) => {
                setFormGW(e.target.value);
                setInvalidGW(false);
              }}
              invalid={invalidGW}
              disabled={loading}
            >
              <option value="" disabled>
                {t("projTable.select")}
              </option>
              {gwList.map((item, i) => (
                <option value={item.id} key={i}>
                  {item.description}
                </option>
              ))}
            </Input>
          </FormGroup>
        ) : null}

        {/*** CAMPO DE TEMPO DE ATUALIZAÇÃO ***/}
        <FormGroup>
          <Label>{t("projTable.commInterval")}</Label>
          <Row>
            <Col md={4}>
              <Input
                type="number"
                name="commInterval"
                id="commInterval"
                value={formInterval}
                onChange={(e) => {
                  setFormInterval(e.target.value);
                  setInvalidInterval(false);
                }}
                invalid={invalidInterval}
                disabled={loading}
              />
            </Col>

            <Col md={4}>
              <Input
                className="pt-0"
                type="select"
                name="intUnit"
                id="intUnit"
                value={intUnit}
                onChange={(e) => setIntUnit(e.target.value)}
                disabled={loading}
              >
                <option value={1}>{t("projTable.seconds")}</option>
                <option value={60}>{t("projTable.minutes")}</option>
                <option value={3600}>{t("projTable.hours")}</option>
              </Input>
            </Col>
            <Col md={4} className="text-right">
              <Button
                type="button"
                id="TooltipForPlusButton"
                color="primary"
                size="sm"
                onClick={() => {
                  setToggleMultipleDevices(!toggleMultipleDevices);
                }}
              >
                <FontAwesomeIcon fixedWidth icon={faPlus} />
              </Button>
              <Tooltip
                isOpen={tooltipOpen}
                placement="top"
                target="TooltipForPlusButton"
                toggle={() => {
                  setTooltipOpen(!tooltipOpen);
                }}
              >
                Adicionar mais dispositivos
              </Tooltip>
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Row>
              {/* LATITUDE */}
              <Col md={6}>
                <Label>
                  {t("projTable.latitude")}
                  <FontAwesomeIcon id="latitudeHelpIcon" fixedWidth icon={faQuestionCircle} />
                  <Tooltip isOpen={tooltipLatitudeOpen} target="latitudeHelpIcon" toggle={toggleTooltipLatitude}>
                  {t("projTable.tooltipLatitude")}
                  </Tooltip>
                </Label>
                <Input
                  type="text"
                  name="latitude"
                  value={formLatitude}
                  onChange={(e) => {
                    setFormLatitude(e.target.value);
                  }}
                  disabled={loading}
                />
              </Col>
              
              {/* LONGITUDE */}
              <Col md={6}>
                <Label>
                  {t("projTable.longitude")}
                  <FontAwesomeIcon id="longitudeHelpIcon" fixedWidth icon={faQuestionCircle} />
                  <Tooltip isOpen={tooltipLongitudeOpen} target="longitudeHelpIcon" toggle={toggleTooltipLongitude}>
                    {t("projTable.tooltipLongitude")}
                  </Tooltip>
                </Label>
                <Input
                  type="text"
                  name="longitude"
                  value={formLongitude}
                  onChange={(e) => {
                    setFormLongitude(e.target.value);
                  }}
                  disabled={loading}
                />
              </Col>
          </Row>
        </FormGroup>

        {toggleMultipleDevices ? (
          <FormGroup>
            <Row>
              <Col sm={1} md={3} lg={6}>
                <Label>Quantidade a serem criados</Label>
                <Input
                  type="number"
                  name="devices"
                  id="devices"
                  value={numberOfDevices}
                  onChange={(e) => {
                    e.preventDefault();
                    setNumberOfDevices(e.target.value);
                  }}
                  required
                />
              </Col>
              <Col
                sm={1}
                md={3}
                lg={6}
                className="d-flex justify-content-center align-items-center"
              >
                <FormText>Máximo de 24 dispositivos</FormText>
              </Col>
            </Row>
          </FormGroup>
        ) : null}
      </ModalBody>

      <ModalFooter>
        {loading ? (
          <Spinner color="primary" className="mr-3" />
        ) : (
          <>
            {formManuf === "myDrivers" && (
              <div className="flex-grow-1">
                <Link to="/addNewDriver">
                  <Button
                    type="button"
                    color="primary"
                    className=""
                    // outline
                  >
                    {t("addNewDriver.myCustomDriversAddBtn")}
                  </Button>
                </Link>
              </div>
            )}
            {/*** BOTÃO DE VOLTAR *
             * (Volta para tela de seleção) ***/}
            <Button
              style={{ alignSelf: "flex-start" }}
              type="button"
              color="primary"
              className="mr-1 mb-1"
              onClick={() => setSelected("")}
              // outline
            >
              {t("Voltar")}
            </Button>
            {/** BOTÃO DE CANCELAR */}
            <Button
              type="button"
              color="primary"
              className="mr-1 mb-1"
              onClick={toggle}
              outline
            >
              {t("projTable.cancel")}
            </Button>
            {/** BOTÃO DE ENVIAR DADOS PARA A API SEM FECHAR O MODAL ***/}
            {/* <Button
                name="btnOpen"
                type="submit"
                color="primary"
              >
                {t('projTable.saveContinue')}
              </Button> */}
            {/** BOTÃO DE ENVIAR DADOS E FECHAR O MODAL */}
            <Button name="btnClose" type="submit" color="primary">
              {t("projTable.saveDevice")}
            </Button>
          </>
        )}
      </ModalFooter>
    </Form>
  );
};

/****** MODAL DE NOVO GATEWAY ******/
const ModalNewGW = ({
  toggle,
  proj,
  selected,
  setSelected,
  setLoadDevices,
}) => {
  const { t } = useTranslation();

  const [manufacturers, setManufacturers] = useState([]); // lista de fabricantes
  const [models, setModels] = useState([]); // lista de modelos

  const [formManuf, setFormManuf] = useState(""); // fabricante selecionado
  const [formModel, setFormModel] = useState(""); // modelo selecionado
  const [formSN, setFormSN] = useState(""); // serial number
  const [formDesc, setFormDesc] = useState(""); // descrição
  const [formInterval, setFormInterval] = useState(""); // tempo de atualização
  const [formLatitude, setFormLatitude] = useState(""); // latitude
  const [formLongitude, setFormLongitude] = useState(""); // longitude
  const [intUnit, setIntUnit] = useState(1); // unidade de tempo de indicação (s, min, h)

  const [tooltipLatitudeOpen, setTooltipLatitudeOpen] = useState(false);
  const [tooltipLongitudeOpen, setTooltipLongitudeOpen] = useState(false);
  const toggleTooltipLatitude = () => setTooltipLatitudeOpen(!tooltipLatitudeOpen);
  const toggleTooltipLongitude = () => setTooltipLongitudeOpen(!tooltipLongitudeOpen);

  const [invalidManuf, setInvalidManuf] = useState(false); // estado de fabricante invalido
  const [invalidModel, setInvalidModel] = useState(false); // estado de modelo invalido
  const [invalidSN, setInvalidSN] = useState(false); // estado de serial number invalido
  const [invalidDesc, setInvalidDesc] = useState(false); // estado de descrição invalido
  const [invalidInterval, setInvalidInterval] = useState(false); // estado de tempo invalido

  const [loading, setLoading] = useState(false); // estado de carregamento envio de dados para api

  // busca lista de fabricantes de gateway
  useEffect(() => {
    axios
      .get("gwManufacturerSearch/")
      .then((res) => {
        setManufacturers(res.data);
      })
      .catch((err) => {
        console.log(err);
        // t() da problema com o useEffect
        toastr.error("Erro!", "Erro ao buscar fabricantes.");
      });
  }, []);

  // busca lista de modelos do fabricante selecionado
  useEffect(() => {
    if (formManuf) {
      axios
        .get("/gwModelSearch", {
          params: {
            name: formManuf,
          },
        })
        .then((res) => setModels(res.data))
        .catch((err) => {
          console.log(err);
          toastr.warning("Erro!", "Erro ao buscar modelos.");
        });
    }
  }, [formManuf]);

  function onSubmitForm(btn) {
    if (!formManuf || !formModel || !formSN || !formDesc || !formInterval) {
      setInvalidManuf(!formManuf);
      setInvalidModel(!formModel);
      setInvalidSN(!formSN);
      setInvalidDesc(!formDesc);
      setInvalidInterval(!formInterval);
      toastr.warning(
        `${t("projTable.att")}!`,
        `${t("projTable.invalidText")}.`
      );
    } else if (formInterval * intUnit < 60) {
      toastr.warning("Erro!", "O número é invalido");
    } else {
      /* Salvar dados do formulário no formato JSON */
      const jsonData = {
        projId: proj.id,
        category: selected,
        manufacturer: formManuf,
        model: formModel,
        serialNumber: formSN,
        description: formDesc,
        commInterval: formInterval * intUnit,
        latitude: formLatitude,
        longitude: formLongitude
      };

      // console.log('novo GW:', jsonData);

      setLoading(true);
      /*** Envio de dados ***/
      axios
        .post("addGateway/", JSON.stringify(jsonData))
        .then((res) => {
          // envia AK e AS para o usuário em arquivo .txt
          const credentialsText =
            "Access key: " +
            res.data.credentials.ak +
            "\nAccess secret: " +
            res.data.credentials.as;
          const credentialsFile = formModel + "_" + formSN + ".txt";

          const blob = new Blob([credentialsText], {
            type: "text/plain;charset=utf-8",
          });
          saveAs(blob, credentialsFile);

          setLoading(false);
          toastr.success(res.data.messageHead, res.data.messageBody);
          setLoadDevices(true); // Atualiza tabela

          /* se dados foram enviados pelo botão "salvar dados"... */
          if (btn === "btnClose") {
            toggle(); // ...fechar o modal
          } else {
            // se foi pelo "salvar e continuar"...
            //     /* ...limpar campos do formulario */
            //     // setFormDesc('');
            //     // setFormHash('');
            //     // setFormIR('');
            //     // setACList([]);

            setSelected(0);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          console.log(err.response);

          if (err.response) {
            /* Notificação de erro de envio */
            toastr.warning(
              err.response.data.messageHead,
              err.response.data.messageBody
            );
          }
        });
    }
  }

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();

        const btn = e.nativeEvent.submitter.name;
        onSubmitForm(btn);
      }}
    >
      <ModalBody className="text-left mx-6">
        <h5 className="text-center">
          {t("projTable.new")} {t("projTable." + selected)}
        </h5>

        <hr />

        {/*** CAMPO DE FABRICANTE ***/}
        <FormGroup>
          <Label>{t("projTable.manufacturer")}</Label>
          <Input
            type="select"
            name="manufacturer"
            id="manufacturer"
            value={formManuf}
            onChange={(e) => {
              setFormManuf(e.target.value);
              setFormModel("");
              setInvalidManuf(false);
              setInvalidModel(false);
            }}
            invalid={invalidManuf}
            disabled={!manufacturers.length || loading}
          >
            <option value="" disabled>
              {t("projTable.select")}
            </option>
            {manufacturers.map((item, i) => (
              <option value={item} key={i}>
                {item}
              </option>
            ))}
            <option value="myDrivers">Meus Drivers</option>
          </Input>
        </FormGroup>

        {/* CAMPO DE MODELO */}
        <FormGroup>
          <Label>{t("projTable.model")}</Label>
          <Input
            type="select"
            name="model"
            id="model"
            // disabled={(!models.length)}
            value={formModel}
            onChange={(e) => {
              setFormModel(e.target.value);
              setInvalidModel(false);
            }}
            invalid={invalidModel}
            disabled={!models.length || loading}
          >
            <option value="" disabled>
              {t("projTable.select")}
            </option>
            {models.map((item, i) => (
              <option value={item.model} key={i}>
                {item.model}
              </option>
            ))}
          </Input>
        </FormGroup>

        {/* CAMPO DE SERIAL NUMBER */}
        <FormGroup>
          <Label>{t("projTable.serialNumber")}</Label>
          <Input
            type="text"
            name="serialNumber"
            id="serialNumber"
            value={formSN}
            onChange={(e) => {
              setFormSN(e.target.value);
              setInvalidSN(false);
            }}
            invalid={invalidSN}
            disabled={loading}
          />
        </FormGroup>

        {/* CAMPO DE DESCRIÇÃO/REFERÊNCIA */}
        <FormGroup>
          <Label>{t("projTable.reference")}</Label>
          <Input
            type="text"
            name="description"
            id="description"
            value={formDesc}
            onChange={(e) => {
              setFormDesc(e.target.value);
              setInvalidDesc(false);
            }}
            invalid={invalidDesc}
            disabled={loading}
          />
        </FormGroup>

        <FormGroup>
          <Label>{t("projTable.commInterval")}</Label>
          <Row>
            <Col md={4}>
              <Input
                type="number"
                name="commInterval"
                id="commInterval"
                value={formInterval}
                onChange={(e) => {
                  setFormInterval(e.target.value);
                  setInvalidInterval(false);
                }}
                invalid={invalidInterval}
                disabled={loading}
              />
              <UncontrolledTooltip placement="right" target="commInterval">
                Mínimo 60 segundos
              </UncontrolledTooltip>
            </Col>

            <Col md={4}>
              <Input
                className="pt-0"
                type="select"
                name="intUnit"
                id="intUnit"
                value={intUnit}
                onChange={(e) => setIntUnit(e.target.value)}
                disabled={loading}
              >
                <option value={1}>{t("projTable.seconds")}</option>
                <option value={60}>{t("projTable.minutes")}</option>
                <option value={3600}>{t("projTable.hours")}</option>
              </Input>
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
        <Row>
            {/* LATITUDE */}
            <Col md={6}>
              <Label>
                {t("projTable.latitude")}
                <FontAwesomeIcon id="latitudeHelpIcon" fixedWidth icon={faQuestionCircle} />
                <Tooltip isOpen={tooltipLatitudeOpen} target="latitudeHelpIcon" toggle={toggleTooltipLatitude}>
                  {t("projTable.tooltipLatitude")}
                </Tooltip>
              </Label>
              <Input
                type="text"
                name="latitude"
                value={formLatitude}
                onChange={(e) => {
                  setFormLatitude(e.target.value);
                }}
                disabled={loading}
              />
            </Col>
            
            {/* LONGITUDE */}
            <Col md={6}>
              <Label>
                {t("projTable.longitude")}
                <FontAwesomeIcon id="longitudeHelpIcon" fixedWidth icon={faQuestionCircle} />
                <Tooltip isOpen={tooltipLongitudeOpen} target="longitudeHelpIcon" toggle={toggleTooltipLongitude}>
                  {t("projTable.tooltipLongitude")}  
                </Tooltip>
              </Label>
              <Input
                type="text"
                name="longitude"
                value={formLongitude}
                onChange={(e) => {
                  setFormLongitude(e.target.value);
                }}
                disabled={loading}
              />
            </Col>
        </Row>
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        {loading ? (
          <Spinner color="primary" className="mr-3" />
        ) : (
          <>
            {formManuf === "myDrivers" && (
              <div className="flex-grow-1">
                <Link to="/addNewDriver">
                  <Button
                    type="button"
                    color="primary"
                    className=""
                    // outline
                  >
                    {t("addNewDriver.myCustomDriversAddBtn")}
                  </Button>
                </Link>
              </div>
            )}
            {/*** BOTÃO DE VOLTAR *
             * (Volta para tela de seleção) ***/}
            <Button
              style={{ alignSelf: "flex-start" }}
              type="button"
              color="primary"
              className="mr-1 mb-1"
              onClick={() => setSelected("")}
              // outline
            >
              {t("Voltar")}
            </Button>
            {/*** BOTÃO DE CANCELAR *
             * (apenas fecha o modal sem enviar nada para a API) ***/}
            <Button
              type="button"
              color="primary"
              className="mr-1 mb-1"
              onClick={toggle}
              outline
            >
              {t("projTable.cancel")}
            </Button>
            {/*** BOTÃO DE ENVIAR DADOS PARA A API SEM FECHAR O MODAL ***/}
            {/* <Button
                name="btnOpen"
                type="submit"
                color="primary"
              >
                {t('projTable.saveContinue')}
              </Button> */}
            {/*** BOTÃO DE ENVIAR DADOS E FECHAR O MODAL ***/}
            <Button name="btnClose" type="submit" color="primary">
              {t("projTable.saveDevice")}
            </Button>
          </>
        )}
      </ModalFooter>
    </Form>
  );
};

/****** MODAL DE NOVO STANDALONE ******/
const ModalNewSD = ({
  toggle,
  proj,
  selected,
  setSelected,
  setLoadDevices,
}) => {
  const { t } = useTranslation();

  const [manufacturers, setManufacturers] = useState([]); // lista de fabricantes
  const [models, setModels] = useState([]); // lista de modelos
  const [acList, setACList] = useState([]); // lista de aparelhos de a/c cadastrados

  const [formManuf, setFormManuf] = useState(""); // fabricante selecionado
  const [formModel, setFormModel] = useState(""); // modelo selecionado
  const [formIR, setFormIR] = useState(""); // hash de IR
  const [formHash, setFormHash] = useState(""); // hash de dispositivo
  const [formDesc, setFormDesc] = useState(""); // descrição
  const [formInterval, setFormInterval] = useState(""); // tempo de atualização
  const [formLongitude, setFormLongitude] = useState(""); // longitude
  const [formLatitude, setFormLatitude] = useState(""); // latitude

  const [tooltipLatitudeOpen, setTooltipLatitudeOpen] = useState(false);
  const [tooltipLongitudeOpen, setTooltipLongitudeOpen] = useState(false);
  const toggleTooltipLatitude = () => setTooltipLatitudeOpen(!tooltipLatitudeOpen);
  const toggleTooltipLongitude = () => setTooltipLongitudeOpen(!tooltipLongitudeOpen);

  const [intUnit, setIntUnit] = useState(1); // unidade de tempo de indicação (s, min, h)
  const [climate, setClimate] = useState(false); // indicador de dispositivo de climatização
  const [loadClimate, setLoadClimate] = useState(false); // estado de carregamento lista de aparelhos de a/c cadastrados

  const [invalidManuf, setInvalidManuf] = useState(false); // estado de fabricante invalido
  const [invalidModel, setInvalidModel] = useState(false); // estado de modelo invalido
  const [invalidIR, setInvalidIR] = useState(false); // estado de hash de IR invalido
  const [invalidDesc, setInvalidDesc] = useState(false); // estado de descrição invalido
  const [invalidInterval, setInvalidInterval] = useState(false); // estado de tempo invalido

  const [loading, setLoading] = useState(false); // estado de carregamento envio de dados para api

  // busca lista de fabricantes de standalone
  useEffect(() => {
    axios
      .get("sdManufacturerSearch/")
      .then((res) => {
        setManufacturers(res.data);
      })
      .catch((err) => {
        console.log(err);
        // t() da problema com o useEffect
        toastr.error("Erro!", "Erro ao buscar fabricantes.");
      });
  }, []);

  // busca lista de modelos do fabricante selecionado
  useEffect(() => {
    if (formManuf) {
      axios
        .get("/sdModelSearch", {
          params: {
            name: formManuf,
          },
        })
        .then((res) => setModels(res.data))
        .catch((err) => {
          console.log(err);
          toastr.warning("Erro!", "Erro ao buscar modelos.");
        });
    }
  }, [formManuf]);

  // busca lista de dispositivos de ar condicionado
  useEffect(() => {
    if (loadClimate) {
      axios
        .get("/readACList", {
          params: {
            infraredId: formIR,
          },
        })
        .then((res) => {
          setACList(res.data.deviceList);
          setLoadClimate(false);
        })
        .catch((err) => {
          console.log(err);
          // t() da problema com o useEffect
          toastr.warning("Erro!", "Erro ao buscar dispositivos.");
          setInvalidIR(true);
          setLoadClimate(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadClimate]);

  function onSubmitForm(btn) {
    if (
      !formManuf ||
      !formModel ||
      !formDesc ||
      (climate && !formIR) ||
      (!climate && !formInterval)
    ) {
      setInvalidManuf(!formManuf);
      setInvalidModel(!formModel);
      setInvalidDesc(!formDesc);
      setInvalidIR(climate && !formIR);
      setInvalidInterval(!climate && !formInterval);
      toastr.warning(
        `${t("projTable.att")}!`,
        `${t("projTable.invalidText")}.`
      );
    } else if (formInterval * intUnit < 60) {
      toastr.warning("Erro!", "O número é invalido");
    } else {
      /* Salvar dados do formulário no formato JSON */
      const jsonData = {
        projId: proj.id,
        category: selected,
        manufacturer: formManuf,
        model: formModel,
        description: formDesc,
        deviceHash: formHash,
        infraredId: formIR,
        commInterval: formInterval * intUnit,
        latitude: formLatitude,
        longitude: formLongitude,
        climate: climate,
      };

      // console.log('novo SD:', jsonData);

      setLoading(true);
      /*** Envio de dados ***/
      axios
        .post("addStandalone/", JSON.stringify(jsonData))
        .then((res) => {
          // envia AK e AS para o usuário em arquivo .txt
          const credentialsText =
            "Access key: " +
            res.data.credentials.ak +
            "\nAccess secret: " +
            res.data.credentials.as;
          const credentialsFile = formModel + ".txt";

          const blob = new Blob([credentialsText], {
            type: "text/plain;charset=utf-8",
          });
          saveAs(blob, credentialsFile);

          setLoading(false);
          toastr.success(res.data.messageHead, res.data.messageBody);
          setLoadDevices(true); // Atualiza tabela

          /* se dados foram enviados pelo botão "salvar dados"... */
          if (btn === "btnClose") {
            toggle(); // ...fechar o modal
          } else {
            // se foi pelo "salvar e continuar"...
            //     /* ...limpar campos do formulario */
            //     // setFormDesc('');
            //     // setFormHash('');
            //     // setFormIR('');
            //     // setACList([]);

            setSelected(0);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          console.log(err.response);

          if (err.response) {
            /* Notificação de erro de envio */
            toastr.warning(
              err.response.data.messageHead,
              err.response.data.messageBody
            );
          }
        });
    }
  }

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();

        const btn = e.nativeEvent.submitter.name;
        onSubmitForm(btn);
      }}
    >
      <ModalBody className="text-left mx-6">
        <h5 className="text-center">
          {t("projTable.new")} {t("projTable." + selected)}
        </h5>

        <hr />

        {/*** CAMPO DE FABRICANTE ***/}
        <FormGroup>
          <Label>{t("projTable.manufacturer")}</Label>
          <Input
            type="select"
            name="manufacturer"
            id="manufacturer"
            value={formManuf}
            onChange={(e) => {
              setFormManuf(e.target.value);
              setFormModel("");
              setClimate(false);
              setInvalidManuf(false);
              setInvalidModel(false);
              setInvalidDesc(false);
              setInvalidIR(false);
              setInvalidInterval(false);
            }}
            invalid={invalidManuf}
            disabled={!manufacturers.length || loading}
          >
            <option value="" disabled>
              {t("projTable.select")}
            </option>
            {manufacturers.map((item, i) => (
              <option value={item} key={i}>
                {item}
              </option>
            ))}
            <option value="myDrivers">Meus Drivers</option>
          </Input>
        </FormGroup>

        {/* CAMPO DE MODELO */}
        <FormGroup>
          <Label>{t("projTable.model")}</Label>
          <Input
            type="select"
            name="model"
            id="model"
            value={formModel}
            onChange={(e) => {
              setFormModel(e.target.value);
              setInvalidModel(false);
              setInvalidDesc(false);
              setInvalidIR(false);
              setInvalidInterval(false);

              if (
                models
                  .find((item) => item.model === e.target.value)
                  .types.includes("C")
              ) {
                setClimate(true);
                setFormInterval(60);
                setIntUnit(60);
              } else {
                setClimate(false);
              }
            }}
            // disabled={(!models.length)}
            invalid={invalidModel}
            disabled={!models.length || loading}
          >
            <option value="" disabled>
              {t("projTable.select")}
            </option>
            {models.map((item, i) => (
              <option value={item.model} key={i}>
                {item.model}
              </option>
            ))}
          </Input>
        </FormGroup>

        {/* CAMPOS DE IR HASH */}
        {climate ? (
          <FormGroup>
            <Label>{t("projTable.hashIR")}</Label>
            <InputGroup className="mb-3" size="sm">
              <Input
                type="text"
                name="irHash"
                id="irHash"
                value={formIR}
                // disabled={(loadClimate)}
                onChange={(e) => {
                  setFormIR(e.target.value);
                  setFormDesc("");
                  setInvalidIR(false);
                  setACList([]);
                }}
                invalid={invalidIR}
                disabled={loadClimate || loading}
              />
              <InputGroupAddon addonType="append">
                <Button
                  color="primary"
                  disabled={loadClimate}
                  onClick={() => {
                    if (formIR === "") {
                      setInvalidIR(true);
                      toastr.warning(
                        `${t("projTable.att")}!`,
                        `${t("projTable.insertIRHash")}.`
                      );
                    } else {
                      setLoadClimate(true);
                      setInvalidDesc(false);
                    }
                  }}
                >
                  {loadClimate ? (
                    <div className="text-center">
                      <Spinner color="white" size="sm" />
                    </div>
                  ) : (
                    <FontAwesomeIcon fixedWidth icon={faSearch} />
                  )}
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
        ) : null}

        {/* CAMPO DE DESCRIÇÃO */}
        {climate ? (
          <FormGroup>
            <Label>{t("projTable.reference")}</Label>
            <Input
              type="select"
              name="description"
              id="description"
              value={formDesc}
              onChange={(e) => {
                setFormDesc(e.target.value);
                setFormHash(
                  acList.find((item) => item.deviceName === e.target.value)
                    .deviceHash
                );
                setInvalidDesc(false);
              }}
              // disabled={(!acList.length || loadClimate)}
              invalid={invalidDesc}
              disabled={!acList.length || loadClimate || loading}
            >
              <option value="" disabled>
                {t("projTable.select")}
              </option>
              {acList.length
                ? acList.map((item, i) => (
                    <option value={item.deviceName} key={i}>
                      {item.deviceName}
                    </option>
                  ))
                : null}
            </Input>
          </FormGroup>
        ) : (
          <FormGroup>
            <Label>{t("projTable.reference")}</Label>
            <Input
              type="text"
              name="description"
              id="description"
              value={formDesc}
              onChange={(e) => {
                setFormDesc(e.target.value);
                setInvalidDesc(false);
              }}
              invalid={invalidDesc}
              disabled={loading}
            />
          </FormGroup>
        )}

        {/*** CAMPO DE TEMPO DE ATUALIZAÇÃO ***/}
        {!climate ? (
          <FormGroup>
            <Label>{t("projTable.commInterval")}</Label>
            <Row>
              <Col md={4}>
                <Input
                  type="number"
                  name="commInterval"
                  id="commInterval"
                  value={formInterval}
                  onChange={(e) => {
                    setFormInterval(e.target.value);
                    setInvalidInterval(false);
                  }}
                  invalid={invalidInterval}
                  disabled={loading}
                />
              </Col>

              <Col md={4}>
                <Input
                  className="pt-0"
                  type="select"
                  name="intUnit"
                  id="intUnit"
                  value={intUnit}
                  onChange={(e) => setIntUnit(e.target.value)}
                  disabled={loading}
                >
                  <option value={1}>{t("projTable.seconds")}</option>
                  <option value={60}>{t("projTable.minutes")}</option>
                  <option value={3600}>{t("projTable.hours")}</option>
                </Input>
              </Col>
            </Row>
          </FormGroup>
        ) : null}

        <FormGroup>
          <Row>
              {/* LATITUDE */}
              <Col md={6}>
                <Label>
                  {t("projTable.latitude")}
                  <FontAwesomeIcon id="latitudeHelpIcon" fixedWidth icon={faQuestionCircle} />
                  <Tooltip isOpen={tooltipLatitudeOpen} target="latitudeHelpIcon" toggle={toggleTooltipLatitude}>
                    {t("projTable.tooltipLatitude")}
                  </Tooltip>
                </Label>
                <Input
                  type="text"
                  name="latitude"
                  value={formLatitude}
                  onChange={(e) => {
                    setFormLatitude(e.target.value);
                  }}
                  disabled={loading}
                />
              </Col>
              
              {/* LONGITUDE */}
              <Col md={6}>
                <Label>
                  {t("projTable.longitude")}
                  <FontAwesomeIcon id="longitudeHelpIcon" fixedWidth icon={faQuestionCircle} />
                  <Tooltip isOpen={tooltipLongitudeOpen} target="longitudeHelpIcon" toggle={toggleTooltipLongitude}>
                    {t("projTable.tooltipLongitude")}
                  </Tooltip>
                </Label>
                <Input
                  type="text"
                  name="longitude"
                  value={formLongitude}
                  onChange={(e) => {
                    setFormLongitude(e.target.value);
                  }}
                  disabled={loading}
                />
              </Col>
          </Row>
        </FormGroup>  
      </ModalBody>

      <ModalFooter>
        {loading ? (
          <Spinner color="primary" className="mr-3" />
        ) : (
          <>
            {formManuf === "myDrivers" && (
              <div className="flex-grow-1">
                <Link to="/addNewDriver">
                  <Button
                    type="button"
                    color="primary"
                    className=""
                    // outline
                  >
                    {t("addNewDriver.myCustomDriversAddBtn")}
                  </Button>
                </Link>
              </div>
            )}
            {/*** BOTÃO DE VOLTAR *
             * (Volta para tela de seleção) ***/}
            <Button
              style={{ alignSelf: "flex-start" }}
              type="button"
              color="primary"
              className="mr-1 mb-1"
              onClick={() => setSelected("")}
              // outline
            >
              {t("Voltar")}
            </Button>

            {/*** BOTÃO DE CANCELAR *
             * (apenas fecha o modal sem enviar nada para a API) ***/}
            <Button
              type="button"
              color="primary"
              className="mr-1 mb-1"
              onClick={toggle}
              outline
            >
              {t("projTable.cancel")}
            </Button>

            {/*** BOTÃO DE ENVIAR DADOS PARA A API SEM FECHAR O MODAL ***/}
            {/* <Button
                name="btnOpen"
                type="submit"
                color="primary"
              >
                {t('projTable.saveContinue')}
              </Button> */}
            {/*** BOTÃO DE ENVIAR DADOS E FECHAR O MODAL ***/}
            <Button name="btnClose" type="submit" color="primary">
              {t("projTable.saveDevice")}
            </Button>
          </>
        )}
      </ModalFooter>
    </Form>
  );
};

/****** MODAL DE NOVO LORAWAN ******/
const ModalNewLW = ({
  toggle,
  proj,
  selected,
  setSelected,
  setLoadDevices,
}) => {
  const { t } = useTranslation();

  const [hash, setHash] = useState();
  const [networkHash, setNetworkHash] = useState();
  const [deviceAddress, setDeviceAddress] = useState();

  const [manufacturers, setManufacturers] = useState([]); // lista de fabricantes
  const [models, setModels] = useState([]); // lista de modelos
  const [acList, setACList] = useState([]); // lista de aparelhos de a/c cadastrados

  const [formManuf, setFormManuf] = useState(""); // fabricante selecionado
  const [formModel, setFormModel] = useState(""); // modelo selecionado
  const [formIR, setFormIR] = useState(""); // hash de IR
  const [formHash, setFormHash] = useState(""); // hash de dispositivo
  const [formDesc, setFormDesc] = useState(""); // descrição
  const [formInterval, setFormInterval] = useState(""); // tempo de atualização
  const [formLatitude, setFormLatitude] = useState(""); // latitude
  const [formLongitude, setFormLongitude] = useState(""); // longitude

  const [tooltipLatitudeOpen, setTooltipLatitudeOpen] = useState(false);
  const [tooltipLongitudeOpen, setTooltipLongitudeOpen] = useState(false);
  const toggleTooltipLatitude = () => setTooltipLatitudeOpen(!tooltipLatitudeOpen);
  const toggleTooltipLongitude = () => setTooltipLongitudeOpen(!tooltipLongitudeOpen);

  const [intUnit, setIntUnit] = useState(1); // unidade de tempo de indicação (s, min, h)
  const [climate, setClimate] = useState(false); // indicador de dispositivo de climatização
  const [loadClimate, setLoadClimate] = useState(false); // estado de carregamento lista de aparelhos de a/c cadastrados

  const [invalidManuf, setInvalidManuf] = useState(false); // estado de fabricante invalido
  const [invalidModel, setInvalidModel] = useState(false); // estado de modelo invalido
  const [invalidIR, setInvalidIR] = useState(false); // estado de hash de IR invalido
  const [invalidDesc, setInvalidDesc] = useState(false); // estado de descrição invalido
  const [invalidInterval, setInvalidInterval] = useState(false); // estado de tempo invalido

  const [loading, setLoading] = useState(false); // estado de carregamento envio de dados para api
  const [toggleModalAddInBatches, setToggleModalAddInBatches] = useState(false);

  // busca lista de fabricantes de standalone
  useEffect(() => {
    axios
      .get("lwManufacturerSearch/")
      .then((res) => {
        setManufacturers(res.data);
      })
      .catch((err) => {
        console.log(err);
        // t() da problema com o useEffect
        toastr.error("Erro!", "Erro ao buscar fabricantes.");
      });
  }, []);

  // busca lista de modelos do fabricante selecionado
  useEffect(() => {
    if (formManuf) {
      axios
        .get("/lwModelSearch", {
          params: {
            name: formManuf,
          },
        })
        .then((res) => setModels(res.data))
        .catch((err) => {
          console.log(err);
          toastr.warning("Erro!", "Erro ao buscar modelos.");
        });
    }
  }, [formManuf]);

  function onSubmitForm(btn) {
    if (
      !formManuf ||
      !formModel ||
      !formDesc ||
      (climate && !formIR) ||
      (!climate && !formInterval) ||
      !hash ||
      !networkHash ||
      !deviceAddress
    ) {
      setInvalidManuf(!formManuf);
      setInvalidModel(!formModel);
      setInvalidDesc(!formDesc);
      setInvalidIR(climate && !formIR);
      setInvalidInterval(!climate && !formInterval);
      toastr.warning(
        `${t("projTable.att")}!`,
        `${t("projTable.invalidText")}.`
      );
    } else if (formInterval * intUnit < 60) {
      toastr.warning("Erro!", "O número é invalido");
    } else {
      /* Salvar dados do formulário no formato JSON */
      const jsonData = {
        projId: proj.id,
        category: selected,
        manufacturer: formManuf,
        model: formModel,
        description: formDesc,
        deviceHash: formHash,
        infraredId: formIR,
        commInterval: formInterval * intUnit,
        latitude: formLatitude,
        longitude: formLongitude,
        hash: hash,
        networkHash: networkHash,
        deviceAddress: deviceAddress,
        // climate: climate
      };

      // console.log('novo SD:', jsonData);

      setLoading(true);
      /*** Envio de dados ***/
      axios
        .post("addLoraDevice/", JSON.stringify(jsonData))
        .then((res) => {
          // envia AK e AS para o usuário em arquivo .txt

          setLoading(false);
          toastr.success(res.data.messageHead, res.data.messageBody);
          setLoadDevices(true); // Atualiza tabela

          /* se dados foram enviados pelo botão "salvar dados"... */
          if (btn === "btnClose") {
            toggle(); // ...fechar o modal
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          console.log(err.response);

          if (err.response) {
            /* Notificação de erro de envio */
            toastr.warning(
              err.response.data.messageHead,
              err.response.data.messageBody
            );
          }
        });
    }
  }

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();

        const btn = e.nativeEvent.submitter.name;
        onSubmitForm(btn);
      }}
    >
      <ModalBody className="text-left mx-6">
        <h5 className="text-center">
          {t("projTable.new")} {t("projTable." + selected)}
        </h5>

        <hr />

        {/*** CAMPO DE FABRICANTE ***/}
        <FormGroup>
          <Label>{t("projTable.manufacturer")}</Label>
          <Input
            type="select"
            name="manufacturer"
            id="manufacturer"
            value={formManuf}
            onChange={(e) => {
              setFormManuf(e.target.value);
              setFormModel("");
              setClimate(false);
              setInvalidManuf(false);
              setInvalidModel(false);
              setInvalidDesc(false);
              setInvalidIR(false);
              setInvalidInterval(false);
            }}
            invalid={invalidManuf}
            disabled={!manufacturers.length || loading}
          >
            <option value="" disabled>
              {t("projTable.select")}
            </option>
            {manufacturers.map((item, i) => (
              <option value={item} key={i}>
                {item}
              </option>
            ))}
            <option value="myDrivers">Meus Drivers</option>
          </Input>
        </FormGroup>

        {/* CAMPO DE MODELO */}
        <FormGroup>
          <Label>{t("projTable.model")}</Label>
          <Input
            type="select"
            name="model"
            id="model"
            value={formModel}
            onChange={(e) => {
              setFormModel(e.target.value);
              setInvalidModel(false);
              setInvalidDesc(false);
              setInvalidIR(false);
              setInvalidInterval(false);

              if (
                models
                  .find((item) => item.model === e.target.value)
                  .types.includes("C")
              ) {
                setClimate(true);
                setFormInterval(60);
                setIntUnit(60);
              } else {
                setClimate(false);
              }
            }}
            // disabled={(!models.length)}
            invalid={invalidModel}
            disabled={!models.length || loading}
          >
            <option value="" disabled>
              {t("projTable.select")}
            </option>
            {models.map((item, i) => (
              <option value={item.model} key={i}>
                {item.model}
              </option>
            ))}
          </Input>
        </FormGroup>

        {/* CAMPOS DE IR HASH */}
        {climate ? (
          <FormGroup>
            <Label>{t("projTable.hashIR")}</Label>
            <InputGroup className="mb-3" size="sm">
              <Input
                type="text"
                name="irHash"
                id="irHash"
                value={formIR}
                // disabled={(loadClimate)}
                onChange={(e) => {
                  setFormIR(e.target.value);
                  setFormDesc("");
                  setInvalidIR(false);
                  setACList([]);
                }}
                invalid={invalidIR}
                disabled={loadClimate || loading}
              />
              <InputGroupAddon addonType="append">
                <Button
                  color="primary"
                  disabled={loadClimate}
                  onClick={() => {
                    if (formIR === "") {
                      setInvalidIR(true);
                      toastr.warning(
                        `${t("projTable.att")}!`,
                        `${t("projTable.insertIRHash")}.`
                      );
                    } else {
                      setLoadClimate(true);
                      setInvalidDesc(false);
                    }
                  }}
                >
                  {loadClimate ? (
                    <div className="text-center">
                      <Spinner color="white" size="sm" />
                    </div>
                  ) : (
                    <FontAwesomeIcon fixedWidth icon={faSearch} />
                  )}
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
        ) : null}

        {/* CAMPO DE DESCRIÇÃO */}
        {climate ? (
          <FormGroup>
            <Label>{t("projTable.reference")}</Label>
            <Input
              type="select"
              name="description"
              id="description"
              value={formDesc}
              onChange={(e) => {
                setFormDesc(e.target.value);
                setFormHash(
                  acList.find((item) => item.deviceName === e.target.value)
                    .deviceHash
                );
                setInvalidDesc(false);
              }}
              // disabled={(!acList.length || loadClimate)}
              invalid={invalidDesc}
              disabled={!acList.length || loadClimate || loading}
            >
              <option value="" disabled>
                {t("projTable.select")}
              </option>
              {acList.length
                ? acList.map((item, i) => (
                    <option value={item.deviceName} key={i}>
                      {item.deviceName}
                    </option>
                  ))
                : null}
            </Input>
          </FormGroup>
        ) : (
          <FormGroup>
            <Label>{t("projTable.reference")}</Label>
            <Input
              type="text"
              name="description"
              id="description"
              value={formDesc}
              onChange={(e) => {
                setFormDesc(e.target.value);
                setInvalidDesc(false);
              }}
              invalid={invalidDesc}
              disabled={loading}
            />
          </FormGroup>
        )}

        <FormGroup>
          <Label>Hash</Label>
          <Input
            type="text"
            name="hash"
            id="hash"
            value={hash}
            onChange={(e) => setHash(e.target.value)}
          />
        </FormGroup>

        {/* NETWORK DEVICE */}
        <FormGroup>
          <Label>Network Hash</Label>
          <Input
            type="text"
            name="networdHash"
            id="networdHash"
            value={networkHash}
            onChange={(e) => setNetworkHash(e.target.value)}
          />
        </FormGroup>

        {/* DEVICE ADDRESS */}
        <FormGroup>
          <Label>Device Address</Label>
          <Input
            type="text"
            name="deviceAddress"
            id="deviceAddress"
            value={deviceAddress}
            onChange={(e) => setDeviceAddress(e.target.value)}
          />
        </FormGroup>

        {/*** CAMPO DE TEMPO DE ATUALIZAÇÃO ***/}
        {!climate ? (
          <FormGroup>
            <Label>{t("projTable.commInterval")}</Label>
            <Row>
              <Col md={4}>
                <Input
                  type="number"
                  name="commInterval"
                  id="commInterval"
                  value={formInterval}
                  onChange={(e) => {
                    setFormInterval(e.target.value);
                    setInvalidInterval(false);
                  }}
                  invalid={invalidInterval}
                  disabled={loading}
                />
              </Col>

              <Col md={4}>
                <Input
                  className="pt-0"
                  type="select"
                  name="intUnit"
                  id="intUnit"
                  value={intUnit}
                  onChange={(e) => setIntUnit(e.target.value)}
                  disabled={loading}
                >
                  <option value={1}>{t("projTable.seconds")}</option>
                  <option value={60}>{t("projTable.minutes")}</option>
                  <option value={3600}>{t("projTable.hours")}</option>
                </Input>
              </Col>
              {/* ICONE PARA ADICIONAR MAIS DE UM DISPOSITIVO */}
              {/* <Col md={4} className="text-right">
                <Button
                  type="button"
                  id="TooltipForPlusButton"
                  color='primary'
                  size="sm"
                  onClick={() => { setToggleMultipleDevices(!toggleMultipleDevices) }
                  }
                ><FontAwesomeIcon fixedWidth icon={faPlus} /></Button>
                <Tooltip
                  isOpen={tooltipOpen}
                  placement="top"
                  target="TooltipForPlusButton"
                  toggle={() => { setTooltipOpen(!tooltipOpen) }}>
                  Adicionar mais dispositivos
                </Tooltip>
              </Col> */}
            </Row>
          </FormGroup>
        ) : null}

        <FormGroup>
          <Row>
              {/* LATITUDE */}
              <Col md={6}>
                <Label>
                  {t("projTable.latitude")}
                  <FontAwesomeIcon id="latitudeHelpIcon" fixedWidth icon={faQuestionCircle} />
                  <Tooltip isOpen={tooltipLatitudeOpen} target="latitudeHelpIcon" toggle={toggleTooltipLatitude}>
                    {t("projTable.tooltipLatitude")}
                  </Tooltip>
                </Label>
                <Input
                  type="text"
                  name="latitude"
                  value={formLatitude}
                  onChange={(e) => {
                    setFormLatitude(e.target.value);
                  }}
                  disabled={loading}
                />
              </Col>
              
              {/* LONGITUDE */}
              <Col md={6}>
                <Label>
                  {t("projTable.longitude")}
                  <FontAwesomeIcon id="longitudeHelpIcon" fixedWidth icon={faQuestionCircle} />
                  <Tooltip isOpen={tooltipLongitudeOpen} target="longitudeHelpIcon" toggle={toggleTooltipLongitude}>
                    {t("projTable.tooltipLongitude")}
                  </Tooltip>
                </Label>
                <Input
                  type="text"
                  name="longitude"
                  value={formLongitude}
                  onChange={(e) => {
                    setFormLongitude(e.target.value);
                  }}
                  disabled={loading}
                />
              </Col>
          </Row>
        </FormGroup>  

        {/* {(toggleMultipleDevices) ?
          <FormGroup>
            <Row>
              <Col sm={1} md={3} lg={6}>
                <Label>Quantidade a serem criados</Label>
                <Input
                  type="number"
                  name="devices"
                  id="devices"
                  value={numberOfDevices}
                  onChange={(e) => {
                    e.preventDefault()
                    setNumberOfDevices(e.target.value)
                  }}
                  required
                />
              </Col>
              <Col sm={1} md={3} lg={6} className='d-flex justify-content-center align-items-center'>
                <FormText>Máximo de 24 dispositivos</FormText>
              </Col>
            </Row>
          </FormGroup>
          : null} */}
      </ModalBody>

      <ModalFooter className="d-flex">
        {loading ? (
          <Spinner color="primary" className="mr-3" />
        ) : (
          <>
            {formManuf === "myDrivers" && (
              <div className="flex-grow-1">
                <Link to="/addNewDriver">
                  <Button
                    type="button"
                    color="primary"
                    className=""
                    // outline
                  >
                    {t("addNewDriver.myCustomDriversAddBtn")}
                  </Button>
                </Link>
              </div>
            )}

            <Button
              style={{ alignSelf: "flex-start" }}
              type="button"
              color="primary"
              className="mr-1 mb-1"
              onClick={() => setToggleModalAddInBatches(true)}
              // outline
            >
              {t("projTable.addInBatches")}
            </Button>

            {/*** BOTÃO DE VOLTAR *
             * (Volta para tela de seleção) ***/}
            <Button
              style={{ alignSelf: "flex-start" }}
              type="button"
              color="primary"
              className="mr-1 mb-1"
              onClick={() => setSelected("")}
              // outline
            >
              {t("Voltar")}
            </Button>
            {/*** BOTÃO DE CANCELAR *
             * (apenas fecha o modal sem enviar nada para a API) ***/}
            <Button
              type="button"
              color="primary"
              className="mr-1 mb-1"
              onClick={toggle}
              outline
            >
              {t("projTable.cancel")}
            </Button>
            {/*** BOTÃO DE ENVIAR DADOS PARA A API SEM FECHAR O MODAL ***/}
            {/* <Button
              name="btnOpen"
              type="submit"
              color="primary"
            >
              {t('projTable.saveContinue')}
            </Button> */}
            {/*** BOTÃO DE ENVIAR DADOS E FECHAR O MODAL ***/}
            <Button name="btnClose" type="submit" color="primary">
              {t("projTable.saveDevice")}
            </Button>
          </>
        )}
        {toggleModalAddInBatches && (
          <ModalAddInBatches
            closeModal={setToggleModalAddInBatches}
            closeAllModals={toggle}
            isOpen={toggleModalAddInBatches}
            projectId={proj.id}
            reloadData={setLoadDevices}
          />
        )}
      </ModalFooter>
    </Form>
  );
};

/****** MODAL DE REGISTRO DE DISPOSITIVO ******/
const ModalNewDevice = ({ open, toggle, proj, setLoadDevices, deviceList }) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState("");
  const categories = ["gw", "sd", "pp", "lw"];

  // const enabledPP = deviceList.some(item => item.category === 'gw');
  const enabledPP = true;

  return (
    <Modal size={!selected ? "lg" : "md"} isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {t("projTable.addDeviceTitle")} {proj.name}
      </ModalHeader>

      {!selected ? ( // Inicial
        <>
          <ModalBody>
            {/* <div className='d-flex justify-content-around flex-sm-column flex-md-row'> */}
            <Row xs={1} md={categories.length} className="text-center">
              {categories.map((cat) => (
                <Col lg={6} key={cat}>
                  <Card
                    role="button"
                    onClick={() => setSelected(cat)}
                    style={{ height: "180px" }}
                    className="card-img-hover-translateY"
                  >
                    <CardHeader role="button">
                      {/* <Label role="button"> */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "1rem",
                          alignItems: "center",
                        }}
                      >
                        <Cpu width={16} height={16} />
                        <Button
                          style={{ width: "50%", alignSelf: "center" }}
                          className="m-1"
                          color="primary"
                          size="lg"
                          // onClick={() => setSelected(cat)}
                          disabled={cat === "pp" && !enabledPP}
                        >
                          {t("projTable." + cat)}
                        </Button>
                      </div>
                      {/* </Label> */}
                    </CardHeader>
                    <CardBody style={{ marginInline: 3 }} role="button">
                      <Label className="text-justify" role="button">
                        {cat === "gw"
                          ? "Um gateway é um dispositivo que atua como ponto de entrada e saída para uma rede. Ele é responsável por rotear dados entre diferentes redes, permitindo a comunicação entre dispositivos em redes diferentes."
                          : cat === "pp"
                          ? "Os periféricos só publicam o estado e recebem comandos através de um gateway. São dispositivos que não podem se conectar diretamente à internet."
                          : cat === "lw"
                          ? "LoRaWAN (Long Range Wide Area Network) é um protocolo de rede de comunicação sem fio de longo alcance, projetado para conectar dispositivos de IoT (Internet das Coisas) em áreas amplas, com baixo consumo de energia e baixo custo."
                          : "Um dispositivo standalone é um dispositivo eletrônico que é capaz de funcionar de forma independente, sem a necessidade de estar conectado a um computador ou a outro dispositivo principal para realizar suas funções. "}
                      </Label>
                    </CardBody>
                    {/* <Button
                        style={{ width: "50%", alignSelf: "center" }}
                        className="m-1 mb-3"
                        color="primary"
                        size="md"
                        // onClick={() => setSelected(cat)}
                        disabled={cat === 'pp' && !enabledPP}

                      >
                        Adicionar
                      </Button> */}
                  </Card>
                </Col>
              ))}
              {/* {categories.map(cat => (
                  <Col key={cat}>
                  <Button

                    className="m-1"
                    color="primary"
                    size="lg"
                    onClick={()=>setSelected(cat)}
                    disabled={cat === 'pp' && !enabledPP}

                  >
                    {t('projTable.'+cat)}
                  </Button>
                  </Col>
                ))} */}
              {/* <Col key={'gw'}>
                  <Button 
                    className="m-1"
                    color="primary" 
                    size="lg"
                    onClick={()=>setSelected('gw')}
                  >
                    {categories['gw']}
                  </Button>
                </Col>

                <Col key={'sd'}>
                  <Button 
                    className="m-1"
                    color="primary" 
                    size="lg"
                    onClick={()=>setSelected('sd')}
                  >
                    {categories['sd']}
                  </Button>
                </Col>

                <Col key={'pp'} >
                  <Button 
                    className="m-1"
                    color="primary" 
                    size="lg"
                    onClick={()=>setSelected('pp')}
                    disabled={!enabledPP}
                  >
                    {categories['pp']}
                  </Button>
                </Col> */}
            </Row>

            {!enabledPP ? (
              <p className="text-center mt-3 mb-0" size="sm">
                {t("projTable.disabledPPText")}.
              </p>
            ) : null}

            {/* </div> */}
          </ModalBody>

          <ModalFooter>
            {/*** BOTÃO DE CANCELAR *
             * (apenas fecha o modal sem enviar nada para a API) ***/}
            <Button
              type="button"
              color="primary"
              className="mr-1 mb-1"
              onClick={toggle}
              outline
            >
              {t("projTable.cancel")}
            </Button>
          </ModalFooter>
        </>
      ) : selected === "pp" ? (
        <ModalNewPP
          toggle={toggle}
          proj={proj}
          selected={selected}
          setSelected={setSelected}
          setLoadDevices={setLoadDevices}
          deviceList={deviceList}
        />
      ) : selected === "gw" ? (
        <ModalNewGW
          toggle={toggle}
          proj={proj}
          selected={selected}
          setSelected={setSelected}
          setLoadDevices={setLoadDevices}
        />
      ) : selected === "lw" ? (
        <ModalNewLW
          toggle={toggle}
          proj={proj}
          selected={selected}
          setSelected={setSelected}
          setLoadDevices={setLoadDevices}
        />
      ) : (
        <ModalNewSD
          toggle={toggle}
          proj={proj}
          selected={selected}
          setSelected={setSelected}
          setLoadDevices={setLoadDevices}
        />
      )}
    </Modal>
  );
};

export { ModalNewProject, ModalAddUser, ModalNewDevice };
