import axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import {
  Button,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Spinner,
} from "reactstrap";

import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { useQuery } from "@tanstack/react-query";
import { DatePicker, Modal } from "antd";

import moment from "moment";
import { queryClient } from "../../../../../App";
import { COLOR_SOLAR } from "../../../../../utils/Constants";
import { formatEnergy } from "../../../../../utils/functions";
import useChartHeight from "../hook";

const { RangePicker, MonthPicker, WeekPicker } = DatePicker;

const ModalCompare = ({ isOpen, handleClose, handleSearch }) => {
  const { t } = useTranslation();
  const [selectOption, setSelectOption] = useState("month");
  const [firstDate, setFirstDate] = useState(null);
  const [secondDate, setSecondDate] = useState(null);
  const options = ["day", "week", "month"];

  const handleOk = () => {
    handleSearch({ firstDate, secondDate, selectOption }); // Chame a função handleSearch com as datas selecionadas
    handleClose(); // Feche o modal após a busca
  };

  const renderDatePickers = () => {
    const PickerComponent =
      selectOption === "month"
        ? MonthPicker
        : selectOption === "week"
        ? WeekPicker
        : DatePicker;

    return (
      <>
        <PickerComponent
          onChange={setFirstDate}
          getPopupContainer={(trigger) => trigger.parentNode}
          format={
            selectOption === "month"
              ? "MM/YYYY"
              : selectOption === "day"
              ? "DD/MM/YYYY"
              : undefined
          }
        />
        <PickerComponent
          onChange={setSecondDate}
          getPopupContainer={(trigger) => trigger.parentNode}
          format={
            selectOption === "month"
              ? "MM/YYYY"
              : selectOption === "day"
              ? "DD/MM/YYYY"
              : undefined
          }
        />
      </>
    );
  };

  return (
    <Modal open={isOpen} onCancel={handleClose} footer={null}>
      <div className="d-flex align-items-center justify-content-center">
        {options.map((option) => (
          <Button
            key={option}
            color="primary"
            onClick={() => setSelectOption(option)}
            style={{
              marginRight: "8px",
              filter: selectOption === option ? "brightness(0.8)" : "none",
            }}
          >
            {t(`solar.compare.${option}`)}
          </Button>
        ))}
      </div>

      <div
        className="d-flex align-items-center justify-content-center"
        style={{ marginTop: "16px", gap: "8px" }}
      >
        {renderDatePickers()}
      </div>

      <div style={{ marginTop: "16px", textAlign: "right" }}>
        <Button
          onClick={handleClose}
          color="secondary"
          style={{ marginRight: "8px" }}
        >
          Cancelar
        </Button>
        <Button type="primary" color="primary" onClick={handleOk}>
          Buscar
        </Button>
      </div>
    </Modal>
  );
};

const EnergyChart = ({
  month,
  pastMonth,
  days,
  energy,
  pastEnergy,
  selectOption,
}) => {
  const data = days.map((day, index) => ({
    date: day.toString().padStart(2, "0"),
    currentMonth: energy[index] ?? 0,
    pastMonth: pastEnergy[index] ?? 0,
  }));

  const { energyAccumulated, energyForecasted } = COLOR_SOLAR;

  const height = useChartHeight();

  return (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis tickFormatter={formatEnergy} width={80} />
        <Tooltip
          formatter={(value) => formatEnergy(value)}
          labelFormatter={(value) => `${value}`}
        />
        <Legend />
        <Bar
          type="monotone"
          dataKey="currentMonth"
          name={`${selectOption === "week" ? "Semana " : ""}${moment(
            month
          ).format(
            selectOption === "week"
              ? "W - MMM/YYYY"
              : selectOption === "day"
              ? "DD/MM/YYYY"
              : "MMM/YYYY"
          )}`}
          fill={energyAccumulated}
        />
        {pastEnergy.length > 0 && (
          <Bar
            type="monotone"
            dataKey="pastMonth"
            name={`${selectOption === "week" ? "Semana " : ""}${moment(
              pastMonth
            ).format(
              selectOption === "week"
                ? "W - MMM/YYYY"
                : selectOption === "day"
                ? "DD/MM/YYYY"
                : "MMM/YYYY"
            )}`}
            fill={energyForecasted}
          />
        )}
      </BarChart>
    </ResponsiveContainer>
  );
};

const EnergyYield = ({ projectId, month }) => {
  const { t } = useTranslation();

  const { data, status } = useQuery({
    queryKey: [
      "readSolarMonthlyEnergy",
      { projId: projectId, monthRef: month },
    ],
    queryFn: () =>
      axios
        .get("/readSolarMonthlyEnergy", {
          params: {
            projId: projectId,
            monthRef: month,
          },
        })
        .then((res) => res.data),
    staleTime: 1000 * 60 * 60, // 1 hour
    refetchInterval: 1000 * 60 * 60, // 1 hour
  });

  const [loading, setLoading] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(month);
  const [pastMonth, setPastMonth] = useState(
    new Date(month.getFullYear(), month.getMonth() - 1)
  );
  const [firstEnergy, setFirstEnergy] = useState([]);
  const [handleSearchData, setHandleSearchData] = useState([]);
  const [pastEnergy, setPastEnergy] = useState([]);
  const [compare, setCompare] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectOption, setSelectOption] = useState("month");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCompare = async ({ firstDate, secondDate, selectOption }) => {
    setLoading(true);
    const initUnix = moment(firstDate).unix();
    const finalUnix = moment(secondDate).unix();

    try {
      const response = await queryClient.ensureQueryData({
        queryKey: [
          "v2/solar/compareData",
          { projId: projectId, initialDate: firstDate, finalDate: secondDate },
        ],
        queryFn: () =>
          axios.get("v2/solar/compareData", {
            params: {
              projId: projectId,
              initialDate: initUnix,
              finalDate: finalUnix,
              type: selectOption,
            },
          }),
      });

      if (selectOption === "month") {
        let firstLegend = moment(firstDate).format("M-YYYY");
        let secondLegend = moment(secondDate).format("M-YYYY");

        let firstData = response.data[firstLegend];
        let secondData = response.data[secondLegend];

        let secondEnergy = secondData.energy;

        setCurrentMonth(moment(firstDate, "M-YYYY").toDate());
        setPastMonth(moment(secondDate, "M-YYYY").toDate());
        setSelectOption("month");

        setFirstEnergy(firstData);
        setPastEnergy(secondEnergy);
      } else if (selectOption === "week") {
        let firstLegend = moment.unix(initUnix).week();
        let secondLegend = moment.unix(finalUnix).week();

        let firstData = response.data[firstLegend];
        let secondData = response.data[secondLegend];

        let secondEnergy = secondData.energy;

        setCurrentMonth(moment(firstDate, "ddd W-YYYY").toDate());
        setPastMonth(moment(secondDate, "ddd W-YYYY").toDate());
        setSelectOption("week");

        setFirstEnergy(firstData);
        setPastEnergy(secondEnergy);
      } else {
        let firstLegend = moment(firstDate).format("DD/MM/YYYY");
        let secondLegend = moment(secondDate).format("DD/MM/YYYY");

        let firstData = response.data[firstLegend];
        let secondData = response.data[secondLegend];

        let secondEnergy = secondData.energy;

        setCurrentMonth(moment(firstDate, "DD/MM/YYYY").toDate());
        setPastMonth(moment(secondDate, "DD/MM/YYYY").toDate());
        setSelectOption("day");

        setFirstEnergy(firstData);
        setPastEnergy(secondEnergy);
      }

      setCompare(true);
    } catch (error) {
      console.log(error);
      toastr.warning("Erro!", "Erro ao buscar dados do mês anterior.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <CardHeader>
        <Row>
          <Col xs={12} md={6}>
            <CardTitle tag="h5">{`${t("solar.monthlyComparison2")}`}</CardTitle>
          </Col>

          <Col
            className="mb-1 d-flex align-items-center justify-content-end"
            style={{ gap: "5px" }}
          >
            {/* <Selects pastMonth={pastMonth} setPastMonth={setPastMonth} /> */}
            <Button
              color="primary"
              onClick={handleOpen}
              disabled={loading}
              size="small"
            >
              {loading ? <Spinner size="sm" /> : t("Comparar")}
            </Button>
          </Col>
        </Row>
      </CardHeader>

      <CardBody>
        {loading || status === "loading" ? (
          <div className="text-center">
            <Spinner color="primary" />
          </div>
        ) : (
          
            <EnergyChart
              month={currentMonth}
              pastMonth={pastMonth}
              days={compare ? firstEnergy?.days : handleSearchData.length ? handleSearchData : data.monthlyEnergy.days}
              energy={compare ? firstEnergy?.energy : data.monthlyEnergy.energy}
              pastEnergy={compare ? pastEnergy : []}
              selectOption={selectOption}
            />
        )}
      </CardBody>

      <ModalCompare
        isOpen={open}
        handleClose={handleClose}
        handleSearch={handleCompare}
      />
    </>
  );
};

export default EnergyYield;
