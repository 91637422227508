// ******* IMPORTS
import React, { useEffect, useState } from "react";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";

import classnames from "classnames";
import {
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ButtonToolbar,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader, //CardTitle,
  Container,
  UncontrolledTooltip,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Spinner,
  TabContent,
  TabPane,
  Nav,
  NavLink,
  NavItem,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretUp,
  faEnvelope,
  faPen,
  faPhone,
  faSearch,
  faSort,
  faTrash,
  faUsersCog,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import { useTranslation } from "react-i18next";

import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import { TreeSelect } from "antd";
import { ModalAddAlarm, ModalAddUser } from "./ModalAddAlarm";
import {
  ModalEditAnalogicAlarm,
  ModalEditDigitalAlarm,
  ModalEditMultiplelAlarm,
} from "./ModalEditAlarm";
import { ModalDeleteAlarm, ModalDeleteManyAlarms } from "./ModalDeleteAlarm";
import ModalAlarmData from "./ModalAlarmData";

/* Ícone de adicionar alarme */
const HeadIcons = ({ tableReload, alarmsSelected }) => {
  const { t } = useTranslation();

  const [openAddAlarm, setOpenAddAlarm] = useState(false);
  const [openDelMany, setOpenDelMany] = useState(false);
  // ativa/desativa modal
  function toggleAddAlarm() {
    setOpenAddAlarm(!openAddAlarm);
  }
  function toggleDelMany() {
    setOpenDelMany(!openDelMany);
  }

  return (
    <div className="d-flex justify-content-between">
      {/* Selecionados */}
      <div>
        {alarmsSelected.length ? (
          <div className="d-flex justify-content-left">
            <p className="mr-2 mb-0 pb-0">
              {alarmsSelected.length}{" "}
              {alarmsSelected.length > 1
                ? t("alarms.selectedAlarms")
                : t("alarms.selectedAlarm")}
              .
            </p>
            <div
              id={"deleteMany"}
              className="align-middle cursor-pointer mb-0 pb-0"
              onClick={() => setOpenDelMany(true)}
            >
              <FontAwesomeIcon icon={faTrash} fixedWidth />
              {/*** modal de adicionar alarme  ***/}
              {openDelMany ? (
                <ModalDeleteManyAlarms
                  open={openDelMany}
                  toggle={toggleDelMany}
                  alarmsSelected={alarmsSelected}
                  tableReload={tableReload}
                />
              ) : null}
              {/* Tooltip */}
              <UncontrolledTooltip placement="top" target={"deleteMany"}>
                {t("alarms.deleteAlarms")}
              </UncontrolledTooltip>
            </div>
          </div>
        ) : null}
      </div>

      <div // ícone de adicionar alarme
        id={"addAlarm"}
        className="mr-2 cursor-pointer"
        onClick={() => setOpenAddAlarm(true)}
      >
        <Button color="primary">{t("alarms.addAlarm")}</Button>
        {openAddAlarm ? ( // modal de adicionar alarme
          <ModalAddAlarm
            open={openAddAlarm}
            toggle={toggleAddAlarm}
            tableReload={tableReload}
          />
        ) : null}
      </div>
    </div>
  );
};

/*** Badge de prioridade ***/
const PriorityBadge = ({ priority }) => {
  const { t } = useTranslation();

  return priority === 1 ? (
    <Badge color="danger">{t("alarms.high")}</Badge>
  ) : priority === 2 ? (
    <Badge color="warning">{t("alarms.medium")}</Badge>
  ) : (
    <Badge color="primary">{t("alarms.low")}</Badge>
  );
};

/*** Ícones de contato ***/
const ContactIcons = ({ alarm }) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-left">
      {alarm.email ? (
        <div id={"mail" + alarm.id}>
          <FontAwesomeIcon fixedWidth icon={faEnvelope} className="mx-1" />
          <UncontrolledTooltip placement="top" target={"mail" + alarm.id}>
            E-mail
          </UncontrolledTooltip>
        </div>
      ) : null}
      {alarm.phone ? (
        <div id={"phone" + alarm.id}>
          <FontAwesomeIcon fixedWidth icon={faPhone} className="mx-1" />
          <UncontrolledTooltip placement="top" target={"phone" + alarm.id}>
            {t("alarms.phone")}
          </UncontrolledTooltip>
        </div>
      ) : null}
      {alarm.whatsapp ? (
        <div id={"whatsapp" + alarm.id}>
          <FontAwesomeIcon
            fixedWidth
            icon={faWhatsapp}
            className="mx-1"
            color="green"
          />
          <UncontrolledTooltip placement="top" target={"whatsapp" + alarm.id}>
            WhatsApp
          </UncontrolledTooltip>
        </div>
      ) : null}
    </div>
  );
};

/*** Ícones de ação ***/
const ActionIcons = ({ alarm, tableReload }) => {
  const { t } = useTranslation();

  const [openEditAlarm, setOpenEditAlarm] = useState(false);
  const [openDeleteAlarm, setOpenDeleteAlarm] = useState(false);
  const [openAddUsers, setOpenAddUsers] = useState(false);
  // ativa/desativa modais
  function toggleEditAlarm() {
    setOpenEditAlarm(!openEditAlarm);
  }
  function toggleDeleteAlarm() {
    setOpenDeleteAlarm(!openDeleteAlarm);
  }
  function toggleAddUser() {
    setOpenAddUsers(!openAddUsers);
  }

  return (
    <div className="d-flex justify-content-left">
      <div // ícone de editar alarme
        id={"edit" + alarm.id}
        className="align-middle cursor-pointer"
        onClick={() => setOpenEditAlarm(true)}
      >
        <FontAwesomeIcon icon={faPen} fixedWidth />
        {/*** modal de editar alarme  ***/}
        {openEditAlarm ? (
          alarm.type === 1 ? (
            <ModalEditDigitalAlarm
              open={openEditAlarm}
              toggle={toggleEditAlarm}
              alarm={alarm}
              tableReload={tableReload}
            />
          ) : alarm.type === 0 ? (
            <ModalEditAnalogicAlarm
              open={openEditAlarm}
              toggle={toggleEditAlarm}
              alarm={alarm}
              tableReload={tableReload}
            />
          ) : (
            <ModalEditMultiplelAlarm
              open={openEditAlarm}
              toggle={toggleEditAlarm}
              alarm={alarm}
              tableReload={tableReload}
            />
          )
        ) : null}
        {/* Tooltip */}
        <UncontrolledTooltip placement="top" target={"edit" + alarm.id}>
          {t("alarms.editAlarm")}
        </UncontrolledTooltip>
      </div>

      <div // ícone de remover alarme
        id={"delete" + alarm.id}
        className="align-middle cursor-pointer"
        onClick={() => setOpenDeleteAlarm(true)}
      >
        <FontAwesomeIcon icon={faTrash} fixedWidth />
        {/*** modal de remover alarme  ***/}
        {openDeleteAlarm ? (
          <ModalDeleteAlarm
            open={openDeleteAlarm}
            toggle={toggleDeleteAlarm}
            alarm={alarm}
            tableReload={tableReload}
          />
        ) : null}
        {/* Tooltip */}
        <UncontrolledTooltip placement="top" target={"delete" + alarm.id}>
          {t("alarms.deleteAlarm")}
        </UncontrolledTooltip>
      </div>

      <div // ícone de vincular alarme a usuário
        id={"users" + alarm.id}
        className="align-middle cursor-pointer"
        onClick={toggleAddUser}
      >
        <FontAwesomeIcon icon={faUsersCog} fixedWidth />
        {/*** modal de vincular alarme a usuários ***/}
        {openAddUsers ? (
          <ModalAddUser
            open={openAddUsers}
            toggle={toggleAddUser}
            alarm={alarm}
          />
        ) : null}
        {/* Tooltip */}
        <UncontrolledTooltip placement="top" target={"users" + alarm.id}>
          {t("alarms.linkUsers")}
        </UncontrolledTooltip>
      </div>
    </div>
  );
};

//  ABRE MODAL DE VISUALIZAÇÃO DE ALARMES AVANÇADOS
const AlarmNameClick = ({ cell, advAlarm, tableReload }) => {
  const [openAlarmData, setOpenAlarmData] = useState(false);

  //FUNCAO TOGGLE
  function toggleAlarmData() {
    setOpenAlarmData(!openAlarmData);
  }

  return (
    <div
      id={"dataAlarm"}
      className="mr-2 cursor-pointer"
      onClick={() => setOpenAlarmData(true)}
    >
      <p>{cell}</p>
      {openAlarmData ? ( // modal de dados de alarme
        <ModalAlarmData
          key={"id"}
          open={openAlarmData}
          toggle={toggleAlarmData}
          tableReload={tableReload}
          alarmData={advAlarm}
        />
      ) : null}
    </div>
  );
};

// COMPONENTE DE BUSCA
const SearchField = ({ setAlarms, setMore }) => {
  const { t } = useTranslation();

  const [analogic, setAnalogic] = useState(true);
  const [digital, setDigital] = useState(true);
  const [multiples, setMultiples] = useState(true);
  const [high, setHigh] = useState(true);
  const [moderate, setModerate] = useState(true);
  const [low, setLow] = useState(true);
  const [searchField, setSearchField] = useState("");
  const [treeOptions, setTreeOptions] = useState();
  const [treeValue, setTreeValue] = useState([]);
  const [projects, setProjects] = useState([]);
  const [devices, setDevices] = useState([]);
  const { SHOW_PARENT } = TreeSelect;
  const maxAlarms = 30; // máximo de alarmes por chamada
  const [phone, setPhone] = useState(false);
  const [whatsapp, setWhatsapp] = useState(false);
  const [email, setEmail] = useState(false);

  useEffect(() => {
    // busca projetos do usuário
    axios
      .get("/projectUserSearch")
      .then((res) => {
        let optAux = [];
        if (res.data.length > 0) {
          res.data.forEach((proj) => {
            const str = "proj" + proj.id;
            optAux.push({
              title: proj.name,
              value: str,
              key: str,
              id: str,
              isLeaf: false,
              checkable: true,
              children: [],
            });
          });
        }
        setTreeOptions(optAux);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
      });
  }, []);

  /** Carrega dispositivos do projeto ao expandir */
  function onLoadData(treeNode) {
    return new Promise((resolve) => {
      const index = treeOptions.findIndex((item) => {
        return item.key === treeNode.key;
      });
      setTimeout(() => {
        if (treeNode.children.length) {
          resolve();
          return;
        } else {
          axios
            .get("/deviceSearch", {
              params: {
                projId: treeNode.key.split("proj")[1],
                // projId: treeNode.id
              },
            })
            .then((res) => {
              let optAux = treeOptions;
              if (res.data.length > 0) {
                res.data.forEach((dev) => {
                  const str = "dev" + dev.id;
                  optAux[index].children.push({
                    title: dev.description,
                    value: str,
                    key: str,
                    id: str,
                    isLeaf: true,
                    checkable: true,
                  });
                });
              }
              setTreeOptions();
              setTreeOptions(optAux);
            })
            .catch((err) => {
              console.log(err);
              console.log(err.response);
            })
            .finally(() => {
              resolve();
              return;
            });
        }
      }, 300);
    });
  }

  /** Atualiza estados de projeto e dispositivo selecionado */
  function onChange(checkedKeys) {
    setTreeValue(checkedKeys);
    if (checkedKeys.length > 0) {
      let projAux = [];
      let devAux = [];
      checkedKeys.forEach((key) => {
        if (key.includes("proj")) {
          projAux.push(key.split("proj")[1]);
        } else if (key.includes("dev")) {
          devAux.push(key.split("dev")[1]);
        }
      });
      setProjects(projAux);
      setDevices(devAux);
    } else {
      setProjects([]);
      setDevices([]);
    }
  }

  /* Função de filtro avançado */
  function onFilterSearch() {
    axios
      .get("/registerAlarmSearch", {
        params: {
          analogic: analogic,
          digital: digital,
          multiples: multiples,
          high: high,
          moderate: moderate,
          low: low,
          projects: projects,
          devices: devices,
          searchField: searchField.toLowerCase(),
          whatsapp: whatsapp,
          phone: phone,
          email: email,
          offset: 0,
          maxAlarms: maxAlarms,
        },
      })
      .then((response) => {
        setAlarms(response.data.alarms);
        setMore(response.data.isMore);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
      });
  }

  return (
    <div>
      {/* Ferramentas de busca */}
      <div className="alarm-search">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            onFilterSearch();
          }}
        >
          <Row className="mb-2">
            {/* Busca por termo/projeto/prioridade */}
            <Col md={5}>
              {/* Busca por termo */}
              <FormGroup className="mb-0">
                <Label for="search" className="ml-1">
                  {t("alarms.searchField")}
                </Label>
                <Input
                  type="text"
                  name="search"
                  id="search"
                  placeholder={t("alarms.textPlaceholder")}
                  value={searchField}
                  onChange={(e) => {
                    e.preventDefault();
                    setSearchField(e.target.value);
                  }}
                />
              </FormGroup>

              {/* Busca por projeto */}
              <FormGroup className="mb-0 mt-2">
                <Label for="project" className="ml-1">
                  {t("alarms.searchProject")}
                </Label>
                <TreeSelect
                  id="project"
                  treeDataSimpleMode
                  maxTagCount="responsive"
                  treeData={treeOptions}
                  value={treeValue}
                  onChange={onChange}
                  loadData={onLoadData}
                  treeCheckable={true}
                  showCheckedStrategy={SHOW_PARENT}
                  placeholder={t("alarms.treePlaceholder")}
                  size="small"
                  style={{ width: "100%" }}
                  className="cursor-pointer"
                />
              </FormGroup>

              {/* Checkboxes de prioridade  */}
              <div className="d-flex justify-content-between mt-2">
                <Label className="ml-1">{t("alarms.priority")}:</Label>
                <FormGroup check inline className="mb-0 ml-2">
                  <Label check className="cursor-pointer">
                    <Input
                      type="checkbox"
                      name="high"
                      className="cursor-pointer"
                      checked={high}
                      onChange={() => setHigh(!high)}
                      style={{ verticalAlign: "middle" }}
                    />
                    <PriorityBadge priority={1} />
                  </Label>
                </FormGroup>

                <FormGroup check inline className="mb-0 ml-auto">
                  <Label check className="cursor-pointer">
                    <Input
                      type="checkbox"
                      name="moderate"
                      className="cursor-pointer"
                      checked={moderate}
                      onChange={() => setModerate(!moderate)}
                      style={{ verticalAlign: "middle" }}
                    />
                    <PriorityBadge priority={2} />
                  </Label>
                </FormGroup>

                <FormGroup check inline className="mb-0 ml-auto">
                  <Label check className="cursor-pointer">
                    <Input
                      type="checkbox"
                      name="low"
                      className="cursor-pointer"
                      checked={low}
                      onChange={() => setLow(!low)}
                      style={{ verticalAlign: "middle" }}
                    />
                    <PriorityBadge priority={3} />
                  </Label>
                </FormGroup>
              </div>
            </Col>

            {/* BUSCA POR CONTATO */}
            <Col md={5}>
              <FormGroup>
                <div className="d-flex justify-content-between mt-4 ml-1">
                  <Label>{t("alarms.contact")}:</Label>

                  <FormGroup check inline className="mb-0 ml-2">
                    <Label check className="cursor-pointer">
                      <Input
                        type="checkbox"
                        name="Telefone"
                        className="cursor-pointer"
                        checked={phone}
                        onChange={() => setPhone(!phone)}
                      />
                      <Button size="sm" color="primary" disabled>
                        {"Telefone"}
                        <FontAwesomeIcon
                          className="ml-1"
                          fixedWidth
                          icon={faPhone}
                        />
                      </Button>
                    </Label>
                  </FormGroup>

                  <FormGroup check inline className="mb-0 ml-auto">
                    <Label check className="cursor-pointer">
                      <Input
                        type="checkbox"
                        name="Whatsapp"
                        className="cursor-pointer"
                        checked={whatsapp}
                        onChange={() => setWhatsapp(!whatsapp)}
                      />
                      <Button size="sm" color="success" disabled>
                        {"Whatsapp"}
                        <FontAwesomeIcon
                          className="ml-1"
                          fixedWidth
                          icon={faWhatsapp}
                        />
                      </Button>
                    </Label>
                  </FormGroup>

                  <FormGroup check inline className="mb-0 ml-auto">
                    <Label check className="cursor-pointer">
                      <Input
                        type="checkbox"
                        name="Email"
                        className="cursor-pointer"
                        checked={email}
                        onChange={() => setEmail(!email)}
                      />
                      <Button size="sm" color="secondary" disabled>
                        {"Email"}
                        <FontAwesomeIcon
                          className="ml-1"
                          fixedWidth
                          icon={faEnvelope}
                        />
                      </Button>
                    </Label>
                  </FormGroup>
                </div>
              </FormGroup>
              {/* Checkboxes de estado de ativação */}
              <div className="d-flex justify-content-between mt-4">
                <Label className="ml-1">{"Tipos de alarme: "}</Label>
                <FormGroup check inline className="mb-0 ml-2">
                  <Label check className="cursor-pointer">
                    <Input
                      type="checkbox"
                      name="Analogic"
                      className="cursor-pointer"
                      checked={analogic}
                      onChange={() => setAnalogic(!analogic)}
                    />
                    {"Analogico"}
                  </Label>
                </FormGroup>

                <FormGroup check inline className="mb-0 ml-auto">
                  <Label check className="cursor-pointer">
                    <Input
                      type="checkbox"
                      name="Digital"
                      className="cursor-pointer"
                      checked={digital}
                      onChange={() => setDigital(!digital)}
                    />
                    {"Digital"}
                  </Label>
                </FormGroup>

                <FormGroup check inline className="mb-0 ml-auto">
                  <Label check className="cursor-pointer">
                    <Input
                      type="checkbox"
                      name="Multiplo"
                      className="cursor-pointer"
                      checked={multiples}
                      onChange={() => setMultiples(!multiples)}
                    />
                    {"Multiplo"}
                  </Label>
                </FormGroup>
              </div>
            </Col>

            {/* Botão de busca */}
            <Col md={2} className="d-flex align-items-end justify-content-end">
              <Button color="primary" type="submit">
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

/*** Tabela ***/
const AlarmConfigTable = () => {
  const { t } = useTranslation();

  const [loadConfig, setLoadConfig] = useState(true);
  const [alarmConfig, setAlarmConfig] = useState([]);
  const [alarmsSelected, setAlarmsSelected] = useState([]);
  const [isMore, setIsMore] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const sizePerPage = 10;
  const maxAlarms = 30;
  function tableReload() {
    setLoadConfig(true);
  }

  useEffect(() => {
    if (loadConfig) {
      axios
        .get("/userConfigParamsSearch", {
          params: {
            offset: 0,
            maxAlarms: maxAlarms,
          },
        })
        .then((res) => {
          setAlarmConfig(res.data.filter((value) => value.type !== 2));
          setLoadConfig(false);
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);

          setLoadConfig(false);
        })
        .finally(() => setLoadConfig(false));
    }
  }, [loadConfig]);

  const alarmConfigCols = [
    {
      // ID (oculto)
      dataField: "id",
      text: `${t("alarms.id")}`,
      sort: true,
      hidden: true,
      isKey: true,
    },
    {
      // descricao
      dataField: "description",
      text: t("alarms.name"),
      sort: true,
      headerStyle: {
        minWidth: "100px",
      },
    },
    {
      // nome do dispositivo
      dataField: "deviceName",
      text: t("alarms.deviceName"),
      sort: true,
      headerStyle: {
        minWidth: "100px",
      },
    },
    {
      // variável
      dataField: "varName",
      text: t("alarms.varName"),
      headerStyle: {
        minWidth: "100px",
      },
    },
    {
      // tipo
      dataField: "type",
      text: t("alarms.type"),
      formatter: (cell) => (cell ? "D" : "A"),
    },
    {
      // limite low
      dataField: "lowLimit",
      text: t("alarms.lowLimit"),
      headerStyle: {
        minWidth: "50px",
      },
      formatter: (cell, row) =>
        cell === null ? "-" : cell + (row.varUnit ? " " + row.varUnit : null),
    },
    {
      // limite high
      dataField: "highLimit",
      text: t("alarms.highLimit"),
      headerStyle: {
        minWidth: "50px",
      },
      formatter: (cell, row) =>
        cell === null ? "-" : cell + (row.varUnit ? " " + row.varUnit : null),
    },
    {
      // prioridade
      dataField: "priority",
      text: t("alarms.priority"),
      headerStyle: {
        minWidth: "50px",
      },
      formatter: (cell) => <PriorityBadge priority={cell} />,
    },
    {
      // contato
      dataField: "contact",
      text: t("alarms.contact"),
      headerStyle: {
        minWidth: "90px",
      },
      formatter: (_, row) => {
        if (!row.email && !row.phone && !row.whatsapp) {
          return "-";
        }

        return <ContactIcons alarm={row} />;
      },
    },
    {
      // mensagem ON low
      dataField: "messageLowOn",
      text: t("alarms.messageLowOn"),
      headerStyle: {
        minWidth: "100px",
      },
      formatter: (cell) => {
        return cell === null
          ? "-"
          : cell.length > 20
          ? cell.slice(0, 17) + "..."
          : cell;
      },
    },
    {
      // mensagem ON high
      dataField: "messageHighOn",
      text: t("alarms.messageHighOn"),
      headerStyle: {
        minWidth: "100px",
      },
      formatter: (cell) => {
        return cell === null
          ? "-"
          : cell.length > 20
          ? cell.slice(0, 17) + "..."
          : cell;
      },
    },
    {
      // mensagem OFF low
      dataField: "messageLowOff",
      text: t("alarms.messageLowOff"),
      headerStyle: {
        minWidth: "100px",
      },
      formatter: (cell) => {
        return cell === null
          ? "-"
          : cell.length > 20
          ? cell.slice(0, 17) + "..."
          : cell;
      },
    },
    {
      // mensagem OFF high
      dataField: "messageHighOff",
      text: t("alarms.messageHighOff"),
      headerStyle: {
        minWidth: "100px",
      },
      formatter: (cell) => {
        return cell === null
          ? "-"
          : cell.length > 20
          ? cell.slice(0, 17) + "..."
          : cell;
      },
    },
    {
      // ícones de ação (editar/remover)
      dataField: "actions",
      text: t("alarms.actions"),
      headerStyle: { width: "60px" },
      formatter: (_, row) => (
        <ActionIcons
          className="text-center"
          alarm={row}
          tableReload={tableReload}
        />
      ),
    },
  ];

  /* Ícones de ordenação */
  const sortIcon = {
    sortCaret: (order) =>
      !order ? (
        <FontAwesomeIcon className={"fa-xs ml-1"} icon={faSort} />
      ) : order === "asc" ? (
        <FontAwesomeIcon className={"fa-xs ml-1"} icon={faCaretDown} />
      ) : (
        <FontAwesomeIcon className={"fa-xs ml-1"} icon={faCaretUp} />
      ),
  };

  /* Caixas de seleção de linha */
  const selectRow = {
    mode: "checkbox",
    bgColor: "#f8f9fa",
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setAlarmsSelected([...alarmsSelected, row.id]);
      } else {
        setAlarmsSelected(alarmsSelected.filter((item) => item !== row.id));
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        let ids = [];
        rows.map((item) => ids.push(item.id));
        setAlarmsSelected(ids);
      } else {
        setAlarmsSelected([]);
      }
    },
  };

  //**** Paginaçao customizada
  const TableLegend = ({ from, to, size }) => {
    return (
      <span
        className="react-bootstrap-table-pagination-total"
        style={{
          display: window.innerWidth < 700 ? "none" : "block",
          marginBottom: window.innerWidth < 700 ? "15px" : "0",
        }}
      >
        {t("userLogs.showing")} {from}-{to} ({t("userLogs.of")}){size} (
        {"alarmes"})
      </span>
    );
  };

  const CustomPagination = ({ pages, onPageChange }) => {
    let activePage = pages.find((item) => item.active).page;

    return (
      <Col
        className={
          "d-flex justify-content-" +
          (window.innerWidth < 700 ? "start" : "end")
        }
        style={{
          position: window.innerWidth < 700 ? "absolute" : "relative",
        }}
      >
        <ButtonToolbar className="mr-2">
          <ButtonGroup>
            {pages.map((p, i) =>
              p.page === ">" || p.page === ">>" ? (
                <Button
                  key={i}
                  color="primary"
                  outline={!p.active}
                  disabled={!isMore && p.disabled}
                  onClick={() => {
                    // verifica se está na última página
                    if (activePage === pages[pages.length - 3].page) {
                      setAlarmConfig(true);

                      axios
                        .get("/userConfigParamsSearch", {
                          params: {
                            offset: alarmConfig.length,
                          },
                        })
                        .then((res) => {
                          setCurrPage(
                            alarmConfig.length % sizePerPage === 0
                              ? activePage + 1
                              : activePage
                          );
                          let temp = alarmConfig;
                          res.data.alarmConfig.map((item) => temp.push(item));
                          setAlarmConfig(temp);
                          setIsMore(res.data.isMore);
                        })
                        .catch((err) => {
                          console.log(err);
                          console.log(err.response);
                        })
                        .finally(() => setAlarmConfig(false));
                    } else {
                      onPageChange(p.page);
                    }
                  }}
                >
                  {p.page}
                </Button>
              ) : (
                <Button
                  key={i}
                  color="primary"
                  outline={!p.active}
                  disabled={p.disabled}
                  onClick={() => onPageChange(p.page)}
                >
                  {p.page}
                </Button>
              )
            )}
          </ButtonGroup>
        </ButtonToolbar>
      </Col>
    );
  };

  const paginationOptions = {
    page: currPage,
    sizePerPage: sizePerPage,
    pageStartIndex: 1,
    paginationSize: 3,
    alwaysShowAllBtns: true, // Always show next and previous button
    firstPageText: "<<",
    prePageText: "<",
    nextPageText: ">",
    lastPageText: ">>",
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    disablePageTitle: true,
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    showTotal: true,
    pageListRenderer: ({ pages, onPageChange }) => (
      <CustomPagination pages={pages} onPageChange={onPageChange} />
    ),
    paginationTotalRenderer: (from, to, size) => (
      <TableLegend from={from} to={to} size={size} />
    ),
  };

  return (
    <div>
      {/* Exibição de alarmes */}
      {!alarmConfig.length ? ( // Se não há alarmes a serem mostrados...
        loadConfig ? ( // ... e se está carregando dados da API...
          // ... exibe spinner
          <div className="text-center">
            <Spinner color="primary" />
          </div>
        ) : (
          // se não está mais carregando, é porque não há alarmes configurados
          <div>
            <HeadIcons
              tableReload={tableReload}
              alarmsSelected={alarmsSelected}
            />
            <SearchField
              alarms={alarmConfig}
              setAlarms={setAlarmConfig}
              setMore={setIsMore}
            />
            <p className="text-center">{t("alarms.noAlarmConfig")}.</p>
          </div>
        )
      ) : (
        // Tabela
        <div style={{ width: "100%", overflowX: "scroll" }}>
          <HeadIcons
            tableReload={tableReload}
            alarmsSelected={alarmsSelected}
          />
          <SearchField
            alarms={alarmConfig}
            setAlarms={setAlarmConfig}
            setMore={setIsMore}
          />
          <BootstrapTable
            keyField="id"
            columns={alarmConfigCols}
            condensed
            data={alarmConfig}
            bordered={false}
            striped={true}
            sort={sortIcon}
            selectRow={selectRow}
            pagination={paginationFactory(paginationOptions)}
          />
        </div>
      )}
    </div>
  );
};

/******* PAGINA DE ALARMES MULTIPLO  ******/
const AdvancedAlarmConfigTable = () => {
  const { t } = useTranslation();
  const [loadConfig, setLoadConfig] = useState(true);
  const [alarmsSelected, setAlarmsSelected] = useState([]);
  const [advAlarm, setAdvAlarm] = useState([]);
  const [isMore, setIsMore] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const maxAlarms = 30;
  const sizePerPage = 10;
  function tableReload() {
    setLoadConfig(true);
  }

  useEffect(() => {
    if (loadConfig) {
      axios
        .get("/userConfigParamsSearch", {
          params: {
            offset: 0,
            maxAlarms: maxAlarms,
          },
        })
        .then((res) => {
          setAdvAlarm(res.data.filter((value) => value.type === 2));

          setLoadConfig(false);
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);

          setLoadConfig(false);
        });
    }
  }, [loadConfig]);

  const alarmConfigCols = [
    {
      // ID (oculto)
      dataField: "id",
      text: `${t("alarms.id")}`,
      sort: true,
      hidden: true,
      isKey: true,
    },
    {
      // descricao
      dataField: "description",
      text: t("alarms.name"),
      sort: true,
      headerStyle: {
        minWidth: "100px",
      },
      // events:{ onClick:()=>{
      //   console.log('click');
      //   setOpenAlarmData(true)
      // }},
      formatter: (cell, row) => (
        <AlarmNameClick cell={cell} advAlarm={row} tableReload={tableReload} />
      ),
    },
    {
      // prioridade
      dataField: "priority",
      text: t("alarms.priority"),
      headerStyle: {
        minWidth: "50px",
      },
      formatter: (cell) => <PriorityBadge priority={cell} />,
    },
    {
      // contato
      dataField: "contact",
      text: t("alarms.contact"),
      headerStyle: {
        minWidth: "90px",
      },
      formatter: (_, row) => {
        if (!row.email && !row.phone && !row.whatsapp) {
          return "-";
        }

        return <ContactIcons alarm={row} />;
      },
    },
    {
      // mensagem ON low
      dataField: "messageLowOn",
      text: t("alarms.messageLowOn"),
      headerStyle: {
        minWidth: "100px",
      },
      formatter: (cell) => {
        return cell === null
          ? "-"
          : cell.length > 20
          ? cell.slice(0, 17) + "..."
          : cell;
      },
    },
    {
      // mensagem OFF low
      dataField: "messageLowOff",
      text: t("alarms.messageLowOff"),
      headerStyle: {
        minWidth: "100px",
      },
      formatter: (cell) => {
        return cell === null
          ? "-"
          : cell.length > 20
          ? cell.slice(0, 17) + "..."
          : cell;
      },
    },
    {
      // ícones de ação (editar/remover)
      dataField: "actions",
      text: t("alarms.actions"),
      headerStyle: { width: "60px" },
      formatter: (_, row) => (
        <ActionIcons
          className="text-center"
          alarm={row}
          tableReload={tableReload}
        />
      ),
    },
  ];

  /* Ícones de ordenação */
  const sortIcon = {
    sortCaret: (order) =>
      !order ? (
        <FontAwesomeIcon className={"fa-xs ml-1"} icon={faSort} />
      ) : order === "asc" ? (
        <FontAwesomeIcon className={"fa-xs ml-1"} icon={faCaretDown} />
      ) : (
        <FontAwesomeIcon className={"fa-xs ml-1"} icon={faCaretUp} />
      ),
  };

  /* Caixas de seleção de linha */
  const selectRow = {
    mode: "checkbox",
    bgColor: "#f8f9fa",
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setAlarmsSelected([...alarmsSelected, row.id]);
      } else {
        setAlarmsSelected(alarmsSelected.filter((item) => item !== row.id));
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        let ids = [];
        rows.map((item) => ids.push(item.id));
        setAlarmsSelected(ids);
      } else {
        setAlarmsSelected([]);
      }
    },
  };

  //**** Paginaçao customizada
  const TableLegend = ({ from, to, size }) => {
    return (
      <span
        className="react-bootstrap-table-pagination-total"
        style={{
          display: window.innerWidth < 700 ? "none" : "block",
          marginBottom: window.innerWidth < 700 ? "15px" : "0",
        }}
      >
        {t("userLogs.showing")} {from}-{to} ({t("userLogs.of")}){size} (
        {"alarmes"})
      </span>
    );
  };

  const CustomPagination = ({ pages, onPageChange }) => {
    let activePage = pages.find((item) => item.active).page;

    return (
      <Col
        className={
          "d-flex justify-content-" +
          (window.innerWidth < 700 ? "start" : "end")
        }
        style={{
          position: window.innerWidth < 700 ? "absolute" : "relative",
        }}
      >
        <ButtonToolbar className="mr-2">
          <ButtonGroup>
            {pages.map((p, i) =>
              p.page === ">" || p.page === ">>" ? (
                <Button
                  key={i}
                  color="primary"
                  outline={!p.active}
                  disabled={!isMore && p.disabled}
                  onClick={() => {
                    // verifica se está na última página
                    if (activePage === pages[pages.length - 3].page) {
                      setAdvAlarm(true);

                      axios
                        .get("/userConfigParamsSearch", {
                          params: {
                            offset: advAlarm.length,
                          },
                        })
                        .then((res) => {
                          setCurrPage(
                            advAlarm.length % sizePerPage === 0
                              ? activePage + 1
                              : activePage
                          );
                          let temp = advAlarm;
                          res.data.advAlarm.map((item) => temp.push(item));
                          setAdvAlarm(temp);
                          setIsMore(res.data.isMore);
                        })
                        .catch((err) => {
                          console.log(err);
                          console.log(err.response);
                        })
                        .finally(() => setAdvAlarm(false));
                    } else {
                      onPageChange(p.page);
                    }
                  }}
                >
                  {p.page}
                </Button>
              ) : (
                <Button
                  key={i}
                  color="primary"
                  outline={!p.active}
                  disabled={p.disabled}
                  onClick={() => onPageChange(p.page)}
                >
                  {p.page}
                </Button>
              )
            )}
          </ButtonGroup>
        </ButtonToolbar>
      </Col>
    );
  };

  const paginationOptions = {
    page: currPage,
    sizePerPage: sizePerPage,
    pageStartIndex: 1,
    paginationSize: 3,
    alwaysShowAllBtns: true, // Always show next and previous button
    firstPageText: "<<",
    prePageText: "<",
    nextPageText: ">",
    lastPageText: ">>",
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    disablePageTitle: true,
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    showTotal: true,
    pageListRenderer: ({ pages, onPageChange }) => (
      <CustomPagination pages={pages} onPageChange={onPageChange} />
    ),
    paginationTotalRenderer: (from, to, size) => (
      <TableLegend from={from} to={to} size={size} />
    ),
  };
  return (
    <div>
      {/* Exibição de alarmes */}
      {!advAlarm.length ? ( // Se não há alarmes a serem mostrados...
        loadConfig ? ( // ... e se está carregando dados da API...
          // ... exibe spinner
          <div className="text-center">
            <Spinner color="primary" />
          </div>
        ) : (
          // se não está mais carregando, é porque não há alarmes configurados
          <div>
            <HeadIcons
              tableReload={tableReload}
              alarmsSelected={alarmsSelected}
            />
          <SearchField />

            <p className="text-center">{t("alarms.noAlarmConfig")}.</p>
          </div>
        )
      ) : (
        // Tabela
        <>
          <HeadIcons
            tableReload={tableReload}
            alarmsSelected={alarmsSelected}
          />
          <SearchField />
          <BootstrapTable
            keyField="id"
            data={advAlarm}
            columns={alarmConfigCols}
            condensed
            bordered={false}
            striped={true}
            sort={sortIcon}
            selectRow={selectRow}
            pagination={paginationFactory(paginationOptions)}
          />
        </>
      )}
    </div>
  );
};

/*** Página ***/
const AlarmConfig = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("1");
  function toggle(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>{t("alarms.titleRegisters")}</HeaderTitle>

        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">{t("sidebar.home")}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/">{t("sidebar.alarms")}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{t("sidebar.alarmConfig")}</BreadcrumbItem>
        </Breadcrumb>
      </Header>

      <Card style={{ marginTop: "-26px" }}>
        <CardHeader>
          <Nav tabs className="card-header-tabs">
            <NavItem style={{ zIndex: "1000" }}>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggle("1");
                }}
                href="#"
              >
                {<h6>Alarmes</h6>}
              </NavLink>
            </NavItem>

            <NavItem style={{ zIndex: "1000" }}>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggle("2");
                }}
                href="#"
              >
                {<h6>Alarmes avançados</h6>}
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>

        <CardBody>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <AlarmConfigTable />
            </TabPane>
          </TabContent>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="2">
              <AdvancedAlarmConfigTable />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </Container>
  );
};

export default AlarmConfig;
