import moment from "moment";
import { useEffect, useState } from "react";
import { Trash } from "react-feather";
import {
    CardBody,
    CardHeader,
    CardSubtitle,
    CardTitle,
    ListGroup,
    ListGroupItem
} from "reactstrap";
import { baseURL } from "../../../../../onClicks/baseURL";
import { useTranslation } from "react-i18next";

export const GatewayLogs = ({ companyId, setStatusConnection }) => {
  const { t } = useTranslation();
  const [logs, setLogs] = useState([]);

  const handleDeleteLog = (id, time) => {
    setLogs((prevLogs) =>
      prevLogs.filter((log) => log.deviceId !== id && log.time !== time)
    );
  };

  useEffect(() => {
    const eventSource = new EventSource(
      `${baseURL}/v2/noc/gatewayLogs?token=${localStorage.getItem("token")}
      &refreshToken=${localStorage.getItem("refreshToken")}
      &companyId=${companyId}`
    );

    eventSource.onmessage = (event) => {
      if (event.data === "Conexão com o mock MQTT estabelecida") {
        setStatusConnection(true);
        console.log(event.data);
      } else {
        setLogs((prevLogs) => {
          const newLog = JSON.parse(event.data);
          const updatedLogs = prevLogs.map((log) => ({ ...log, isNew: false }));
          const logExists = updatedLogs.some(
            (log) =>
              log.deviceId === newLog.deviceId && log.time === newLog.time
          );
          if (logExists) {
            return updatedLogs.map((log) =>
              log.deviceId === newLog.deviceId && log.time === newLog.time
                ? { ...log, isNew: true }
                : log
            );
          } else {
            return [...updatedLogs, { ...newLog, isNew: true }];
          }
        });
      }
    };

    eventSource.onerror = (error) => {
      console.error("Erro na conexão SSE", error);
      setStatusConnection(false);
      eventSource.close();
    };
    return () => {
      eventSource.close();
    };
  }, [companyId]);

  return (
    <ListGroup
      flush
      style={{
        overflowY: "auto",
        maxHeight: "100%",
        overflowX: "hidden",
      }}
    >
      {logs.length === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            color: "gray",
            fontSize: "18px",
          }}
        >
          {t("noc.main.logs")}
        </div>
      ) : (
        logs
          .slice()
          .reverse()
          .map((log, index) => (
            <ListGroupItem
              key={log.deviceId + log.time + index}
              className={`noc-list-item p-0 ${
                log.isNew ? "recently-added" : ""
              }`}
              style={{
                "--border-color": `${
                  log.status === "on" ? "rgb(26, 202, 26)" : "rgb(247, 51, 51)"
                }`,
              }}
            >
              <CardHeader className="d-flex justify-content-between align-items-center w-100">
                <div className="d-flex flex-1 justify-content-between pr-2 w-100">

                <CardTitle tag="h5" className="mb-0">
                  {log.description}
                </CardTitle>
                <Trash
                  size={16}
                  className="noc-delete-list-item"
                  onClick={() => handleDeleteLog(log.deviceId, log.time)}
                  />
                  </div>
                <div
                  className="noc-status-log d-flex"
                  style={{
                    "--circle-color": `${
                      log.status === "on"
                        ? "rgb(26, 202, 26)"
                        : "rgb(247, 51, 51)"
                    }`,
                  }}
                >
                  <CardSubtitle className="text-muted ">
                    {log.status === "on"
                      ? t("noc.main.connected")
                      : t("noc.main.disconnected")}{" "}
                    <CardSubtitle
                      tag={"p"}
                      style={{ fontSize: "12px", textAlign: "end" }}
                    >
                      {moment.utc(log.time).local().format("HH:mm:ss")}
                    </CardSubtitle>
                  </CardSubtitle>
                </div>

                
              </CardHeader>
              <CardBody
                className="d-flex flex-wrap"
                style={{
                  gap: "8px",
                  fontSize: "12px",
                  paddingTop: 0,
                }}
              >
                <CardSubtitle className="mb-0">
                  <strong>{t("home.project")}:</strong> {log.projectName}
                </CardSubtitle>
                <CardSubtitle className="mb-0">
                  <strong>{t("home.company")}:</strong> {log.companyName}
                </CardSubtitle>
                <CardSubtitle className="mb-0">
                  <strong>{t("projTable.model")}:</strong> {log.model}
                </CardSubtitle>
                <CardSubtitle className="mb-0">
                  <strong>{t("projTable.manufacturer")}:</strong>{" "}
                  {log.manufacturer}
                </CardSubtitle>
              </CardBody>
            </ListGroupItem>
          ))
      )}
    </ListGroup>
  );
};
