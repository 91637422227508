import {
  faPercent,
  faStreetView,
  faSun,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { Zap } from "react-feather";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import { calcPercentage, formatEnergy } from "../../../../../utils/functions";
import {
  PRESET,
  useWindowDimensions,
} from "../../../../igDashboards/view/hooks/useWindowDimensions";

export default function LateralPerformanceCards({ projectId }) {
  const userType = localStorage.getItem("userType");
  const { preset, height } = useWindowDimensions();
  const { t } = useTranslation();
  const { data: newData, status } = useQuery({
    queryKey: [
      "readSolarPlantInfo",
      {
        projId: projectId,
      },
    ],
    queryFn: () =>
      axios
        .get("readSolarPlantInfo", {
          params: {
            projId: projectId,
          },
        })
        .then((res) => res.data),
    staleTime: 1000 * 60 * 15, // 1 hour
    refetchInterval: 1000 * 60 * 15, // 1 hour
  });

  const avgAvailability =
    (newData?.info?.deviceOnline / newData?.info?.totalDevices) * 100;

  const hasPvSyst =
    !newData || status === "loading" ? true : newData.info.hasPvSyst;
  const hasMetricModels =
    !newData || status === "loading" ? true : newData.info.hasMetricModels;

  const performanceRatio = newData?.info?.monthPvSystRatio;

  const percIrradiation = calcPercentage(
    newData?.info?.monthlyEnergy,
    newData?.info?.projectedEnergyIrradiation
  );
  const hasRatio = performanceRatio > 0;
  const delta = percIrradiation / (performanceRatio > 0 ? performanceRatio : 1);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        maxHeight: preset === PRESET.DESKTOP_HD ? height - 300 : "unset",
        overflowY: "auto",
      }}
    >
      <CardInfo
        loading={!newData || status === "loading"}
        title={t("solar.pr")}
        value={`${(newData?.info?.performanceRatio * 100)
          .toFixed(1)
          .replace(".", ",")} %`}
        values={[
          {
            value: `${
              newData?.info?.projectedEnergyIrradiation === 0
                ? "0,0"
                : calcPercentage(
                    newData?.info?.monthlyEnergy,
                    newData?.info?.projectedEnergyIrradiation
                  )
                    .toFixed(2)
                    .replace(".", ",")
            }%`,
            title: `${t("solar.prPvSystPercent")}`,
            show: hasMetricModels && userType !== "3",
          },
          {
            value: `${
              newData?.info?.projectedEnergyPvSyst === 0
                ? "0,0"
                : (
                    (newData?.info?.monthlyEnergy /
                      newData?.info?.projectedEnergyPvSyst) *
                    100
                  )
                    .toFixed(2)
                    .replace(".", ",")
            }%`,
            title: `${t("solar.generatedVsPvsyst")}`,
            show: hasPvSyst,
          },
          {
            value: `${hasRatio ? delta.toFixed(2).replace(".", ",") : "0,0"}%`,
            title: `Delta`,
            show: Boolean(delta) && hasMetricModels,
          },
        ]}
        icon={
          <Zap
            className="feather align-middle"
            style={{ width: "25px", height: "25px" }}
          />
        }
      />

      <CardInfo
        loading={!newData || status === "loading"}
        title={t("solar.expectedGeneration")}
        values={[
          {
            value: formatEnergy(newData?.info?.projectedEnergyIrradiation),
            title: t("solar.irradiation"),
            show: hasMetricModels,
          },
          {
            value: formatEnergy(newData?.info?.projectedEnergyPvSyst),
            title: "pvSyst",
            show: hasPvSyst,
          },
        ]}
        icon={
          <FontAwesomeIcon icon={faStreetView} style={{ fontSize: "22px" }} />
        }
      />

      <CardInfo
        loading={!newData || status === "loading"}
        large={!hasMetricModels && !hasPvSyst}
        title={t("solar.capacityFactor")}
        value={`${(newData?.info?.capacityFactor * 100)
          .toFixed(2)
          .replace(".", ",")} %`}
        icon={<FontAwesomeIcon icon={faSun} style={{ fontSize: "21px" }} />}
      />

      <CardInfo
        isLast
        large={!hasMetricModels && !hasPvSyst}
        loading={!newData || status === "loading"}
        title={t("solar.disponibility")}
        value={`${avgAvailability.toFixed(2).replace(".", ",")} %`}
        icon={<FontAwesomeIcon icon={faPercent} style={{ fontSize: "18px" }} />}
      />
    </div>
  );
}

const CardInfo = ({
  title,
  value,
  icon,
  values = [],
  loading = true,
  isLast = false,
  large = false,
}) => {
  const { preset } = useWindowDimensions();

  const valuesToShow = values.filter((item) => item.show);

  return (
    <Card
      style={{
        maxWidth: preset === PRESET.DESKTOP_HD ? "200px" : "unset",
        marginBottom: !isLast ? "8px" : "0px",
        width: "100%",
        height: "100%",
        display: values.length && valuesToShow.length === 0 ? "none" : "flex",
      }}
    >
      <CardHeader style={{ padding: "14px 14px 12px 14px" }}>
        <Row>
          <Col className="mt-0">
            <CardTitle tag="h5">{title}</CardTitle>
          </Col>
          <Col className="col-auto" style={{ paddingLeft: "0px" }}>
            <div>{icon}</div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody
        style={{
          paddingTop: "0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: "0px",
          paddingRight: "0px",
          flex: 1,
        }}
      >
        {loading ? (
          <div className="text-center ">
            <Spinner />
          </div>
        ) : values.length > 1 ? (
          <div className="d-flex w-100  align-items-start gap-2">
            {valuesToShow.map((v, i) => (
              <div
                key={i}
                className={`d-flex flex-1 flex-column justify-content-center w-100 align-items-center ${
                  i === 1 ? "border-left" : i === 2 ? "border-left" : ""
                }`}
              >
                <h4 className="mb-0">{v.value}</h4>
                <small>{v.title}</small>
              </div>
            ))}
          </div>
        ) : value || value === 0 ? (
          large ? (
            <h1 className="text-center">{value}</h1>
          ) : (
            <h3 className="text-center">{value}</h3>
          )
        ) : (
          <div className="text-center mb-4">
            <Spinner />
          </div>
        )}
      </CardBody>
    </Card>
  );
};
