import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CardBody, CardHeader, CardTitle, Spinner } from "reactstrap";

import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import moment from "moment";
import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { DateRangeSelector } from "../../../../../components/DateRangeSelector";
import { COLOR_SOLAR } from "../../../../../utils/Constants";
import { formatEnergy } from "../../../../../utils/functions";
import useChartHeight from "../hook";

const ENUM_ENTRY_RANGE = {
  today: "minutes",
  last24Hours: "minutes",
  last7Days: "days",
  last30Days: "days",
  last12Months: "months",
  "24Hours": "minutes",
  "7D": "days",
  fullMonth: "days",
  fullYear: "months",
};

const EnergyChart = ({
  hours = [],
  firstEnergy = [],
  firstIrradiation = [],
  secondEnergy = [],
  secondIrradiation = [],
  compare = false,
  firstDataLegend = "",
  secondDataLegend = "",
}) => {
  const { t } = useTranslation();

  const data = hours.map((hour, index) => ({
    time: hour.toString().padStart(2, "0"),
    energy: firstEnergy[index] || 0,
    irradiation: firstIrradiation[index] || 0,
    ...(compare && {
      energy2: secondEnergy[index] || 0,
      irradiation2: secondIrradiation[index] || 0,
    }),
  }));

  const minEnergy = 0;
  const maxEnergy = Math.max(...firstEnergy, ...(compare ? secondEnergy : []));
  const minIrradiation = 0;
  const maxIrradiation = Math.max(
    ...firstIrradiation,
    ...(compare ? secondIrradiation : [])
  );

  const { dailyEnergyGenerated, irradiationWm2, energyAccumulated } =
    COLOR_SOLAR;
  const secondaryEnergyColor = "#42A5F5";
  const secondaryIrradiationColor = "#42A5F5";
  const height = useChartHeight();

  return (
    <ResponsiveContainer width="100%" height={height}>
      <ComposedChart data={data}>
        <defs>
          <linearGradient id="colorEnergy" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="5%"
              stopColor={dailyEnergyGenerated}
              stopOpacity={0.6}
            />
            <stop
              offset="95%"
              stopColor={dailyEnergyGenerated}
              stopOpacity={0}
            />
          </linearGradient>
          <linearGradient id="colorIrradiation" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={irradiationWm2} stopOpacity={0.6} />
            <stop offset="95%" stopColor={irradiationWm2} stopOpacity={0} />
          </linearGradient>
          {compare && (
            <>
              <linearGradient id="colorEnergy2" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor={secondaryEnergyColor}
                  stopOpacity={0.6}
                />
                <stop
                  offset="95%"
                  stopColor={secondaryEnergyColor}
                  stopOpacity={0}
                />
              </linearGradient>
              <linearGradient
                id="colorIrradiation2"
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop
                  offset="5%"
                  stopColor={secondaryIrradiationColor}
                  stopOpacity={0.6}
                />
                <stop
                  offset="95%"
                  stopColor={secondaryIrradiationColor}
                  stopOpacity={0}
                />
              </linearGradient>
            </>
          )}
        </defs>

        <CartesianGrid strokeDasharray="3 3" opacity={0.2} />
        <XAxis dataKey="time" />

        <YAxis
          yAxisId="left"
          orientation="left"
          domain={[minEnergy, maxEnergy]}
          tickFormatter={formatEnergy}
          width={50}
          axisLine={false}
          tickLine={false}
        />

        <YAxis
          yAxisId="right"
          orientation="right"
          domain={[minIrradiation, maxIrradiation]}
          tickFormatter={(value) => `${value} W/m²`}
          width={50}
          axisLine={false}
          tickLine={false}
        />
        <Tooltip
          formatter={(value, name) => [
            name.includes(t("solar.energyLabel")) ? formatEnergy(value) : value,
            name.includes(t("solar.energyLabel"))
              ? `${t("solar.energyLabel")}`
              : `${t("solar.irradiation")} (W/m²)`,
          ]}
          labelFormatter={(label) => `${label}`}
          cursor={{ fill: "#E0E0E0", opacity: 0.1 }}
        />

        <Legend verticalAlign="bottom" height={36} />

        <Bar
          yAxisId="left"
          type="monotone"
          dataKey="energy"
          name={`${firstDataLegend} ${t("solar.energyLabel")}`}
          // stroke={energyAccumulated}
          fill={energyAccumulated}
          fillOpacity={0.6}
          // strokeWidth={2}
          dot={false}
        />

        {/* <Line
          yAxisId="right"
          type="monotone"
          dataKey="irradiation"
          name={`${firstDataLegend} ${t("solar.irradiation")}`}
          stroke={irradiationWm2}
          strokeWidth={2}
          dot={false}
        /> */}

        {compare && (
          <>
            <Area
              yAxisId="left"
              type="monotone"
              dataKey="energy2"
              name={`${secondDataLegend} ${t(
                "solar.energyLabel"
              )} - Comparação`}
              stroke={secondaryEnergyColor}
              fill="url(#colorEnergy2)"
              fillOpacity={0.3}
              strokeWidth={2}
              dot={false}
            />

            <Line
              yAxisId="right"
              type="monotone"
              dataKey="irradiation2"
              name={`${secondDataLegend} ${t(
                "solar.irradiation"
              )} - Comparação`}
              stroke={secondaryIrradiationColor}
              strokeOpacity={0.6}
              strokeWidth={2}
              dot={false}
            />
          </>
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

const CurrentDayPower = ({ projectId, monthRef }) => {
  const { t } = useTranslation();
  const [start, setStart] = useState(
    moment(new Date()).startOf("day").toDate()
  );
  const [end, setEnd] = useState(moment(new Date()).endOf("day").toDate());
  const [range, setRange] = useState("24Hours");

  const { data, isFetching } = useQuery({
    queryKey: [
      "solarReadDataChartByTimestamp",
      { projId: projectId, start, end, range, get: ["energy"] },
    ],
    queryFn: () =>
      axios
        .get("v2/solar/readDataChartByTimestamp", {
          params: {
            projId: projectId,
            start,
            end,
            range: ENUM_ENTRY_RANGE[range],
            get: ["energy"],
          },
        })
        .then((res) => res.data),
    staleTime: 1000 * 60 * 60, // 1 hour
    refetchInterval: 1000 * 60 * 60, // 1 hour
  });

  return (
    <>
      <CardHeader
        className="d-flex align-items-center justify-content-between mr-4"
        style={{ height: "fit-content", paddingBottom: 0 }}
      >
        <CardTitle tag="h5">{`${t("solar.energyLabel")}`}</CardTitle>
      </CardHeader>

      <CardBody className="p-0">
        {isFetching ? (
          <div className="text-center">
            <Spinner color="primary" />
          </div>
        ) : (
          <DateRangeSelector
            loading={isFetching}
            initDate={start}
            finishDate={end}
            setEntrieStartDate={setStart}
            setEntrieEndDate={setEnd}
            entrieRange={range}
            setEntrieRange={setRange}
          >
            <EnergyChart hours={data.times} firstEnergy={data.energy} />
          </DateRangeSelector>
        )}
      </CardBody>
    </>
  );
};

export default CurrentDayPower;
