import React from "react";
import { connect } from "react-redux";

import Content from "../components/Content";
import Footer from "../components/Footer";
import Main from "../components/Main";
import { SidebarV2 } from "../components/SidebarV2";
import Wrapper from "../components/Wrapper";
import { ThemeProvider } from "../context/theme";
import { ConfigProvider } from "antd";
import { useTranslation } from "react-i18next";
import enUS from "antd/es/locale/en_US";
import ptBR from "antd/es/locale/pt_BR";
const Dashboard = ({ sidebar, children }) => {
  const { i18n } = useTranslation();
  return (
    <React.Fragment>
      <ConfigProvider locale={i18n.language === "en" ? enUS : ptBR}
         
      >
        <Wrapper>
          <SidebarV2 />
          <Main>
            <Content>{children} </Content>
            <Footer />
          </Main>
        </Wrapper>
      </ConfigProvider>
    </React.Fragment>
  );
};

const Theme = ({ children }) => (
  <ThemeProvider>
    <Dashboard>{children}</Dashboard>
  </ThemeProvider>
);

export default Theme
