// IMPORTS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlug, faPowerOff, faCarBattery, faDatabase, faSignInAlt, faSignOutAlt, faInfo, faBell, faChargingStation } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, CardText, CardTitle, Col, ListGroup, ListGroupItem, Row, Spinner, Badge, Input } from 'reactstrap';
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';
import NoBreakCharts from './NoBreakCharts';
import BatteryChart from './NobreakBatteryChart';
import OutputChart from './NobreakOutputChart';
import { useTranslation } from 'react-i18next';
import DatePicker from "react-datepicker";
import { Search } from "react-feather";

// COMPONENTE DA TABELA DE DISPOSITIVOS
const NoBreakDevices = ({ devices, selectedDevice, setSelectedDevice, }) => {
  const { t } = useTranslation();
  const [searchbar, setSearchbar] = useState("");
  const timestampNow = Math.floor(Date.now() / 1000);
  const [filteredDevices, setFilteredDevices] = useState([]);

  var AlturaTable = (window.innerWidth < 768) ? "340px" :(window.innerWidth < 1290)? "38vh": "40vh"
  useEffect(() => {

    setFilteredDevices(devices.filter(item => {

      return item.description.toLowerCase()
        .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        .includes(searchbar.toLowerCase()
          .normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
    }));
  }, [searchbar, devices]);

  // RETORNA TABELA DE DISPOSITIVOS
  return (<>
    {(devices.length) ?
      <div>
        <CardHeader>
          <CardTitle tag="h1" className="mb-0">
            {t('noBreak.deviceList')}
          </CardTitle>
        </CardHeader>
        <div className="d-flex align-items-center justify-content-start" style={{ paddingBottom: "10px", marginRight: "22px", marginLeft: "12px" }}>
          <Search width={36} height={16} className="text-muted" />
          <Input

            type="text"
            placeholder="Buscar"
            aria-label="Search"
            // className="form-control-lite"
            value={searchbar}
            onChange={e => setSearchbar(e.target.value)}
          />
        </div>

        <ListGroup flush style={{ overflowY: "scroll", height: AlturaTable }} key="id">
          {filteredDevices && filteredDevices.map((element) =>

            <ListGroupItem
              className={"cursor-pointer" + ((selectedDevice === element.id) ? " selected" : "")}
              key={element.id}
              onClick={e => {
                e.preventDefault()
                setSelectedDevice(element.id)
              }}
            >
              <div>
                <div>{element.description}</div>
                <div className='d-flex justify-content-between'>
                  <div
                    className="font-weight-bold"
                    style={{ marginRight: "10px", width: "50%" }}
                  >
                    {element.model}
                  </div>
                  <div style={{ width: "50%" }}>
                    <Badge
                      className="badge-pill mr-1 mb-1"
                      style={{
                        float: "right",
                        backgroundColor: (element.id && timestampNow - element.commInterval < element.lastComm)
                          ? "green"
                          : (element.lastComm == null)
                            ? "red"
                            : (element.id && timestampNow - element.lastComm > element.commInterval)
                              ? "orange"
                              : null,
                      }}
                    >
                      {(element.id && timestampNow - element.commInterval < element.lastComm)
                        ? `${t('home.online')}`
                        : (element.lastComm == null)
                          ? `${t('noBreak.noComm')}`
                          : (element.id && timestampNow - element.lastComm > element.commInterval)
                            ? `${t('home.offline')}`
                            : null
                      }
                    </Badge>
                  </div>
                </div>
              </div>
            </ListGroupItem>
          )}
        </ListGroup>
      </div>
      : (!devices) ?
        <div className="text-center">
          <Spinner color="primary" />
        </div>
        : `${t('dashboards.noDataStats')}`}
  </>)

}

// COMPONENTE INFORMAÇÃO SOBRE DEVICES
const FurtherInformation = ({ parameters, activeTab, selectedDevice, devices }) => {
  const { t } = useTranslation();
  //AJUSTES MOBILE
  let mobileWidth = (window.innerWidth < 768) ? "78vw" : "24vw";
  let zeroMargin = (window.innerWidth < 768) ? "1em" : (window.innerWidth < 1290) ? "1.5em" : "5em";
  let conditionalData = devices.filter((element) => element.lastComm !== null)
  let test = conditionalData.filter(element => element.id === selectedDevice)

  //******************//
  return (<>

    {(activeTab === "noBreak" && test.length) ?
      <div>
        {parameters && parameters.map((device) =>

          (device.length) ?
            (device[0].deviceInfo.id === selectedDevice) ?

              <div
                className={"justify-content-center" + ((selectedDevice === device[0].deviceInfo.id) ? "" : " selected")}
                key={device[0].deviceInfo.id}
                onChange={e => {
                  e.preventDefault()
                }}
              >
                <Row xl={2} md={2} sm={1} xs={1} className="justify-content-center">
                  <Col className="col-auto">
                    <Card className='p-3' style={{ backgroundColor: "#D3D3D3", height: "20vh", width: mobileWidth, marginLeft: zeroMargin }}>
                      <div className='d-flex justify-content-between'>
                        <CardTitle tag="h4">{t('noBreak.inputs')}</CardTitle><FontAwesomeIcon style={{ fontSize: "1.5em" }} fixedWidth icon={faSignInAlt} />
                      </div>
                      <Row className='justify-content-between'>
                        <Col>
                          <CardText>{t('noBreak.tension')}</CardText>
                          <CardText>{t('noBreak.frequency')}</CardText>
                        </Col>
                        <Col className='col-auto'>
                          <CardText>{device[0].data.InputVoltage1} V</CardText>
                          <CardText>{device[0].data.InputFrequency1} Hz</CardText>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col className="col-auto">
                    <Card className='p-3' style={{ backgroundColor: "#D3D3D3", height: "20vh", width: mobileWidth, marginLeft: (window.innerWidth < 768) ? "1em" : "0" }}>
                      <div className='d-flex justify-content-between'>
                        <CardTitle tag="h4">{t('noBreak.outputs')}</CardTitle><FontAwesomeIcon style={{ fontSize: "1.5em" }} fixedWidth icon={faSignOutAlt} />
                      </div>
                      <Row>
                        <Col>
                          <CardText>{t('noBreak.tension')}</CardText>
                          <CardText>{t('noBreak.frequency')}</CardText>
                          <CardText>{t('noBreak.capacity')}</CardText>
                        </Col>
                        <Col className='col-auto'>
                          <CardText>{device[0].data.OutputVoltage1} V</CardText>
                          <CardText>{device[0].data.OutputFrequency} Hz</CardText>
                          <CardText>{device[0].data.OutputPercentLoad1} %</CardText>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>

                <Row xl={2} md={2} sm={1} xs={1} className="justify-content-center">
                  <Col className="col-auto" >
                    <Card className='p-3' style={{ backgroundColor: "#D3D3D3", height: "20vh", width: mobileWidth, marginLeft: zeroMargin }}>
                      <div className='d-flex justify-content-between'>
                        <CardTitle tag="h4">{t('noBreak.battery')}</CardTitle><FontAwesomeIcon style={{ fontSize: "1.5em" }} fixedWidth icon={faCarBattery} />
                      </div>
                      <Row>
                        <Col>
                          <CardText>{t('noBreak.tension')}</CardText>
                          <CardText>{t('noBreak.current')}</CardText>
                          <CardText>{t('noBreak.timeOfAutonomy')}</CardText>
                        </Col>
                        <Col className='col-auto'>
                          <CardText>{device[0].data.BatteryVoltage} V</CardText>
                          <CardText>{device[0].data.BatteryCurrent} A</CardText>
                          <CardText>{device[0].data.EstimatedMinutesRemaining} min</CardText>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col className="col-auto" >
                    <Card className='p-3 ' style={{ backgroundColor: "#D3D3D3", height: "20vh", width: mobileWidth, marginLeft: (window.innerWidth < 768) ? "1em" : "0" }}>
                      <div className='d-flex justify-content-between'>
                        <CardTitle tag="h4">{t('projects.noBreak')}</CardTitle><FontAwesomeIcon style={{ fontSize: "1.5em" }} fixedWidth icon={faInfo} />
                      </div>
                      <Row>
                        <Col>
                          <CardText>{t('noBreak.mode')}</CardText>
                          <CardText>{t('noBreak.temperature')}</CardText>
                          <CardText>{t('noBreak.chargeLeft')}</CardText>
                        </Col>
                        <Col className='col-auto'>
                          <CardText>{(device[0].data.BatteryStatus === 2) ? t('noBreak.normalMode') : t('noBreak.failMode')}</CardText>
                          <CardText>{device[0].data.BatteryTemperature} ºC</CardText>
                          <CardText>{device[0].data.EstimatedChargeRemaining} %</CardText>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </div>
              : null
            : null
        )}
      </div>
      : <div className='text-center mt-4 h1'>{t('dashboards.noDataStats')}</div>
    }
  </>);

}

// TABELA NO BREAK
const NoBreakModule = ({ devices, projectId, activeTab }) => {
  const currentDate = new Date();
  const [selectDate, setSelectDate] = useState(currentDate);

  // Chamadas para mandar parametros para os graficos
  const [graphData, SetGraphData] = useState([])



  const { t } = useTranslation();

  const [selectedDevice, setSelectedDevice] = useState(devices[0].id)
  const [data, setData] = useState([])
  const [totalOfDevices, setTotalOfDevices] = useState()
  const [noBreakParameters, setNoBreakParameters] = useState([])
  const [loadGraph, setLoadGraph] = useState(true)
  let subtraction = devices.filter(item => item.lastComm === null);


  
  //hook para repetiçao de leitura
  useEffect(() => {
    if (loadGraph) {
      axios.get('/graphAndSaveNobreakData', {
        params: {
          projId: projectId,
          monthRef: ((!selectDate) ? new Date() : selectDate),
        }
      })
        .then(res => {
          SetGraphData(res.data.graphData.map((element) => element))
          setLoadGraph(false)
        })
        .catch(err => {
          console.log(err);
        });
    }
    // eslint-disable-next-line
  }, [loadGraph, selectDate])

  // RETORNA TODOS OS DADOS DA TABELA nobreakdata
  useEffect(() => {


    if (activeTab === "noBreak") {

      axios.get('/readNobreakData', {
        params: {
          projId: projectId
        }
      })
        .then(res => {
          setTotalOfDevices(res.data.valueOfReturn[0].length)
          setData(res.data.valueOfReturn[0])
          setNoBreakParameters(res.data.valueOfReturn[0])
          setLoadGraph(false)
        })
        .catch(err => {
          console.log(err);
          setLoadGraph(false)
        })
    }
    // eslint-disable-next-line
  }, [activeTab])
  // Variaveis por modo de operação
  let sourceBypass = data.filter((element) =>
    (element.length && element[0].source === 4) ?
     element.length
      : 0
  );
  let sourceBattery = data.filter((element) =>
  (element.length && element[0].source === 5) ?
   element.length
    : 0
);

  const timestampNow = Math.floor(Date.now());
  const date = new Date(timestampNow)
  let dateFormat = ("0" + date.getDate()).slice(-2)
    + '/' + ("0" + (date.getMonth() + 1)).slice(-2)
    + '/' + date.getFullYear()
    + ' ' + ("0" + date.getHours()).slice(-2)
    + ':' + ("0" + date.getMinutes()).slice(-2)
    + ':' + ("0" + date.getSeconds()).slice(-2);


  // RETORNA COMPONENT PRINCIPAL DA PAGINA
  return (<>
    {/* CONDICIONAL PARA CARREGAMENTO DE DADOS */}
    {(data.length && graphData.length && activeTab === "noBreak") ?
      <>
        {/*DATA E DIA DE PROCESSAMENTO  */}
        <CardHeader
          className="text-center"
          style={{ paddingTop: "0px", paddingBottom: "4px", marginTop: "-10+px", paddingLeft: "5px" }}
        >
          <Row>
            <Col className="col-auto" style={{ paddingLeft: "2px", display: "flex" }}>
              <div style={{ width: "26px", height: "26px" }}>
                <FontAwesomeIcon
                  className="align-center success"
                  icon={faDatabase}
                  fixedWidth
                />

              </div>
              <div style={{ paddingTop: "1px" }}>
                <h5>{t('solar.processed')}: {dateFormat}</h5>
              </div>
            </Col>
          </Row>
        </CardHeader>
        <Row></Row>
        {/*  CARD SUPERIORES */}
        <Row xl="5" lg="4" md="3" sm="2" xs="1" className="d-flex justify-content-lg-around justify-content-md-center flex-row">
          {/* TOTAL DE DISPOSITIVOS */}
          <Col >
            <Card style={{ maxHeight: "117px", height: "117px" }}>
              <CardBody>
                <Row >
                  <Col className="mt-0">
                    <CardTitle tag="h5">{t('noBreak.totalDevices')}</CardTitle>
                  </Col>
                  <Col className="col-auto">
                    <div style={{ width: "26px", height: "26px" }} className="avatar-title rounded-circle bg-primary-dark">
                      <FontAwesomeIcon icon={faPlug} fixedWidth className="align-middle" />
                    </div>
                  </Col>
                </Row>
                <Row className='justify-content-center'>
                  <CardText className="display-5 text-center" tag="h1">
                    {totalOfDevices}
                  </CardText>
                </Row>
              </CardBody>
            </Card>
          </Col>
          {/* EM OPERAÇAO NORMAL */}
          <Col>
            <Card style={{ maxHeight: "117px", height: "117px" }}>
              <CardBody>
                <Row className='justify-content-between'>
                  <Col className='mt-0'>
                    <CardTitle tag="h5">{t('noBreak.normalOperation')}</CardTitle>
                  </Col>
                  <Col className="col-auto">
                    <div style={{ width: "26px", height: "26px" }} className="avatar-title rounded-circle bg-primary-dark">
                      <FontAwesomeIcon icon={faPowerOff} fixedWidth className="align-middle" />
                    </div>

                  </Col>
                </Row>
                <Row className='justify-content-center'>
                  <CardText className="display-5 text-center" tag="h1">
                    {totalOfDevices - subtraction.length}
                  </CardText>
                </Row>
              </CardBody>
            </Card>
          </Col>
          {/* EM FALHA OU BYPASS */}
          <Col>
            <Card style={{ maxHeight: "117px", height: "117px" }}>
              <CardBody>
                <Row>
                  <Col className="mt-0">
                    <CardTitle tag="h5">{t('noBreak.byPassOperation')}</CardTitle>
                  </Col>
                  <Col className="col-auto">
                    <div style={{ width: "26px", height: "26px" }} className="avatar-title rounded-circle bg-primary-dark">
                      <FontAwesomeIcon icon={faChargingStation} fixedWidth className="align-middle" />
                    </div>
                  </Col>
                </Row>
                <Row className='justify-content-center'>
                  <CardText className="display-5 text-center" tag="h1">
                  {sourceBypass.length}
                  </CardText>
                </Row>
              </CardBody>
            </Card>
          </Col>
          {/* EM MODO BATERIA */}
          <Col>
            <Card style={{ maxHeight: "117px", height: "117px" }}>
              <CardBody>
                <Row>
                  <Col className="mt-0">
                    <CardTitle tag="h5">{t('noBreak.batteryOperation')}</CardTitle>
                  </Col>
                  <Col className="col-auto">
                    <div style={{ width: "26px", height: "26px" }} className="avatar-title rounded-circle bg-primary-dark">
                      <FontAwesomeIcon icon={faCarBattery} fixedWidth className="align-middle" />
                    </div>

                  </Col>
                </Row>
                <Row className='justify-content-center'>
                  <CardText className="display-5 text-center" tag="h1">
                    {sourceBattery.length}
                  </CardText>
                </Row>
              </CardBody>
            </Card>
          </Col>
          {/* ALARMES */}
          <Col>
            <Card style={{ maxHeight: "117px", height: "117px" }}>
              <CardBody>
                <Row>
                  <Col className="mt-0">
                    <CardTitle tag="h5">{t('noBreak.alarms')}</CardTitle>
                  </Col>
                  <Col className="col-auto">
                    <div style={{ width: "26px", height: "26px" }} className="avatar-title rounded-circle bg-primary-dark">
                      <FontAwesomeIcon icon={faBell} fixedWidth className="align-middle" />
                    </div>

                  </Col>
                </Row>
                <Row className='justify-content-center'>
                  {data.map((element) =>
                    (element.length && selectedDevice === element[0].deviceInfo.id) ?
                      <CardText style={{
                        float: "right",
                        backgroundColor: (element[0].alarms > 0)
                         ?"red": "none"
                      }} className="display-5 text-center" tag="h1">
                        {element[0].alarms}
                      </CardText>
                      : null
                  )}

                </Row>
              </CardBody>

            </Card>
          </Col>
        </Row>

        <Row xs="11" md={11}>
          <Col sm="3" xs="12" className="pr-0">
            <Card style={{ padding: "1%" }}>
              <NoBreakDevices projId={projectId} activeTab={activeTab} devices={devices} selectedDevice={selectedDevice} setSelectedDevice={setSelectedDevice} />
            </Card>
          </Col>
          <Col sm="9">
            <Carousel
              style={{ height: "52vh" }}
              fade
              interval={null}
              controls="true"
              className="carouselButton">
              <Carousel.Item>
                <Card style={{ overflowY: (window.innerWidth < 768) ? "scroll" : null, height: "50vh", padding: "7px" }}>
                  <div className='d-flex justify-content-between'>
                    <CardTitle className='ml-2 mt-2 h5'>{t('noBreak.deviceInfo')}</CardTitle>
                    <div className='mt-2' style={{ width: "20%" }} >
                      <DatePicker
                        className="form-control cursor-pointer"
                        selected={selectDate}
                        onChange={date => {
                          setSelectDate(date)
                          setLoadGraph(true);
                        }}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        showFullMonthYearPicker
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                  {(loadGraph) ?
                    <div className="text-center">
                      <Spinner color="primary" />
                    </div>
                    : <FurtherInformation parameters={noBreakParameters} activeTab={activeTab} devices={devices} selectedDevice={selectedDevice} setLoadGraph={setLoadGraph} />}
                </Card>
              </Carousel.Item>
              <Carousel.Item>
                <Card style={{ overflow: "hidden", height: "49vh" }}>
                  <div className='d-flex justify-content-between'>
                    <CardTitle className='ml-4 mt-2 h5'>{t('noBreak.inputGraph')}</CardTitle>
                    <div className='mt-2' style={{ width: "20%" }} >
                      <DatePicker
                        className="inputs form-control cursor-pointer"
                        selected={selectDate}
                        onChange={(date) => {
                          setSelectDate(date)
                          setLoadGraph(true);
                        }}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        showFullMonthYearPicker
                        placeholderText={t('solar.selectMonth')}
                        maxDate={currentDate}
                      />
                    </div>
                  </div>
                  {(loadGraph) ?
                    <div className="text-center">
                      <Spinner color="primary" />
                    </div>
                    :
                    <NoBreakCharts graphData={graphData} projId={projectId} selectedDevice={selectedDevice} setLoadGraph={setLoadGraph} />
                  }

                </Card>
              </Carousel.Item>
              <Carousel.Item >
                <Card style={{ overflow: "hidden", height: "49vh" }}>
                  <div className='d-flex justify-content-between'>
                    <CardTitle className='ml-4 mt-2 h5'>{t('noBreak.outputGraph')}</CardTitle>
                    <div className='mt-2' style={{ width: "20%" }} >
                      <DatePicker
                        className="inputs form-control cursor-pointer"
                        selected={selectDate}
                        onChange={(date) => {
                          setSelectDate(date)
                          setLoadGraph(true);
                        }}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        showFullMonthYearPicker
                        placeholderText={t('solar.selectMonth')}
                        maxDate={currentDate}
                      />
                    </div>
                  </div>
                  {(loadGraph) ?
                    <div className="text-center">
                      <Spinner color="primary" />
                    </div>
                    :
                    <OutputChart graphData={graphData} projId={projectId} selectedDevice={selectedDevice} setLoadGraph={setLoadGraph} />}
                </Card>
              </Carousel.Item>
              <Carousel.Item>
                <Card style={{ overflow: "hidden", height: "49vh" }}>
                  <div className='d-flex justify-content-between'>
                    <CardTitle className='ml-4  mt-2 h5'>{t('noBreak.batteryGraph')}</CardTitle>
                    <div className='mt-2' style={{ width: "20%" }} >
                      <DatePicker
                        className="inputs form-control cursor-pointer"
                        selected={selectDate}
                        onChange={(date) => {
                          setSelectDate(date)
                          setLoadGraph(true);
                        }}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        showFullMonthYearPicker
                        placeholderText={t('solar.selectMonth')}
                        maxDate={currentDate}
                      />
                    </div>
                  </div>
                  {(loadGraph) ?
                    <div className="text-center">
                      <Spinner color="primary" />
                    </div>
                    :
                    <BatteryChart graphData={graphData} projId={projectId} selectedDevice={selectedDevice} setLoadGraph={setLoadGraph} />}
                </Card>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </>
      : (!data.length) ?
        <Card>
          <CardBody>
            <div className="text-center p-3"><Spinner color="primary" /></div>
          </CardBody>
        </Card>
        : <p style={{ fontSize: "2em", textAlign: "center", marginTop: "2em" }}>{t('dashboards.noDataStats')}</p>}
  </>)

}

export default NoBreakModule