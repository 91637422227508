import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import { usePlantContext } from "../PlantProvider";
import { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useTheme } from "./../../../../../context/theme";

const DoughnutChartDevicesStatus = ({ theme }) => {
  const { deviceStatus } = usePlantContext();
  const { t } = useTranslation();
  const { colors } = useTheme();

  const [showDevices, setShowDevices] = useState([]);
  const [showLabel, setShowLabel] = useState("");

  const toggleDevices = (select, label) => {
    setShowLabel(label);
    setShowDevices(select);
  };

  const data = {
    labels: [
      t("homePlant.deviceWithoutAlarm"),
      t("homePlant.deviceWithAlarm"),
      t("homePlant.deviceNeverCommunicated"),
    ],
    datasets: [
      {
        data: [
          deviceStatus?.deviceWithoutAlarm?.length,
          deviceStatus?.deviceWithAlarm?.length,
          deviceStatus?.deviceNeverCommunicated?.length,
        ],
        devices: [
          deviceStatus?.deviceWithoutAlarm,
          deviceStatus?.deviceWithAlarm,
          deviceStatus?.deviceNeverCommunicated,
        ],
        backgroundColor: [
          colors["--primary-color"],
          colors["--warning-color"],
          colors["--secondary-color"],
        ],
        borderColor: "transparent",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cutoutPercentage: 65,
    plugins: {
      doughnutlabel: {
        labels: [
          {
            text: data.datasets[0].data.reduce((a, b) => a + b, 0),
            font: {
              size: "25",
            },
            color: colors["--text-color"]

          },
          {
            text: "Total",
            font: {
              size: "20",
            },
          },
        ],
      },
    },
    legend: {
      position: "bottom",
      display: false,
    },
  };

  const cols = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      hidden: true,
    },
    {
      dataField: "description",
      text: t("homePlant.description"),
      sort: true,
    },
    {
      dataField: "lastComm",
      text: t("public.lastComm"),
      sort: true,
      formatter: (cell) => {
        if (cell) {
          const cellDate = new Date(cell * 1000);

          return (
            cellDate.getDate().toString().padStart(2, "0") +
            "/" +
            (cellDate.getMonth() + 1).toString().padStart(2, "0") +
            "/" +
            cellDate.getFullYear().toString().padStart(2, "0") +
            " " +
            cellDate.getHours().toString().padStart(2, "0") +
            ":" +
            cellDate.getMinutes().toString().padStart(2, "0") +
            ":" +
            cellDate.getSeconds().toString().padStart(2, "0")
          );
        } else return "-";
      },
    },
  ];

  return (
    <div
      className="chart chart-xs"
      style={{ height: "120px", minHeight: "100px", marginBottom: "18px" }}
    >
      <Doughnut data={data} options={options} />
      <Table borderless className="mt-2" style={{ width: "100%" }}>
        <tbody>
          {/* NOTE: Legenda do dashboard */}
          {data.labels.map((label, index) => {
            return (
              <tr
                key={index}
                className="cursor-pointer"
                style={{ padding: "2px", borderTop: "1px solid #dee2e6" }}
                onClick={() =>
                  toggleDevices(data.datasets[0].devices[index], label)
                }
              >
                <td style={{ padding: "5px 0" }}>
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{
                      color: data.datasets[0].backgroundColor[index],
                    }}
                  />
                </td>
                <td style={{ padding: "5px 0" }}>{label}</td>
                <td style={{ padding: "5px 0" }}>
                  {data.datasets[0].data[index]}
                </td>
              </tr>
            );
          })}
          {}
        </tbody>
      </Table>
      {/*NOTE: Modal para lista de dispositivos na legenda*/}
      {Boolean(showDevices.length) && (
        <Modal
          isOpen={Boolean(showDevices.length)}
          toggle={() => toggleDevices([])}
        >
          <ModalHeader>{showLabel}</ModalHeader>
          <ModalBody>
            <BootstrapTable
              keyField="id"
              data={showDevices}
              columns={cols}
              striped
              hover
              condensed
              noDataIndication={t("homePlant.none")}
            />
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default DoughnutChartDevicesStatus;
