import { Checkbox, Form, Input, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  WhatsAppOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { usePlantContext } from "../../../PlantProvider";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { useQueries, useQuery } from "@tanstack/react-query";

const { Option } = Select;

const ModalAlarm = ({ onClose }) => {
  const { t } = useTranslation();
  const { projectId } = usePlantContext();
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [
    { data: users, isLoading: usersLoading },
    { data: savedConfig, isLoading: configLoading },
  ] = useQueries({
    queries: [
      {
        queryKey: ["usersByProject", projectId],
        queryFn: async () => {
          const response = await axios.get("/v2/user/listUsersByProject", {
            params: { projectId },
          });
          return response.data;
        },
      },
      {
        queryKey: ["savedConfig", projectId],
        queryFn: async () => {
          const response = await axios.get(
            "/v2/energy/readAlarmEnergyExceed",
            {
              params: { projectId },
            }
          );
          return response.data;
        },
      },
    ],
  });

  const loading = usersLoading || configLoading;

  useEffect(() => {
    if (savedConfig) {
      form.setFieldsValue({
        realTimeAlerts: savedConfig.realTimeAlerts,
        dailySummary: savedConfig.dailySummary,
        users: JSON.parse(savedConfig.users),
        notificationTypes: savedConfig.notificationTypes,
      });
    }
  }, [savedConfig, form]);

  const onFinish = async (values) => {
    // ... (código anterior do onFinish - adapte para incluir users e notificationTypes)
    console.log(values);
    setSubmitting(true);
    try {
      // Simulate a fake request with a timeout
      const dataToSubmit = { ...values, projectId };
      const response = await axios.post(
        "/v2/energy/addEnergyExceedAlarm",
        dataToSubmit
      );
      console.log("Submitted data:", dataToSubmit);
      toastr.success("Success", "Data submitted successfully");
      // Handle success (e.g., show a success message, close the modal, etc.)
    } catch (error) {
      console.error("Error submitting data:", error);
      // Handle error (e.g., show an error message)
      toastr.error("Error", "Error submitting");
    } finally {
      setSubmitting(false);
    }
  };

  if (loading && (!users || !savedConfig)) return (
    <Modal isOpen toggle={onClose} size="lg">
      <ModalHeader>{t("params.alarms")}</ModalHeader>
      <ModalBody>Loading...</ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          {t("params.close")}
        </Button>
      </ModalFooter>
    </Modal>
  )

  return (
    <Modal isOpen toggle={onClose} size="lg">
      <ModalHeader>{t("params.alarms")}</ModalHeader>
      <ModalBody>
        <Form
          form={form}
          name="alarm-settings"
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            label={t("params.alarm.alarm-settings")}
            className="mb-0 pb-0"
          >
            <Form.Item
              name="realTimeAlerts"
              valuePropName="checked"
              className="mb-0 pb-0"
            >
              <Checkbox>{t("params.alarm.real-time-alerts")}</Checkbox>
            </Form.Item>
            <Form.Item
              name="dailySummary"
              valuePropName="checked"
              className="mb-0 pb-0"
            >
              <Checkbox>{t("params.alarm.daily-summary")}</Checkbox>
            </Form.Item>
          </Form.Item>

          <Form.Item
            label={t("params.alarm.users")}
            className="mb-0 pb-0"
            name="users"
          >
            <Select
              mode="multiple"
              placeholder="Selecione os usuários"
              options={users?.map((user) => ({
                label: user.name,
                value: user.id,
              }))} // Adapte para seus dados de usuário
              loading={loading}
            />
          </Form.Item>

          <Form.Item
            label={t("params.alarm.notifications")}
            className="mb-0 pb-0"
          >
            <Form.Item
              name="notificationTypes"
              valuePropName="checked"
              noStyle
              className="mb-0 pb-0"
            >
              <Checkbox.Group>
                <Checkbox value="whatsapp" style={{ lineHeight: "32px" }}>
                  <WhatsAppOutlined /> {t("params.alarm.whatsapp")}
                </Checkbox>
                <Checkbox value="email" style={{ lineHeight: "32px" }}>
                  <MailOutlined /> {t("params.alarm.email")}
                </Checkbox>
                <Checkbox value="phone" style={{ lineHeight: "32px" }}>
                  <PhoneOutlined /> {t("params.alarm.phone")}
                </Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Form.Item>

          <Form.Item className="mb-0 pb-0">
            <Button type="primary" htmlType="submit" loading={submitting}>
              {t("params.alarm.save-settings")}
            </Button>
          </Form.Item>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          {t("params.close")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalAlarm;
