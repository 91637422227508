const enUsGlobalTranslations = {
    home: {
        welcome: "Welcome",
        connectivity: "Connectivity",
        online: "Online",
        offline: "Offline",
        noComm: "Never had communicated",
        onlineTitle: "Online Devices",
        offlineTitle: "Offline Devices",
        noCommTitle: "Devices that had never communicated",
        description: "Reference",
        project: "Project",
        lastComm: "Last communication",
        search: "Search",
        company: "Company",
        map: "Map",
        latestOccurrences: "Latest Occurrences",
        typesOfAlarms: "Alarms Types",
        high: "High",
        average: "Moderate",
        low: "Low",
        notOccurrences: "There are no occurrences of alarms.",
        powerModuleOn: "Power Module",
        solarModuleOn: "Solar Module",
        waterModuleOn: "Water Module",
        gasModuleOn: "Gas Module",
    },
}

export default enUsGlobalTranslations;