import { Button, Checkbox, Form, Image, Input, message, Space } from "antd";
import { toastr } from "react-redux-toastr";
import React, { useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { useTheme } from "../../../context/theme";
import axios from "axios";
import { useTranslation } from "react-i18next";
const ThemeForm = ({closeModal}) => {
  const {t} = useTranslation();
  const { colors, updateUserColors, handleResetTheme } = useTheme();

  const { handleSubmit, control, reset } = useForm({
    defaultValues: colors, // Set default values from context
  });
  const [defaultColorHeaderText, setDefaultColorHeaderText] = useState(() => {
    return colors.defaultColorHeaderText || false;
  });
  const [previewImage, setPreviewImage] = useState(() => {
    if (colors["--bg-image"] === "url('')") {
      return null;
    }
    if (
      colors["--bg-image"] &&
      colors["--bg-image"].startsWith("url('") &&
      colors["--bg-image"].endsWith("')")
    ) {
      return colors["--bg-image"].slice(5, -2);
    }
  });
  const fileInputRef = useRef(null); // Ref for the file input

  const onSubmit = async (data) => {
    let newTheme = { ...data, defaultColorHeaderText: defaultColorHeaderText };
    const allowedKeys = [
      "--primary-color",
      "--secondary-color",
      "--header-text-color",
      "--bg-image",
      "defaultColorHeaderText",
    ];
    // Filter out any keys that are not allowed
    Object.keys(newTheme).forEach((key) => {
      if (!allowedKeys.includes(key)) {
        delete newTheme[key];
      }
    });

    if (data["--bg-image"] instanceof File) {
      // Await the base64 conversion:
      newTheme["--bg-image"] = await getBase64(data["--bg-image"]);
    }
    if(typeof data['--bg-image'] === 'string' &&data['--bg-image'] && data['--bg-image'].startsWith('url(') && data['--bg-image'].endsWith(')')) {
      newTheme['--bg-image'] = data['--bg-image'].slice(5, -2);
    }

    try {
      await axios.post("/updateUserTheme", { themeColors: newTheme });
      updateUserColors(newTheme); // Update the context with the new theme
      toastr.success(t('theme.saveSuccess')); // Show success message after successful API call
    } catch (error) {
      console.error("Erro ao salvar o tema:", error); // Log the error for debugging
      toastr.error(t('theme.saveError')); // Show error message after failed API call
    }
  };

  // Modify getBase64 to return a Promise
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleReset = () => {
    reset(colors); // Reset form values to initial colors
    setPreviewImage(colors["--bg-image"]); // Reset preview image
    setDefaultColorHeaderText(colors.defaultColorHeaderText || false);

    // Manually reset the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    handleResetTheme(); // Reset theme in context
    closeModal?.();
  };

  const urlPreview = useMemo(() => {
    if (previewImage?.startsWith("url(")) {
      return previewImage.slice(5, -2);
    }
    return previewImage;
  }, [previewImage]);

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5" className="mb-0">
         {t("theme.themeColors")}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Form name="theme-form" layout="vertical">
          <Form.Item label={t('theme.primaryColor')} name="--primary-color">
            <Controller
              name="--primary-color"
              control={control}
              defaultValue={colors["--primary-color"]}
              render={({ field }) => (
                <Space.Compact>
                  <Input
                    type="color"
                    style={{ width: 60 }}
                    value={field.value}
                    onChange={field.onChange}
                  />
                  <Input value={field.value} onChange={field.onChange} />
                </Space.Compact>
              )}
            />
          </Form.Item>
          <Form.Item label={t('theme.secondaryColor')} name="--secondary-color">
            <Controller
              name="--secondary-color"
              control={control}
              defaultValue={colors["--secondary-color"]}
              render={({ field }) => (
                <Space.Compact>
                  <Input
                    type="color"
                    style={{ width: 60 }}
                    value={field.value}
                    onChange={field.onChange}
                  />
                  <Input value={field.value} onChange={field.onChange} />
                </Space.Compact>
              )}
            />
          </Form.Item>
          <Form.Item
            label={t('theme.headerTextColor')}
            name="--header-text-color"
          >
            <Controller
              name="--header-text-color"
              control={control}
              defaultValue={colors["--header-text-color"]}
              render={({ field }) => (
                <div className="d-flex flex-column">
                  <Checkbox
                    checked={defaultColorHeaderText}
                    onChange={(e) => {
                      setDefaultColorHeaderText(e.target.checked);
                      field.onChange("");
                    }}
                  >
                    Cor padrão
                  </Checkbox>
                  <Space.Compact>
                    <Input
                      type="color"
                      style={{ width: 60 }}
                      value={field.value}
                      onChange={field.onChange}
                      ref={fileInputRef} // Assign the ref to the input
                      disabled={defaultColorHeaderText}
                      hidden={defaultColorHeaderText}
                    />
                    <Input
                      value={field.value}
                      onChange={field.onChange}
                      disabled={defaultColorHeaderText}
                      hidden={defaultColorHeaderText}
                    />
                  </Space.Compact>
                </div>
              )}
            />
          </Form.Item>
          <Form.Item label={t('theme.backgroundImage')} name="--bg-image">
            {previewImage ? (
              <Space.Compact
                className="d-flex flex-column align-items-center "
                style={{ gap: 8, width: 200 }}
              >
                <Image
                  src={urlPreview}
                  alt="Preview"
                  style={{ width: 200, height: 200 }}
                />
                <Button
                  onClick={() => {
                    setPreviewImage(null);
                    reset({ "--bg-image": "" });
                  }}
                  type="danger"
                >
                  {t('theme.remove')}
                </Button>
              </Space.Compact>
            ) : (
              <Controller
                name="--bg-image"
                control={control}
                render={({ field }) => (
                  <Input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef} // Assign the ref to the input
                    onChange={async (e) => {
                      field.onChange(e.target.files[0]);
                      if (e.target.files[0]) {
                        if (e.target.files[0].size > 1024 * 1024) {
                          message.error(t('theme.maxFileSize'));
                          setPreviewImage(null);
                          fileInputRef.current.value = "";
                          field.onChange(null);
                          return;
                        }
                        const url = await getBase64(e.target.files[0]);
                        //limit to 1mb
                        setPreviewImage(url);
                      }
                    }}
                  />
                )}
              />
            )}
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              onClick={handleSubmit(onSubmit)}
            >
              {t('theme.saveTheme')}
            </Button>
            <Button
              htmlType="button"
              onClick={handleReset} // Call handleReset
              style={{ marginLeft: "8px" }}
            >
              {t('theme.reset')}
            </Button>
          </Form.Item>
        </Form>
      </CardBody>
    </Card>
  );
};

export default ThemeForm;
