import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Box } from "./Box";
import { Container } from "./Container";
import { Line } from "./Line";
import { LineIcon } from "./LineIcon";
import { LineText } from "./LineText";
import { SpanText } from "./SpanText";

export const ChildrenRoutes = ({ route, open, moreMarginLeft = false }) => {
  const { t } = useTranslation();
  const [openChildrens, setOpenChildrens] = useState(false);

  if (!route) return <></>;
  return (
    <>
      <div onClick={() => setOpenChildrens(!openChildrens)}>
        <Link
          to={route.pathRoute}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Container open={open} moreMarginLeft={moreMarginLeft}>
            <Box open={open}>
              <Line open={open}>
                <LineIcon>
                  <FontAwesomeIcon
                    style={{ color: "var(--primary-color)" }}
                    icon={route.icon}
                  />
                </LineIcon>
                <LineText open={open}>
                  <SpanText text={t(`sidebar.${route.name}`)} />
                  {route.children?.length && (
                    <FontAwesomeIcon
                      style={{ color: "var(--primary-color)" }}
                      icon={faChevronDown}
                      size={"xs"}
                    />
                  )}
                </LineText>
              </Line>
            </Box>
          </Container>
        </Link>
      </div>
      {openChildrens ? (
        route?.children?.map((child, index) => {
          return (
            <React.Fragment key={child.name + index}>
              <ChildrenRoutes route={child} open={open} moreMarginLeft={true} />
            </React.Fragment>
          );
        })
      ) : (
        <></>
      )}
    </>
  );
};
