import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  Col,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";

import { useTranslation } from "react-i18next";
import SolarModuleWithProvider from "../../../projects/solarModule";
import ClimateModule from "../../climateModule/climateModule";
import { EnergyModuleWithProvider } from "../../../../igData/projects/energyModule/";
import NoBreakModule from "../../nobreakModule/NoBreakModule";
import { WaterModuleWithProvider } from "../../waterModule/Plant";
import { DevicesCards } from "./components/DevicesCards";
import { GasModuleWithProvider } from "../../gasModule/Plant";

export const CardWithTabs = connect((store) => ({
  theme: store.theme,
}))(
  ({
    loadingDevices,
    devices,
    searchbar,
    isCardLoading,
    projectId,
    activeTab,
    handleFilterDevices,
    setFilteredDevices,
    filteredDevices,
  }) => {
    const { t } = useTranslation();
    const [filteredDevs, setFilteredDevs] = useState([]);
    const [solarFilter, setSolarFilter] = useState(false);
    const [trackerFilter, setTrackFilter] = useState(false);
    const [solarimeterFilter, setSolarimeterFilter] = useState(false);
    const [nobreakFilter, setNobreakFilter] = useState(false);
    const [climateFilter, setClimateFilter] = useState(false);
    const [utilitiesFilter, setUtilitiesFilter] = useState(false);
    const [gatewayFilter, setGatewayFilter] = useState(false);
    const [mobileWidth, setMobileWidth] = useState(0);

    console.log('activeTab', activeTab);

    useEffect(() => {
      setFilteredDevs(
        devices.filter((device) => {
          return (
            device.description
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes(searchbar) ||
            device.model
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes(searchbar)
          );
        })
      );
    }, [searchbar, devices]);

    useEffect(() => {
      function handleResize() {
        setMobileWidth(window.innerWidth);
      }

      window.addEventListener("resize", handleResize);

      handleResize();

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [setMobileWidth]);

    function toggleSolar() {
      setSolarFilter(!solarFilter);
      setTrackFilter(false);
      setSolarimeterFilter(false);
      setNobreakFilter(false);
      setClimateFilter(false);
      setUtilitiesFilter(false);
      setGatewayFilter(false);
      setFilteredDevices(false);
    }
    function toggleTracker() {
      setTrackFilter(!trackerFilter);
      setSolarFilter(false);
      setSolarimeterFilter(false);
      setNobreakFilter(false);
      setClimateFilter(false);
      setUtilitiesFilter(false);
      setGatewayFilter(false);
      setFilteredDevices(false);
    }
    function toggleSolarimeter() {
      setSolarimeterFilter(!solarimeterFilter);
      setSolarFilter(false);
      setTrackFilter(false);
      setNobreakFilter(false);
      setClimateFilter(false);
      setUtilitiesFilter(false);
      setGatewayFilter(false);
      setFilteredDevices(false);
    }
    function toggleNobreak() {
      setNobreakFilter(!nobreakFilter);
      setTrackFilter(false);
      setSolarimeterFilter(false);
      setSolarFilter(false);
      setClimateFilter(false);
      setUtilitiesFilter(false);
      setGatewayFilter(false);
      setFilteredDevices(false);
    }
    function toggleClimate() {
      setClimateFilter(!climateFilter);
      setTrackFilter(false);
      setSolarimeterFilter(false);
      setNobreakFilter(false);
      setUtilitiesFilter(false);
      setGatewayFilter(false);
      setSolarFilter(false);
      setFilteredDevices(false);
    }
    function toggleUtils() {
      setUtilitiesFilter(!utilitiesFilter);
      setTrackFilter(false);
      setSolarimeterFilter(false);
      setClimateFilter(false);
      setNobreakFilter(false);
      setGatewayFilter(false);
      setSolarFilter(false);
      setFilteredDevices(false);
    }
    function toggleGateway() {
      setGatewayFilter(!gatewayFilter);
      setTrackFilter(false);
      setSolarimeterFilter(false);
      setClimateFilter(false);
      setNobreakFilter(false);
      setUtilitiesFilter(false);
      setSolarFilter(false);
      setFilteredDevices(false);
    }

    return (
      <Card className="devices-card" style={{
        background: "transparent",
        boxShadow: "none",
      }}>

        <Row className="m-0">
          {activeTab === "default" && mobileWidth > 1250 ? (
            <Card
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "28px",
                marginRight: "12px",
                minWidth: "9rem",
                height: "100%",
              }}
              md={activeTab === "default" ? 12 : 12}
            >
              <ListGroup flush style={{ overflowY: "scroll", height: "100%" }}>
                <Label className="text-center p-2">{t("devices.filter")}</Label>
                <ListGroupItem
                  role={"button"}
                  style={{
                    backgroundColor: solarFilter === true ? "#e9ecef" : "",
                  }}
                  disabled={
                    (!filteredDevices.length && trackerFilter) ||
                    (!filteredDevices.length && solarimeterFilter) ||
                    (!filteredDevices.length && nobreakFilter) ||
                    (!filteredDevices.length && climateFilter) ||
                    (!filteredDevices.length && utilitiesFilter) ||
                    (!filteredDevices.length && gatewayFilter)
                      ? true
                      : false
                  }
                  onClick={toggleSolar}
                >
                  <Label>{t("devices.inverter")}</Label>
                </ListGroupItem>
                <ListGroupItem
                  role={"button"}
                  style={{
                    backgroundColor: trackerFilter === true ? "#e9ecef" : "",
                  }}
                  disabled={
                    (!filteredDevices.length && solarFilter) ||
                    (!filteredDevices.length && solarimeterFilter) ||
                    (!filteredDevices.length && nobreakFilter) ||
                    (!filteredDevices.length && climateFilter) ||
                    (!filteredDevices.length && utilitiesFilter) ||
                    (!filteredDevices.length && gatewayFilter)
                      ? true
                      : false
                  }
                  onClick={toggleTracker}
                >
                  <Label>{t("devices.tracker")}</Label>
                </ListGroupItem>
                <ListGroupItem
                  role={"button"}
                  style={{
                    backgroundColor:
                      solarimeterFilter === true ? "#e9ecef" : "",
                  }}
                  disabled={
                    (!filteredDevices.length && solarFilter) ||
                    (!filteredDevices.length && trackerFilter) ||
                    (!filteredDevices.length && nobreakFilter) ||
                    (!filteredDevices.length && climateFilter) ||
                    (!filteredDevices.length && utilitiesFilter) ||
                    (!filteredDevices.length && gatewayFilter)
                      ? true
                      : false
                  }
                  onClick={toggleSolarimeter}
                >
                  <Label>{t("devices.solarimeter")}</Label>
                </ListGroupItem>
                <ListGroupItem
                  role={"button"}
                  style={{
                    backgroundColor: nobreakFilter === true ? "#e9ecef" : "",
                  }}
                  disabled={
                    (!filteredDevices.length && solarFilter) ||
                    (!filteredDevices.length && trackerFilter) ||
                    (!filteredDevices.length && solarimeterFilter) ||
                    (!filteredDevices.length && climateFilter) ||
                    (!filteredDevices.length && utilitiesFilter) ||
                    (!filteredDevices.length && gatewayFilter)
                      ? true
                      : false
                  }
                  onClick={toggleNobreak}
                >
                  <Label>{t("devices.nobreak")}</Label>
                </ListGroupItem>
                <ListGroupItem
                  role={"button"}
                  style={{
                    backgroundColor: climateFilter === true ? "#e9ecef" : "",
                  }}
                  disabled={
                    (!filteredDevices.length && solarFilter) ||
                    (!filteredDevices.length && trackerFilter) ||
                    (!filteredDevices.length && solarimeterFilter) ||
                    (!filteredDevices.length && nobreakFilter) ||
                    (!filteredDevices.length && utilitiesFilter) ||
                    (!filteredDevices.length && gatewayFilter)
                      ? true
                      : false
                  }
                  onClick={toggleClimate}
                >
                  <Label>{t("devices.climate")}</Label>
                </ListGroupItem>
                <ListGroupItem
                  role={"button"}
                  style={{
                    backgroundColor: utilitiesFilter === true ? "#e9ecef" : "",
                  }}
                  disabled={
                    (!filteredDevices.length && solarFilter) ||
                    (!filteredDevices.length && trackerFilter) ||
                    (!filteredDevices.length && solarimeterFilter) ||
                    (!filteredDevices.length && nobreakFilter) ||
                    (!filteredDevices.length && climateFilter) ||
                    (!filteredDevices.length && gatewayFilter)
                      ? true
                      : false
                  }
                  onClick={toggleUtils}
                >
                  <Label>{t("devices.utils")}</Label>
                </ListGroupItem>
                <ListGroupItem
                  role={"button"}
                  style={{
                    backgroundColor: gatewayFilter === true ? "#e9ecef" : "",
                  }}
                  disabled={
                    (!filteredDevices.length && solarFilter) ||
                    (!filteredDevices.length && trackerFilter) ||
                    (!filteredDevices.length && solarimeterFilter) ||
                    (!filteredDevices.length && nobreakFilter) ||
                    (!filteredDevices.length && climateFilter) ||
                    (!filteredDevices.length && utilitiesFilter)
                      ? true
                      : false
                  }
                  onClick={toggleGateway}
                >
                  <Label>{t("devices.gateway")}</Label>
                </ListGroupItem>
              </ListGroup>
            </Card>
          ) : activeTab === "default" && mobileWidth <= 1250 ? (
            <Row
              style={{
                display: "flex",
                margin: "auto",
              }}
            >
              <Card
                style={{
                  marginTop: "28px",
                  padding: ".5rem",
                }}
              >
                <ListGroup
                  horizontal
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Col className="text-center" xs={12}>
                    <Label className="text-center p-2">Filtrar</Label>
                  </Col>

                  <ListGroupItem
                    role={"button"}
                    style={{
                      backgroundColor: solarFilter === true ? "#e9ecef" : "",
                    }}
                    disabled={
                      (!filteredDevices.length && trackerFilter) ||
                      (!filteredDevices.length && solarimeterFilter) ||
                      (!filteredDevices.length && nobreakFilter) ||
                      (!filteredDevices.length && climateFilter) ||
                      (!filteredDevices.length && utilitiesFilter) ||
                      (!filteredDevices.length && gatewayFilter)
                        ? true
                        : false
                    }
                    onClick={toggleSolar}
                  >
                    <Label>{t("devices.inverter")}</Label>
                  </ListGroupItem>
                  <ListGroupItem
                    role={"button"}
                    style={{
                      backgroundColor: trackerFilter === true ? "#e9ecef" : "",
                    }}
                    disabled={
                      (!filteredDevices.length && solarFilter) ||
                      (!filteredDevices.length && solarimeterFilter) ||
                      (!filteredDevices.length && nobreakFilter) ||
                      (!filteredDevices.length && climateFilter) ||
                      (!filteredDevices.length && utilitiesFilter) ||
                      (!filteredDevices.length && gatewayFilter)
                        ? true
                        : false
                    }
                    onClick={toggleTracker}
                  >
                    <Label>{t("devices.tracker")}</Label>
                  </ListGroupItem>
                  <ListGroupItem
                    role={"button"}
                    style={{
                      backgroundColor:
                        solarimeterFilter === true ? "#e9ecef" : "",
                    }}
                    disabled={
                      (!filteredDevices.length && solarFilter) ||
                      (!filteredDevices.length && trackerFilter) ||
                      (!filteredDevices.length && nobreakFilter) ||
                      (!filteredDevices.length && climateFilter) ||
                      (!filteredDevices.length && utilitiesFilter) ||
                      (!filteredDevices.length && gatewayFilter)
                        ? true
                        : false
                    }
                    onClick={toggleSolarimeter}
                  >
                    <Label>{t("devices.solarimeter")}</Label>
                  </ListGroupItem>
                  <ListGroupItem
                    role={"button"}
                    style={{
                      backgroundColor: nobreakFilter === true ? "#e9ecef" : "",
                    }}
                    disabled={
                      (!filteredDevices.length && solarFilter) ||
                      (!filteredDevices.length && trackerFilter) ||
                      (!filteredDevices.length && solarimeterFilter) ||
                      (!filteredDevices.length && climateFilter) ||
                      (!filteredDevices.length && utilitiesFilter) ||
                      (!filteredDevices.length && gatewayFilter)
                        ? true
                        : false
                    }
                    onClick={toggleNobreak}
                  >
                    <Label>{t("devices.nobreak")}</Label>
                  </ListGroupItem>
                  <ListGroupItem
                    role={"button"}
                    style={{
                      backgroundColor: climateFilter === true ? "#e9ecef" : "",
                    }}
                    disabled={
                      (!filteredDevices.length && solarFilter) ||
                      (!filteredDevices.length && trackerFilter) ||
                      (!filteredDevices.length && solarimeterFilter) ||
                      (!filteredDevices.length && nobreakFilter) ||
                      (!filteredDevices.length && utilitiesFilter) ||
                      (!filteredDevices.length && gatewayFilter)
                        ? true
                        : false
                    }
                    onClick={toggleClimate}
                  >
                    <Label>{t("devices.climate")}</Label>
                  </ListGroupItem>
                  <ListGroupItem
                    role={"button"}
                    style={{
                      backgroundColor:
                        utilitiesFilter === true ? "#e9ecef" : "",
                    }}
                    disabled={
                      (!filteredDevices.length && solarFilter) ||
                      (!filteredDevices.length && trackerFilter) ||
                      (!filteredDevices.length && solarimeterFilter) ||
                      (!filteredDevices.length && nobreakFilter) ||
                      (!filteredDevices.length && climateFilter) ||
                      (!filteredDevices.length && gatewayFilter)
                        ? true
                        : false
                    }
                    onClick={toggleUtils}
                  >
                    <Label>{t("devices.utils")}</Label>
                  </ListGroupItem>
                  <ListGroupItem
                    role={"button"}
                    style={{
                      backgroundColor: gatewayFilter === true ? "#e9ecef" : "",
                    }}
                    disabled={
                      (!filteredDevices.length && solarFilter) ||
                      (!filteredDevices.length && trackerFilter) ||
                      (!filteredDevices.length && solarimeterFilter) ||
                      (!filteredDevices.length && nobreakFilter) ||
                      (!filteredDevices.length && climateFilter) ||
                      (!filteredDevices.length && utilitiesFilter)
                        ? true
                        : false
                    }
                    onClick={toggleGateway}
                  >
                    <Label>{t("devices.gateway")}</Label>
                  </ListGroupItem>
                </ListGroup>
              </Card>
            </Row>
          ) : null}
          <Col
            md={
              activeTab === "default" && mobileWidth > 1250
                ? 10
                : activeTab === "default" && mobileWidth <= 1250
                ? 12
                : 12
            }
          >
            <CardBody
              className="devices-card-body"
              style={{ paddingLeft: "0px", paddingRight: "0px", background: "transparent" }}
            >
              {/* padrão (dispositivos) */}
              <TabContent activeTab={activeTab}>
                <TabPane tabId="default">
                  {/* Exibe spinner enquanto não retorna os dispositivos */}
                  {isCardLoading ? (
                    <div>
                      <Spinner color="primary" />
                    </div>
                  ) : activeTab === "default" ? (
                    <DevicesCards
                      setFilteredDevices={setFilteredDevices}
                      loadingDevices={loadingDevices}
                      handleFilterDevices={handleFilterDevices}
                      filteredDevices={filteredDevices}
                      solarFilter={solarFilter}
                      trackerFilter={trackerFilter}
                      solarimeterFilter={solarimeterFilter}
                      setSolarFilter={setSolarFilter}
                      nobreakFilter={nobreakFilter}
                      setNobreakFilter={setNobreakFilter}
                      climateFilter={climateFilter}
                      setClimateFilter={setClimateFilter}
                      utilitiesFilter={utilitiesFilter}
                      setUtilitiesFilter={setUtilitiesFilter}
                      gatewayFilter={gatewayFilter}
                      setGatewayFilter={setGatewayFilter}
                      devices={filteredDevs}
                      activeTab={activeTab}
                    />
                  ) : null}
                </TabPane>
              </TabContent>

              {/* solar */}
              <TabContent activeTab={activeTab}>
                <TabPane tabId="solar">
                  {isCardLoading ? (
                    <div>
                      <Spinner color="primary" />
                    </div>
                  ) : activeTab === "solar" ? (
                    <SolarModuleWithProvider
                      devices={filteredDevs}
                      projectId={projectId}
                      activeTab={activeTab}
                    />
                  ) : null}
                </TabPane>
              </TabContent>

              {/* NOBREAK */}

              <TabContent activeTab={activeTab}>
                <TabPane tabId="noBreak">
                  {filteredDevs.length ? (
                    <NoBreakModule
                      devices={filteredDevs}
                      projectId={projectId}
                      activeTab={activeTab}
                    />
                  ) : null}
                </TabPane>
              </TabContent>

              {/* clima */}
              <TabContent activeTab={activeTab}>
                <TabPane tabId="climate">
                  {isCardLoading ? (
                    <div>
                      <Spinner color="primary" />
                    </div>
                  ) : activeTab === "climate" ? (
                    <ClimateModule
                      devicesSearch={filteredDevs}
                      projectId={projectId}
                      activeTab={activeTab}
                    />
                  ) : null}
                </TabPane>
              </TabContent>

              {/* energia */}
              <TabContent activeTab={activeTab}>
                <TabPane tabId="energy">
                  {isCardLoading ? (
                    <div>
                      <Spinner color="primary" />
                    </div>
                  ) : activeTab === "energy" ? (
                    <EnergyModuleWithProvider
                      devicesSearch={filteredDevs}
                      projectId={projectId}
                      activeTab={activeTab}
                    />
                  ) : null}
                </TabPane>
              </TabContent>

              {/* agua */}
              <TabContent activeTab={activeTab}>
                <TabPane tabId="water">
                  {/* <CardTitle tag="h5">Módulo Água</CardTitle>
                <CardText>
                  Desabilitado...
                </CardText> */}
                  {activeTab === "water" ? (
                    <WaterModuleWithProvider
                      activeTab={activeTab}
                      projectId={projectId}
                    />
                  ) : null}
                </TabPane>
              </TabContent>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="gas">
              
                  {activeTab === "gas" ? (
                    <GasModuleWithProvider
                      activeTab={activeTab}
                      projectId={projectId}
                    />
                  ) : null}
                </TabPane>
              </TabContent>
            </CardBody>
          </Col>
        </Row>
      </Card>
    );
  }
);
