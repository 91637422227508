import { faCog, faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "antd";
import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DropdownItem, DropdownMenu } from "reactstrap";
import { useTheme } from "../../../context/theme";
import ThemeForm from "../../../pages/igSettings/settings/themes";

export const ThemesDropdown = () => {
  const { t } = useTranslation();
  const { setTheme } = useTheme();
  const [openModalSetting, setOpenModalSetting] = useState(false);

  function updateTheme(theme) {
    setTheme(theme);

    axios
      .put(
        "updateTheme/",
        JSON.stringify({
          theme: theme,
        })
      )
      .finally(() => {});
  }

  return (
    <>
      <DropdownMenu
        style={{
          minWidth: "7rem",
          position: "fixed",
          zIndex: "2000",
          marginLeft: "4rem",
        }}
      >
        <DropdownItem
          onClick={() => {
            updateTheme("dark");
            localStorage.setItem("defaultTheme", "dark");
          }}
        >
          <FontAwesomeIcon
            icon={faMoon}
            fixedWidth
            className="mr-2 align-middle"
          />
          {t("theme.dark")}
        </DropdownItem>

        <DropdownItem
          onClick={() => {
            updateTheme("light");
            localStorage.setItem("defaultTheme", "light");
          }}
        >
          <FontAwesomeIcon
            icon={faSun}
            fixedWidth
            className="mr-2 align-middle"
          />
          {t("theme.light")}
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            setOpenModalSetting(true);
          }}
        >
          <FontAwesomeIcon
            icon={faCog}
            fixedWidth
            className="mr-2 align-middle"
          />
          {t("theme.settings")}
        </DropdownItem>
      </DropdownMenu>
      {openModalSetting && (
        <Modal
          title={t("theme.settings")}
          visible={openModalSetting}
          onOk={() => setOpenModalSetting(false)}
          onCancel={() => setOpenModalSetting(false)}
          footer={null}
        >
          <ThemeForm
            closeModal={() => setOpenModalSetting(false)}
          />
        </Modal>
      )}
    </>
  );
};
