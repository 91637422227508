import axiosDefaults from "./axiosDefaults";

/****** FUNÇÃO DE LOGOFF/SIGN OUT  *******/
function onSignOutClick() {  
  // Limpa dados de usuário e estado de login do session storage
  localStorage.clear();

  // redefine configurações do axios
  axiosDefaults();

  document.getElementById("theme-variables")?.remove();

};

export default onSignOutClick;