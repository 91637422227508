import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CardBody,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

// import "../../../../assets/scss/3-components/_antd.scss";
// import "../../solarModule/devicesModal/DevicesModal.scss";
// import '../../../../../assets/scss/6-solar/DevicesModal.scss';

import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import ActivePower from "./components/ActivePower";
import CurrentCards from "./components/CurrentCards";
import CurrentFaseA from "./components/CurrentFaseA";
import CurrentFaseB from "./components/CurrentFaseB";
import CurrentFaseC from "./components/CurrentFaseC";
import EnergyDaily from "./components/EnergyDaily";
import Frequency from "./components/Frequency";
import LastComm from "./components/LastComm";
import LifetimeEnergy from "./components/LifetimeEnergy";
import PowerFactory from "./components/PowerFactory";
import Processed from "./components/Processed";
import PVCards from "./components/PVCards";
import Temperature from "./components/Temperature";
import VoltageFaseA from "./components/VoltageFaseA";
import VoltageFaseB from "./components/VoltageFaseB";
import VoltageFaseC from "./components/VoltageFaseC";

/****** MODAL DE DETALHES DO DEVICE ******/
const DevicesModal = ({ device, open, toggle, allData, state }) => {
  const { t } = useTranslation();
  const [cancelTokenSource, setCancelTokenSource] = useState(null);

  // Cria o CancelToken ao abrir o modal
  // Cria o CancelToken ao abrir o modal
  useEffect(() => {
    if (open) {
      const tokenSource = axios.CancelToken.source();
      setCancelTokenSource(tokenSource);

      return () => {
        tokenSource.cancel("Modal fechado, requisição cancelada.");
      };
    }
  }, [open]);

  const { data, error, status, isRefetching } = useQuery({
    queryKey: ["readDataLast", device],
    queryFn: async () => {
      try {
        const response = await axios.get("/readDataLast", {
          params: {
            idDevice: device,
          },
          cancelToken: cancelTokenSource.token,
        });
        return response.data;
      } catch (error) {
        if (error.response) {
          throw error.response.data;
        } else {
          throw error.message;
        }
      }
    },
    refetchInterval: 1000 * 60, // 1 minuto
    enabled: !!cancelTokenSource,
  });

  const loading = status === "loading";

  if (!data && loading) {
    return (
      <Modal
        isOpen={open}
        toggle={toggle}
        className={`modal-dialog`}
        size="xl"
        style={{ marginTop: "10px", marginBottom: "-4px" }}
      >
        <ModalBody style={{ paddingTop: "0px" }}>
          <CardBody
            style={{ marginTop: "54px", height: "94px", textAlign: "center" }}
          >
            <Spinner color="primary" />
          </CardBody>
        </ModalBody>

      </Modal>
    );
  }

  if (status === "error" && !data) {
    return (
      <Modal
        isOpen={open}
        toggle={toggle}
        className={`modal-dialog`}
        size="xl"
        style={{ marginTop: "10px", marginBottom: "-4px" }}
      >
        <ModalHeader
          toggle={toggle}
          style={{
            paddingLeft: "32px",
            borderBottom: "none",
            paddingBottom: "6px",
            paddingTop: "10px",
          }}
        >
          {device}
        </ModalHeader>
        <ModalBody style={{ paddingTop: "0px" }}>
          <CardBody
            style={{ marginTop: "54px", height: "94px", textAlign: "center" }}
          >
            <b>{error.message ?? t("solar.noDataPlants")}</b>
          </CardBody>
        </ModalBody>
      </Modal>
    );
  }

 

  // const StyleModal = (window.innerWidth < 480) ? "" : "";

  let dataGeral = data.deviceName;
  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      className={`modal-dialog card-solar-status`}
      size="xl"
      style={{ marginTop: "10px", marginBottom: "-4px" }}
    >
      <ModalHeader
        toggle={toggle}
        style={{
          paddingLeft: "32px",
          borderBottom: "none",
          paddingBottom: "6px",
          paddingTop: "10px",
        }}
      >
        {data.deviceName}{" "}
        {loading || isRefetching ? <Spinner color="primary" size="sm" /> : null}
      </ModalHeader>
      <ModalBody style={{ paddingTop: "0px" }}>
        <div
          style={{
            paddingLeft: "15px",
            paddingRight: "15px",
            paddingTop: "0px",
            marginBottom: "0px",
          }}
        >
          <Row sm="12" lg="12" style={{ paddingRight: "20px" }}>
            <Col>
              <LastComm allData={allData} state={state} data={data} />
            </Col>
            <Col>
              <Processed allData={allData} state={state} />
            </Col>
            <Col>
              <ActivePower allData={allData} ActivePower={data.activePower} />
            </Col>
            <Col>
              <EnergyDaily allData={allData} EnergyDaily={data.dailyEnergy} />
            </Col>
            <Col>
              <LifetimeEnergy
                allData={allData}
                LifetimeEnergy={data.totalEnergy}
              />
            </Col>
            <Col>
              <Frequency allData={allData} data={data} />
            </Col>
            <Col>
              <Temperature allData={allData} data={data} />
            </Col>
            <Col>
              <PowerFactory allData={allData} data={data} />
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: "10px",
              justifyContent: "space-between",
              paddingRight: "30px",
              paddingTop: "15px",
            }}
          >
            <div>
              <CardHeader
                style={{
                  paddingLeft: "1px",
                  paddingTop: "0px",
                  paddingBottom: "2px",
                }}
              >
                <h5>{t("solar.gridVoltage")}</h5>
              </CardHeader>
              <Row>
                <Col>
                  <VoltageFaseA allData={allData} VoltageFaseA={data.A_V} />
                </Col>
                <Col>
                  <VoltageFaseB allData={allData} VoltageFaseB={data.B_V} />
                </Col>
                <Col>
                  <VoltageFaseC allData={allData} VoltageFaseC={data.C_V} />
                </Col>
              </Row>
            </div>
            <div>
              <CardHeader
                style={{
                  paddingLeft: "0px",
                  paddingTop: "0px",
                  paddingBottom: "2px",
                }}
              >
                <h5>{t("solar.gridCurrent")}</h5>
              </CardHeader>
              <Row>
                <Col>
                  <CurrentFaseA allData={allData} CurrentFaseA={data.A_Curr} />
                </Col>
                <Col>
                  <CurrentFaseB allData={allData} CurrentFaseB={data.B_Curr} />
                </Col>
                <Col>
                  <CurrentFaseC allData={allData} CurrentFaseC={data.C_Curr} />
                </Col>
              </Row>
            </div>
          </Row>
          {dataGeral ? (
            <div>
              <CardHeader
                className="headerdeviceModal"
                style={{
                  paddingTop: "0px",
                  paddingLeft: "27px",
                  top: "8px",
                  width: "271px",
                  position: "relative",
                  marginLeft: "-120px",
                }}
              >
                <h6>{t("solar.inputVoltage")}</h6>
              </CardHeader>
              <Row
                id="larguraDiv"
                className="ROW NO MODAL PV styleScroll"
                style={{
                  overflowX: "scroll",
                  marginRight: "5px",
                  marginLeft: "28px",
                  marginTop: "-25px",
                }}
              >
                <PVCards data={data} />
              </Row>
              <CardHeader
                className="headerdeviceModal"
                style={{
                  paddingTop: "0px",
                  paddingLeft: "10px",
                  height: "37px",
                  top: "14px",
                  width: "271px",
                  position: "relative",
                  marginLeft: "-120px",
                }}
              >
                <h6>{t("solar.inputCurrent")}</h6>
              </CardHeader>
              <Row
                className="ROW NO MODAL PC"
                style={{
                  overflowX: "scroll",
                  marginRight: "5px",
                  marginLeft: "28px",
                  marginTop: "-10px",
                  paddingBottom: "10px",
                }}
              >
                <CurrentCards data={data} />
              </Row>
            </div>
          ) : (
            <CardBody className="text-center"> {t("solar.noData")} </CardBody>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export { DevicesModal };

