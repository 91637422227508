const enUsSolarTranslations = {
  solar: {
    prPvSystPercent: "PR Effective (%)",
    prIrradiationPercent: "PR Irradiation (%)",
    plants: "Plants",
    params: "Params",
    details: "Details",
    energy: "Daily",
    daily: "Energy",
    daily2: "Lifetime",
    current: "Current",
    lifetime: "Energy",
    power: "Power",
    income: "Monthly",
    month: "Income",
    month2: "Month",
    cultivated: "Cultivated",
    treesCultivated: "Trees",
    deviceList: "Device List",
    inputVoltage: "Input Voltage (V)",
    inputCurrent: "Input Current (A)",
    gridVoltage: "Grid Voltage (V)",
    gridCurrent: "Grid Current (A)",
    deviceStatus: "Device Status",
    activePower: "Active Power",
    currentDayPower: "Current Day Power",
    currentDayEnergy: "Current Day Energy",
    generationXirradiationDay: "Power x Irradiation",
    settings: "Settings",
    configPrice: "Enter value per kWh",
    price: "Value/kWh (R$)",
    insertDevices: "Please select 1 or more devices",
    devices: "Devices",
    refConsuption: "Consumption Reference",
    save: "Save",
    selectMonth: "Select Month",
    tree: "Tree",
    trees: "Trees",
    monthlyComparison: "Monthly Power Comparison",
    monthlyComparison2: "Monthly Energy Comparison",
    currentDay: "Current Day",
    currentMonth: "Current Month",
    lastMonth: "Last Month",
    noData: "No data found",
    noDataMonth: "No data found for the selected month",
    noSolarDevices: "There is no Solar type devices",
    energyTooltip: "Energy",
    powerTooltip: "Power",
    energyLabel: "Generated Energy",
    consumedLabel: "Consumed Energy",
    powerLabel: "Power",
    hour: "Hour",
    date: "Date",
    total: "Income",
    income2: "Total",
    pv: "PV",
    system: "System",
    online: "online",
    incomeSolar: "Income",
    inverterQuantity: "Inverter Quantity",
    inverter: "Inverter",
    inverters: "Inverters",
    status: "Status",
    pvPlant: "PV Plant",
    solar: "Solar",
    solarVision: "Solar Module Overview",
    tooltipOff: "Communication failure on all devices",
    tooltipOn: "All devices are communicating",
    tooltipWarning: "Communication failure on one or more devices",
    noDataPlants: "There is no Solar type plants",
    generate: "Generated",
    consumed: "Consumed",
    powerGraphic: "Power",
    powerCard: "Installed",
    capacity: "Capacity",
    avoided: "Avoided",
    emissions: "Emissions",
    monthly: "Monthly",
    weeklyEnergyLabel: "Weekly Energy",
    monthlyEnergy: "Energy",
    weeklyEnergy: "Weekly",
    inverterStatus: "Inverter Status",
    processed: "Processed",
    dailyEnergy: "Daily Energy",
    lifetimeEnergy: "Lifetime Energy",
    frequency: "Frequency",
    temperature: "Temperature",
    powerFactory: "Power Factor",
    lastCommunication: "Last Communication",
    area: "Area",
    rendimento: "Module Yield",
    cliente: "Customer",
    localizacao: "Location",
    conjuntoProduto: "Product Cluster",
    posicionamento: "Positioning",
    picoEnergia: "Peak Power",
    contratoInicio: "Contract Start",
    contratoFim: "Contract End",
    imagemPlanta: "Plant Image",
    expectedEfficiency: "Expected Efficiency",
    humidity: "Humidity/Pluviometer",
    velocity: "Velocity",
    irradiation: "Irradiation",
    irradiance: "Irradiance",
    moduleTemperature: "Module Temperature",
    ambientTemperature: "Room Temperature",
    windDirection: "Wind Direction",
    solarimetric: "Solarimetric Station",
    generation: "Generation",
    generationXirradiation: "Generation x Irradiance",
    indicators: "Performance Indicators",
    disponibility: "Plant General Availability",
    capacityFactor: "Capacity Factor",
    solarCapacity: "Capacity",
    productivity: "Productivity",
    plant: "Plant",
    expectedGeneration: "Expected Generation",
    pr: "Performance Ratio",
    availability: "Availability",
    plantAvailability: "General Plant Availability",
    day: "Day",
    noParams: "Parameters required",
    config: "Configure",

    // NOTE:MODAL PVSYST
    modalPvSystHeader: "Config PVSyst",
    pvSystJan: "January",
    pvSystFev: "February",
    pvSystMar: "March",
    pvSystAbr: "April",
    pvSystMai: "May",
    pvSystJun: "June",
    pvSystJul: "July",
    pvSystAgo: "August",
    pvSystSet: "September",
    pvSystOut: "October",
    pvSystNov: "November",
    pvSystDez: "December",

    north: "N",
    northeast: "NE",
    east: "E",
    southeast: "SE",
    south: "S",
    southwest: "SW",
    west: "W",
    northwest: "NW",

    // NOTE:REPORTS PDF
    reactPdfModalTitle: "Report Solar Module",
    reactPdfReport: "Report",
    reactPdfDailyEnergy: "Daily Energy Generated",
    reactPdfMonthlyEnergy: "Monthly Energy Generated",
    reactPdfLifetimeEnergy: "Lifetime Energy",
    reactPdfCurrentPower: "Current Power",
    reactPdfCapacity: "Installed Capacity",
    reactPdfIncomeDisp: "Monthly Income",
    reactPdfAvoidedDisp: "Avoided Emissions",
    errors: {
      monthRef: {
        title: "Reference Month",
        msg: "Reference Month is invalid date",
      },
      contractEnd: {
        title: "Contract End",
        msg: "The date must be bigger than contract start",
      },
      efficiency: {
        title: "Module Yield",
        msg: "Must be between 0 and 100",
      },
      invalidEmail: {
        title: "Attention",
        msg: "Invalid email format",
      },
      emailAdded: {
        title: "Attention",
        msg: "Email already added",
      },
      emptyEmail: {
        title: "Attention",
        msg: "No emails to be sent",
      },
      sendReportManager: {
        title: "Management Report",
        msg: "Error to send",
      },
    },
    success: {
      sendReportManager: {
        title: "Management Report",
        msg: "Sent with success",
      },
    },
    billingReport: {
      title: "Management Report",
      prevReport: "Previous Report",
      generateReport: "Generate Report",
      generate: "Generate",
      errorPDF: "Please select a PDF file",
      readingProgress: "Reading in Progress",
      generatingReport: "Generating Report",
      selectMonthRef: "Select the reference month",
      selectFile: "Select file",
      changeFile: "Change file",
      onlyPdfFile: "Only Pdf files",
      previousRead: "Previous Reading",
      currentRead: "Current Reading",
      generationBalancekWh: "Generation Balance kWh",
      kwhBalanceToExpire: "KWH Balance to Expire",
      value: "Value",
      TUSDConsumption: "TUSD Consumption",
      TEConsumption: "TE Consumption",
      consumption: "Consumption",
      TUSDInjectedEnergy: "TUSD Injected Energy",
      TEInjectedEnergy: "TE Injected Energy",
      publicLightingAgreement: "Public Lighting Agreement",
      injectedActiveEnergy: "Injected Active Energy",
      totalToPay: "Total To Pay",
      noPdfFileSelected: "No PDF Selected",
      confirmTheReadData: "Confirm the Read Data",
      continue: "Continue",
    },
    prDelta: "Delta",
    generatedVsPvsyst: "Generated / PVsyst (%)",
    prProjected: "PR Projetado (%)",
  },
  solarReport: {
    reportTitle: "Plan",
    youSaved: "You Saved",
    performanceRatio: "Performance Ratio",
    generatedEnergy: "Generated Energy",
    projectedEnergy: "Projected Energy",
    avoidedDisp: "Avoided Emissions",
    cultivatedTrees: "Equivalent to the total number of cultivated trees",
    previousMonths: "Previous months",
    solutionMade: "Solution made by",
    // NOTE:MODAL DE ENVIAR POR E-MAIL
    sendEmail: "Send by e-mail",
    bodyText: "Enter the email and click the + button",
    inputPlaceholder: "example@email.com",
    editText: "Edit the email and click the Confirm button",
    closeButton: "Close",
    sendButton: "Send",
    // NOTE:TOOLTIPS
    generateMonthlyReport: "Generate Monthly Report",
    reportPDF: "Report PDF",
    reportXLS: "Report XLS",
    startDate: "Start Date",
    endDate: "End Date",
    selectDates: "Select the dates",
    error: "An error occurred, try again later.",
    solarManagementReport: "Solar Management Report",
  },
  reportManager: {
    title: "Management Report",
    modalTitle: "Management Report - Solar Module",
    month: "month",
    customer: "Customer",
    location: "Location",
    productCluster: "Product Cluster",
    positioning: "Positioning",
    peakPower: "Peak Power",
    contractStart: "Contract Start",
    contractEnd: "Contract End",
    detailsTitle: "Details",
    statusTitle: "Current Status",
    statusInverter: "All Inverters working",
    statusSystem: "System Monitoring working",
    kpisTitle: "KPIs (Average Values per Month)",
    eventTitle:
      "Maintenance activities, relevant faults and other noticeable events",
    grossMargin: "Gross Margin",
    mwhProduced: "MWh produced",
    co2Saved: "CO2 saved",
    avgPr: "Perfomance Ratio",
    labelEvent:
      "Would you like to add event recording to the report? Enter below and click the + button",
    eventDescription: "Event Description",
    eventAction: "Action",
    showPDF: "Show PDF",
    notEmptyEvent: "Fill in the event description and action",
  },
  availability: {
    deleteSuccess: "Record deleted successfully!",
    deleteError: "Error deleting record.",
    saveSuccess: "Configuration saved successfully!",
    saveConflict: "There was a conflict",
    saveError: "An error occurred while trying to save the configuration",
    configTitle: "Availability Configuration",
    backButton: "Back",
    addButton: "Add",
    deleteConfirm: "Are you sure you want to delete this record?",
    deleteYes: "Yes",
    deleteNo: "No",
    cancelButton: "Cancel",
    saveButton: "Save",
    deviceName: "Device",
    model: "Model",
    startOffline: "Start Offline",
    endOffline: "End Offline",
    motivation: "Motivation",
    actions: "Actions",
    addFiles: "Add Files",
  },
};

export default enUsSolarTranslations;
