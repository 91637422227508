/****** ÍNDICE DE ROTAS ******/
import {
  faUser,
  faHome,
  faChalkboard,
  faTable,
  faChartLine,
  faBell,
  faBook,
  faCog,
  faLock,
  faTags,
} from "@fortawesome/free-solid-svg-icons";

// Auth
import SignIn from "../pages/igAuth/SignIn";
import ResetPassword from "../pages/igAuth/ResetPassword";

// Main
import Home from "../pages/igMain/";

// Dashboards
import DashboardsTable from "../pages/igDashboards/DashboardsTable";
import NewCard from "../pages/igDashboards/view/newCard/NewCard";
import DashboardView from "../pages/igDashboards/view/DashboardView";
import SelectCardType from "../pages/igDashboards/view/newCard/SelectCardType";

//Data Explorer
import DataExplorer from "../pages/igData/dataExplorer/DataExplorer";

// Projects
import Projects from "../pages/igData/projects/Projects";
import Devices from "../pages/igData/projects/project/Devices";
import DeviceVariables from "../pages/igData/projects/DeviceVariables";
// import SolarView from "../pages/igData/projects/SolarView";
import SolarView from "../pages/igData/projects/solarModule/SolarView";

// Alarms
import AlarmConfig from "../pages/igAlarms/alarmConfig/AlarmConfig";
import AlarmView from "../pages/igAlarms/alarmView/AlarmView";

// Registers (admin only)
import UserData from "../pages/igRegisters/users/UserData";
import ProjectData from "../pages/igRegisters/projects/ProjectData";
import AlarmData from "../pages/igRegisters/alarms/AlarmData";
import LogData from "../pages/igRegisters/igLogs/LogData";
import AddNewDriver from "../pages/igRegisters/projects/drivers/AddNewDriver";
import Gateways from "../pages/igRegisters/gateways/Gateways";

// Settings
import MySettings from "../pages/igSettings/Settings";
import { EnergyModule } from "../pages/igData/projects/energyModule/generalVisionEnergy";
import { ServiceAuthorizationsWithProvider } from "../pages/igData/NetworkOperationsCenter/ServiceAuthorizations";
import { Noc } from "../pages/igData/NetworkOperationsCenter";
import { WaterModule } from "../pages/igData/projects/waterModule/";
import { CommercialManagement } from "../pages/igData/NetworkOperationsCenter/CommercialManagement";
import { GasModule } from "../pages/igData/projects/gasModule";

//Final User
import { HomeFinalUser } from "../pages/FinalUser/home";

/******* Routes ******/

// Auth
const authRoutes = {
  path: "/auth",
  name: "Auth",
  icon: faUser,
  children: [
    {
      path: "/auth/signin",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
  ],
};

// Main
const homeRoutes = {
  path: "/home",
  name: "home",
  icon: faHome,
  component: Home,
  children: null,
};

const finalUser = {
  path: "/endUser",
  name: "homeUser",
  icon: faHome,
  component: HomeFinalUser,
  children: null,
}

// Dashboards
const dashboardsRoutes = {
  path: "/dashboards",
  name: "dashboards",
  icon: faChalkboard,
  header: "iot",
  component: DashboardsTable,
  children: null,
};
const newCardRoutes = {
  // path: "/newCard",
  children: [
    {
      path: "/newCard",
      name: "allCards",
      component: SelectCardType,
      children: null,
    },
    {
      path: "/newCard/:type",
      name: "newCard",
      component: NewCard,
      children: null,
    },
    {
      path: "/newCard/:type/:id",
      name: "newCard",
      component: NewCard,
      children: null,
    },
  ],
};
const MeusCardsRoutes = {
  path: "/dashboardView",
  name: "MeusCards",
  component: DashboardView,
  children: null,
};

// Data Explorer
const dataExplorerRoutes = {
  path: "/data",
  name: "dataExplorer",
  icon: faChartLine,
  component: DataExplorer,
  children: null,
};

// Projects
const projectsRoutes = {
  name: "projects",
  icon: faTable,
  path: "/projects",
  component: Projects,
};

const solarSpecialitRoute = {
  header: "specialist",
  path: "/solar",
  name: "solarModuleOn",
  icon: faChalkboard,
  component: SolarView,
};

const energySpecialitRoute = {
  header: "specialist",
  path: "/energy",
  name: "powerModuleOn",
  icon: faChalkboard,
  component: EnergyModule,
};

const waterSpecialitRoute = {
  header: "specialist",
  path: "/water",
  name: "waterModuleOn",
  icon: faChalkboard,
  component: WaterModule,
};

//NOTE: Componente de gás
const gasSpecialitRoute = {
  header: "specialist",
  path: "/gas",
  name: "gasModuleOn",
  icon: faChalkboard,
  component: GasModule,
};

//NOTE: Componente de climatização
// const climateSpecialitRoute = {
//   header: "specialist",
//   path: "/",
//   name: "climateModuleOn",
//   icon: faChalkboard,
//   component: ,
// };

//NOTE: Componente de noBreak
// const nobreakSpecialitRoute = {
//   header: "specialist",
//   path: "/",
//   name: "nobreakModuleOn",
//   icon: faChalkboard,
//   component: ,
// };

const devicesRoutes = {
  // name:"devices",
  path: "/project-:id-:module/:refName?/:refId?",
  component: Devices,
  children: null,
};

const deviceVariables = {
  path: "/variables-:id",
  component: DeviceVariables,
  children: null,
};

// Alarms
const alarmsRoutes = {
  // path: "/alarms",
  header: "tools",
  name: "alarms",
  icon: faBell,
  children: [
    {
      path: "/alarmView",
      name: "alarmView",
      component: AlarmView,
    },
    {
      path: "/alarmConfig",
      name: "alarmConfig",
      component: AlarmConfig,
    },
    {
      path: "/alarmsRegisters",
      name: "alarmsData",
      component: AlarmData,
    },
  ],
};

// Registers (admin only)
const registerRoutes = {
  // path: "/registers",
  header: "adminOnly",
  name: "registers",
  icon: faTags,
  children: [
    {
      path: "/userRegisters",
      name: "usersData",
      component: UserData,
    },
    {
      path: "/projectRegisters",
      name: "projectsData",
      component: ProjectData,
    },
  ],
};

// Noc
const nocRoutes = {
  header: "noc",
  name: "noc",
  icon: faTags,
  children: [
    {
      path: "/serviceAuthorizations-:projId?",
      name: "serviceAuthorizations",
      component: ServiceAuthorizationsWithProvider,
    },
    {
      path: "/serviceAuthorizations/commercialManagement",
      name: "CommercialManagement",
      component: CommercialManagement,
    },
    {
      path: "/iGoalEye",
      name: "iGoalEye",
      component: Noc,
    },
  ],
};

// Securiry (admin only)
const securityRoutes = {
  // path: "/registers",
  name: "security",
  icon: faLock,
  children: [
    {
      // Registers (logs)
      path: "/logsRegisters",
      name: "logsData",
      component: LogData,
      //icon:faBook
    },
  ],
};

// Registers (admin only)
const managementRoutes = {
  // path: "/registers",
  name: "management",
  icon: faBook,
  children: [
    {
      path: "/addNewDriver",
      name: "myDriversData",
      component: AddNewDriver,
    },
    {
      path: "/gatewayManagement",
      name: "gateways",
      component: Gateways,
    },
  ],
};

// Settings
const settingsRoutes = {
  path: "/settings",
  name: "settings",
  icon: faCog,
  component: MySettings,
  children: null,
};

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

// Sidebar routes
export const sidebarRoutes = [
  homeRoutes,
  dashboardsRoutes,
  dataExplorerRoutes,
  projectsRoutes,
  solarSpecialitRoute,
  energySpecialitRoute,
  waterSpecialitRoute,
  alarmsRoutes,
];

// Admin only routes
export const adminRoutes = [
  homeRoutes,
  dashboardsRoutes,
  dataExplorerRoutes,
  projectsRoutes,
  solarSpecialitRoute,
  energySpecialitRoute,
  waterSpecialitRoute,
  gasSpecialitRoute,
  // climateSpecialitRoute,
  // nobreakSpecialitRoute,
  alarmsRoutes,
  registerRoutes,
  securityRoutes,
  managementRoutes,
  nocRoutes,
];

// Auth specific routes
export const auth = [authRoutes];

// All routes
export const allRoutes = [
  homeRoutes,
  dashboardsRoutes,
  MeusCardsRoutes,
  newCardRoutes,
  dataExplorerRoutes,
  projectsRoutes,
  solarSpecialitRoute,
  energySpecialitRoute,
  waterSpecialitRoute,
  gasSpecialitRoute,
  // climateSpecialitRoute,
  // nobreakSpecialitRoute,
  devicesRoutes,
  deviceVariables,
  alarmsRoutes,
  registerRoutes,
  securityRoutes,
  managementRoutes,
  settingsRoutes,
  nocRoutes,
  finalUser
];

// Default routes: all routes except register routes (admin only)
const defaultRoutes = [
  homeRoutes,
  dashboardsRoutes,
  MeusCardsRoutes,
  newCardRoutes,
  dataExplorerRoutes,
  projectsRoutes,
  solarSpecialitRoute,
  energySpecialitRoute,
  waterSpecialitRoute,
  gasSpecialitRoute,
  // climateSpecialitRoute,
  // nobreakSpecialitRoute,
  devicesRoutes,
  deviceVariables,
  alarmsRoutes,
  settingsRoutes,
  nocRoutes,
  finalUser
];

export default defaultRoutes;
