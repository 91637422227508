import { useQuery } from "@tanstack/react-query";
import {
  Checkbox,
  DatePicker,
  Divider,
  Empty,
  Modal,
  Spin,
  Table,
  Typography,
} from "antd";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiInfoCircle } from "react-icons/bi";
import { Button, Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { queryClient } from "../../App";
import DataExplorerChart from "../../pages/igData/dataExplorer/components/NewLineChart";
import { DateRangeSelector } from "../DateRangeSelector";

const { RangePicker } = DatePicker;
const dateFormat = "DD/MM/YYYY";

export const DataExplorerInProject = ({ projectId }) => {
  const { t } = useTranslation();

  const [modal, setModal] = useState(false);
  const [sendParams, setSendParams] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [range, setRange] = useState("24Hours");

  const [mergeVariables, setMergeVariables] = useState(true);
  const [checkedList, setCheckedList] = useState([]);
  const [variables, setVariables] = useState([]);
  const [selectedVariables, setSelectedVariables] = useState([]);
  const [autoSelect, setAutoSelect] = useState(false);

  const [initDate, setEntrieStartDate] = useState(
    moment().startOf("day").toDate()
  );
  const [finishDate, setEntrieEndDate] = useState(
    moment().endOf("day").toDate()
  );

  const handleClickModal = () => setModal(!modal);

  const { data } = useQuery({
    queryKey: ["projectListDevices", { id: projectId }],
    queryFn: async () => {
      const res = await axios.get("v2/project/listDevices", {
        params: { projId: projectId },
      });
      return res.data;
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
  });

  const handleSearch = async (params) => {
    setLoading(true);
    setError(false);
    setModal(false);
    try {
      const response = await queryClient.ensureQueryData({
        queryKey: ["v2/project/dataExplorer", { projId: projectId, params }],
        queryFn: () =>
          axios.post("v2/project/dataExplorer", params).then((res) => res.data),
      });

      setSendParams({
        dataChart: response,
        sendedParams: { ...params },
      });
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (sendParams?.sendedParams) {
      sendParams.sendedParams.startDate = moment(initDate).unix();
      sendParams.sendedParams.endDate = moment(finishDate).unix();
      handleSearch(sendParams.sendedParams);
    }
  }, [initDate, finishDate]);

  return (
    <Card
      style={{
        height: "100%",
      }}
    >
      <CardHeader className="d-flex justify-content-between align-items-center">
        <CardTitle tag="h5" className="mb-0">
          {t("Data Explorer")}
        </CardTitle>
        {sendParams && (
          <Button onClick={handleClickModal}>{t("dataExplorer.select")}</Button>
        )}
      </CardHeader>
      <CardBody
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading ? (
          <Spin />
        ) : sendParams ? (
          <DateRangeSelector
            loading={loading}
            initDate={initDate}
            finishDate={finishDate}
            setEntrieStartDate={setEntrieStartDate}
            setEntrieEndDate={setEntrieEndDate}
            entrieRange={range}
            setEntrieRange={setRange}
            notShowFilters={[
              "today",
              "last24Hours",
              "last7Days",
              "last30Days",
              "last12Months",
              "fullMonth",
              "fullYear",
            ]}
          >
            <DataExplorerChart dataExplorerInfo={sendParams} t={t} />
          </DateRangeSelector>
        ) : (
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 60,
            }}
            description={
              <Typography.Text>
                {error
                  ? t("dataExplorer.errorFetchingData")
                  : t("dataExplorer.noData")}
              </Typography.Text>
            }
          >
            <Button onClick={handleClickModal}>
              {t("dataExplorer.select")}
            </Button>
          </Empty>
        )}
      </CardBody>
      {modal && (
        <ModalDataExplorerInProject
          projectId={projectId}
          open={modal}
          toggle={handleClickModal}
          handleSearch={handleSearch}
          deviceList={data ?? []}
          mergeVariables={mergeVariables}
          setMergeVariables={setMergeVariables}
          checkedList={checkedList}
          setCheckedList={setCheckedList}
          variables={variables}
          setVariables={setVariables}
          selectedVariables={selectedVariables}
          setSelectedVariables={setSelectedVariables}
          autoSelect={autoSelect}
          setAutoSelect={setAutoSelect}
          initDate={initDate}
          setEntrieStartDate={setEntrieStartDate}
          finishDate={finishDate}
          setEntrieEndDate={setEntrieEndDate}
        />
      )}
    </Card>
  );
};

const ModalDataExplorerInProject = ({
  projectId,
  open,
  toggle,
  handleSearch,
  deviceList,
  mergeVariables,
  setMergeVariables,
  checkedList,
  setCheckedList,
  variables,
  setVariables,
  selectedVariables,
  setSelectedVariables,
  autoSelect,
  setAutoSelect,
  initDate,
  setEntrieStartDate,
  finishDate,
  setEntrieEndDate,
}) => {
  const { t } = useTranslation();

  const processVariables = (devices, mergeVariables = false) => {
    if (mergeVariables) {
      const mergedVariables = {};
      devices.forEach((device) => {
        device.driver.varlist.forEach((variable) => {
          if (!mergedVariables[variable.tag]) {
            mergedVariables[variable.tag] = {
              tag: variable.tag,
              varName: variable.varName,
              unit: variable.unit,
            };
          }
        });
      });
      return Object.values(mergedVariables);
    } else {
      return devices.flatMap((device) =>
        device.driver.varlist.map((variable) => ({
          dvcId: device.dvcId,
          description: device.description,
          tag: variable.tag + " - " + device.dvcId,
          varName: variable.varName + " - " + device.description,
          unit: variable.unit,
        }))
      );
    }
  };

  const onChangeDeviceSelection = (selectedRowKeys) => {
    setCheckedList(selectedRowKeys);
    const selectedDevices = deviceList.filter((device) =>
      selectedRowKeys.includes(device.dvcId)
    );
    const newVariables = processVariables(selectedDevices, mergeVariables);
    setVariables(newVariables);
  };

  const onChangeVariableSelection = (selectedTags) => {
    setSelectedVariables(selectedTags);
  };

  const columnsDevices = [
    {
      title: t("dataExplorer.deviceDescription"),
      dataIndex: "description",
      key: "description",
    },
  ];

  const columnsVariables = [
    {
      title: t("dataExplorer.varName"),
      dataIndex: "varName",
      key: "varName",
    },
  ];

  const onOkHandler = () => {
    const selectedDevices = deviceList.filter((device) =>
      checkedList.includes(device.dvcId)
    );

    const result = selectedDevices.map((device) => {
      const variablesForDevice = selectedVariables
        .map((tag) => {
          const variable = device.driver.varlist.find((variable) =>
            mergeVariables
              ? variable.tag === tag
              : `${variable.tag} - ${device.dvcId}` === tag
          );
          return variable
            ? {
                tag: variable.tag,
                varName: variable.varName,
              }
            : null;
        })
        .filter(Boolean);

      return {
        device: device.dvcId,
        tags: variablesForDevice,
        title: device.description,
      };
    });

    console.log(result);
    handleSearch({
      projectId,
      devices: result,
      startDate: moment(initDate).unix(),
      endDate: moment(finishDate).unix(),
    });
  };

  const onMergeVariablesChange = (e) => {
    const isMerge = e.target.checked;
    setMergeVariables(isMerge);
    setSelectedVariables([]);
    const selectedDevices = deviceList.filter((device) =>
      checkedList.includes(device.dvcId)
    );
    const newVariables = processVariables(selectedDevices, isMerge);
    setVariables(newVariables);
  };

  const onShortcutSelectByTagType = (tagTypes) => {
    if (autoSelect) {
      const devicesWithTagTypes = deviceList.filter((device) =>
        device.driver.varlist.some(
          (variable) => variable.tagType && tagTypes.includes(variable.tagType)
        )
      );

      const deviceIdsWithTagTypes = devicesWithTagTypes.map(
        (device) => device.dvcId
      );

      setCheckedList(deviceIdsWithTagTypes);

      const newVariables = processVariables(
        devicesWithTagTypes,
        mergeVariables
      );

      const variablesWithTagTypes = newVariables.filter((variable) => {
        return devicesWithTagTypes.some((device) =>
          device.driver.varlist.some(
            (v) =>
              v.tag ===
                (mergeVariables
                  ? variable.tag
                  : variable.tag.split(" - ")[0]) &&
              tagTypes.includes(v.tagType)
          )
        );
      });

      setVariables(newVariables);
      setSelectedVariables(
        variablesWithTagTypes.map((variable) => variable.tag)
      );
    } else {
      const selectedDevices = deviceList.filter((device) =>
        checkedList.includes(device.dvcId)
      );
      const newVariables = processVariables(selectedDevices, mergeVariables);

      const variablesWithTagTypes = newVariables.filter((variable) => {
        return selectedDevices.some((device) =>
          device.driver.varlist.some(
            (v) =>
              v.tag ===
                (mergeVariables
                  ? variable.tag
                  : variable.tag.split(" - ")[0]) &&
              tagTypes.includes(v.tagType)
          )
        );
      });

      setVariables(newVariables);
      setSelectedVariables(
        variablesWithTagTypes.map((variable) => variable.tag)
      );
    }
  };

  const formattedStartDate = moment(initDate).format("DD/MM/YYYY - ddd");
  const formattedEndDate = moment(finishDate).format("DD/MM/YYYY - ddd");

  return (
    <Modal
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>{t("dataExplorer.selectVariables")}</span>
        </div>
      }
      open={open}
      onCancel={toggle}
      onOk={onOkHandler}
      closable
      width={`75vw`}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Checkbox onChange={onMergeVariablesChange} checked={mergeVariables}>
          {t("dataExplorer.mergeVariables")}
          <BiInfoCircle
            style={{ marginLeft: "4px" }}
            title={t("dataExplorer.mergeVariablesInfo")}
          />
        </Checkbox>

        <Checkbox
          checked={autoSelect}
          onChange={() => setAutoSelect(!autoSelect)}
          style={{ position: "relative" }}
        >
          {t("dataExplorer.autoSelect")}

          <BiInfoCircle
            style={{ marginLeft: "4px" }}
            title={t("dataExplorer.autoSelectInfo")}
          />
        </Checkbox>

        <RangePicker
          value={[
            moment(formattedStartDate, dateFormat),
            moment(formattedEndDate, dateFormat),
          ]}
          onChange={(dates) => {
            if (dates) {
              const [start, end] = dates;
              setEntrieStartDate(start.startOf("day").toDate());
              setEntrieEndDate(end.endOf("day").toDate());
            }
          }}
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
          clearIcon={null}
          format={dateFormat}
          style={{ zIndex: 9999 }}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "4px",
        }}
      >
        <Button
          type="primary"
          onClick={() => onShortcutSelectByTagType(["OC", "OV"])}
          outline
          size="small"
        >
          {t("dataExplorer.acData")}
        </Button>
        <Button
          type="primary"
          onClick={() => onShortcutSelectByTagType(["IC"])}
          outline
          size="small"
        >
          {t("dataExplorer.cc")}
        </Button>
        <Button
          type="primary"
          onClick={() => onShortcutSelectByTagType(["IV"])}
          outline
          size="small"
        >
          {t("dataExplorer.tensaoCC")}
        </Button>
        <Button
          type="primary"
          onClick={() => onShortcutSelectByTagType(["IV", "IC"])}
          outline
          size="small"
        >
          {t("dataExplorer.correnteTensao")}
        </Button>
      </div>
      <Divider />
      <div
        style={{ display: "flex", justifyContent: "space-between", gap: "4px" }}
      >
        <div>
          <Button
            onClick={() => {
              setSelectedVariables([]);
              setVariables([]);
              setCheckedList([]);
            }}
            size="small"
            outline
            style={{ border: "none" }}
          >
            {t("dataExplorer.clearAll")}
          </Button>
          <Table
            rowKey="dvcId"
            columns={columnsDevices}
            dataSource={deviceList}
            pagination={false}
            rowSelection={{
              selectedRowKeys: checkedList,
              onChange: onChangeDeviceSelection,
              type: "checkbox",
              checkStrictly: true,
            }}
            scroll={{ y: 300 }}
          />
        </div>

        {/* Tabela de variáveis */}
        <div>
          <Button
            onClick={() => {
              setSelectedVariables([]);
            }}
            size="small"
            outline
            style={{ border: "none" }}
          >
            {t("dataExplorer.clearVariables")}
          </Button>
          <Table
            rowKey="tag"
            columns={columnsVariables}
            dataSource={variables}
            pagination={false}
            rowSelection={{
              selectedRowKeys: selectedVariables,
              onChange: onChangeVariableSelection,
              type: "checkbox",
              checkStrictly: true,
            }}
            scroll={{ y: 300 }}
          />
        </div>
      </div>
    </Modal>
  );
};
