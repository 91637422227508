import { useSelector } from "react-redux";
import { useTheme } from "./../context/theme";
export const IconModule = ({ IconComponent, moduleValue, ...props }) => {
    const {colors} = useTheme()
    const colorByValue = (value) => {
      return value > 0 ? 'var(--primary-color)' : 'var(--disabled-color)';
    };
  
    const pointerByValue = (value) => {
      return value > 0 ? "pointer" : "not-allowed";
    };
  
    return (
      <IconComponent
        cursor={pointerByValue(moduleValue)}
        size="12px"
        color={moduleValue > 0 ? colors['--info-color'] : colors['--disabed']}
        style={{
          filter: `drop-shadow(0 0 3px ${colorByValue(moduleValue)})`,
        }}
        {...props}
      />
    );
  };
  