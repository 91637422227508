import React from "react";
import classNames from "classnames";

const Main = ({ className, children }) => (
  <div className={classNames("main", className)} style={{
    padding: "20px",
    minHeight: "100vh",
  }}>{children}</div>
);

export default Main;
