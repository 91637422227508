import { Plus, X } from "react-feather";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  CardSubtitle,
  CardTitle,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

export const ModalCalculationBase = ({ isOpen, handleCloseModal }) => {
  const { t } = useTranslation();

  //NOTE: Criando array de objetos com as informações
  const MOCK_INFORMATION = [
    {
      valueOne: t("calculationBase.peakConsumption"),
      valueTwo: t("calculationBase.tusdPeakConsumption"),
    },
    {
      valueOne: t("calculationBase.offPeakConsumption"),
      valueTwo: t("calculationBase.tusdOffPeakConsumption"),
    },
    {
      valueOne: t("calculationBase.peakConsumption"),
      valueTwo: t("calculationBase.tePeakConsumption"),
    },
    {
      valueOne: t("calculationBase.offPeakConsumption"),
      valueTwo: t("calculationBase.teOffPeakConsumption"),
    },
    {
      valueOne: t("calculationBase.peakContractedDemand"),
      valueTwo: t("calculationBase.systemUsageDistPeak"),
    },
    {
      valueOne: t("calculationBase.offPeakContractedDemand"),
      valueTwo: t("calculationBase.systemUsageDistOffPeak"),
    },
  ];

  return (
    <Modal isOpen={isOpen} toggle={handleCloseModal} size="md">
      <ModalHeader
        style={{ borderBottom: "1px solid #aef5c6" }}
        className="m-2"
      >
        <CardTitle tag="h5" style={{ marginBottom: "0px" }}>
          {t("calculationBase.title")}
        </CardTitle>
      </ModalHeader>
      <ModalBody sx={12} className="m-2">
        <Row xs={12}>
          <Col
            xs={12}
            sm={3}
            className="d-flex align-items-center justify-content-center"
          >
            <Row sx={12}>
              <CardSubtitle className="text-uppercase text-muted " tag="p">
                {t("calculationBase.monthlyBill") + " ="}
              </CardSubtitle>
            </Row>
          </Col>
          <Col
            xs={12}
            sm={9}
            className="d-flex flex-column align-items-center justify-content-center"
          >
            {/* NOTE: Exibindo informações */}
            {MOCK_INFORMATION.map(({ valueOne, valueTwo }, index) => {
              return (
                <React.Fragment key={index}>
                  <Row
                    xs={12}
                    style={{
                      height: "48px",
                      borderLeft: `1px solid ${'var(--primary-color)'}`,
                      borderRight: `1px solid ${'var(--primary-color)'}`,
                      borderRadius: "20px",
                    }}
                    className="w-100 d-flex align-items-center justify-content-center"
                  >
                    <CardSubtitle
                      className="text-muted text-center"
                      tag="p"
                      style={{ width: 150 }}
                      color="primary"
                    >
                      {valueOne}
                    </CardSubtitle>
                    <X size={12} className="ml-2 mr-2" />
                    <CardSubtitle
                      className="text-muted text-center"
                      style={{ width: 150 }}
                      tag="p"
                    >
                      {" "}
                      {valueTwo}
                    </CardSubtitle>
                  </Row>
                  {/* NOTE: Caso não seja o último, adiciona uma linha com símbolo de "+" */}
                  {index + 1 !== MOCK_INFORMATION.length && (
                    <Row xs={12}>
                      <Plus size={12} className="mt-1 mb-1" />
                    </Row>
                  )}
                </React.Fragment>
              );
            })}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};
